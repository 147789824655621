<template>
  <div class="school_base_info_common input_h_44">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <!-- 学校信息 -->
      <div class="school_info">
        <p class="info_title">学校信息</p>
        <el-form-item
          class="el-form-100"
          label="学校名称"
          prop="schoolName"
          style="width: 100%"
        >
          <el-input
            v-model="ruleForm.schoolName"
            placeholder="请输入学校名称"
          ></el-input>
        </el-form-item>
        <div class="left_right_cont">
          <div class="left">
            <el-form-item label="学校简称">
              <el-input
                v-model="ruleForm.forShort"
                placeholder="请输入学校简称"
              ></el-input>
            </el-form-item>
            <el-form-item label="学校性质"  prop="nature">
              <el-select v-model="ruleForm.nature" placeholder="请选择">
                <el-option
                  :label="item.baseName"
                  :value="item.baseCode"
                  :key="item.baseCode"
                  v-for="item in schoolTypeOptions"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="right">
            <el-form-item label="学校机构代码">
              <el-input
                v-model="ruleForm.code"
                placeholder="请输入学校机构代码"
              ></el-input>
            </el-form-item>
            <el-form-item label="学校类别" prop="schoolProperty">
              <el-select
                v-model="ruleForm.schoolProperty"
                @change="changeSchoolPro"
                clearable
                disabled
                placeholder="请选择"
              >
                <el-option
                  :label="item.property"
                  :value="item.id"
                  :key="item.id"
                  v-for="item in schoolPropertyOptions"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </div>
      <!-- 学制信息 -->
      <div class="school_system_info" v-if="isShowSchoolSys">
        <p class="info_title">学制信息</p>
        <div class="info_content" v-if="ruleForm.schoolProperty == 5">
          <el-form-item
            label="小学"
            prop="primarySchool"
            style="margin-right: 32px"
            :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              v-model="ruleForm.primarySchool"
              @change="primarySchoolChange"
              placeholder="请选择学制"
              disabled
            >
              <el-option
                v-for="item in eduSys"
                :key="item.id"
                :label="item.property"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="初中" prop="schEduSys">
            <el-select
              v-model="ruleForm.schEduSys"
              clearable
              disabled
              placeholder="请选择学制"
              style="width: 100%"
            >
              <el-option
                v-for="item in primarySchoolList"
                :key="item.id"
                :label="item.property"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="info_content" v-else>
          <el-form-item
            prop="schEduSys"
            :label="
              schoolPropertyOptions.find(
                (el) => el.id == ruleForm.schoolProperty
              ).property
            "
          >
            <el-select
              v-model="ruleForm.schEduSys"
              clearable
              filterable
              disabled
              placeholder="请选择学制"
              style="width: 100%"
            >
              <el-option
                v-for="item in eduSys"
                :key="item.id"
                :label="item.property"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <!-- 学校地址 -->
      <div class="school_address">
        <p class="info_title">学校地址</p>
        <div class="city_box">
          <el-form-item label="省" style="margin-right: 32px">
            <el-select v-model="ruleForm.area" clearable placeholder="请选择"  @change='onSelctArea'>
              <el-option
                :label="item.districtName"
                :value="item.id"
                :key="item.id"
                v-for="item in provinceOptions"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市" style="margin-right: 32px">
            <el-select v-model="ruleForm.city" clearable placeholder="请选择" @change='onSelctCity'>
              <el-option
                :label="item.districtName"
                :value="item.id"
                :key="item.id"
                v-for="item in cityOptions"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县">
            <el-select v-model="ruleForm.county" clearable placeholder="请选择">
              <el-option
                :label="item.districtName"
                :value="item.id"
                :key="item.id"
                v-for="item in countyOptions"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="详细地址">
          <el-input v-model="ruleForm.address" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <!-- 学校logo -->
      <div class="school_logo">
        <p class="info_title">学校logo</p>
        <div class="upload_box">
          <el-upload
            action="#"
            :show-file-list="false"
            accept=".jpeg,.png,.jpg,.svg"
            :http-request="uploadImg"
          >
            <div class="img">
              <div class="have_img" v-if="ruleForm.logoUrl">
                <img :src="imgPath + ruleForm.logoUrl" />
              </div>
              <div class="note_img" v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <circle cx="18" cy="18" r="18" fill="#999999" />
                  <path
                    d="M10 18H26"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                  />
                  <path
                    d="M18 26V10"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                  />
                </svg>
                <p class="p1">上传logo</p>
                <p class="p2">只支持JPG/PNG，大小不超过5M</p>
              </div>
            </div>
          </el-upload>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  props: [
    "schoolInfo"
],
  data() {
    return {
      imgPath: process.env.VUE_APP_FILE_URL,
      ruleForm: {
        schoolName: "", //学校名称
        forShort: "", //学校简称
        code: "", //学校机构代码
        nature: "", //学校性质
        schoolProperty: "", //学校类别
        schoolPropertyName: "", //学校类别名称
        schEduSys: null, //学制
        primarySchool: null, //小学学制
        area: "", //省code
        areaName: "", //省名称
        city: "", //市code
        cityName: "", //市名称
        county: "", //地区code
        countyName: "", //地区名称
        address: "", //详细地址
        logoUrl: "", //图标
      },
      schoolInfo:this.schoolInfo,
      rules: {
        schoolName: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        schoolProperty: [
          { required: true, message: "请选择学校类别", trigger: "change" },
        ],
        nature:[
          {required: true, message: "请选择学校性质", trigger: "change"}
        ],
        schEduSys: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        primarySchool: [{ required: false }],
      },
      computedTabsActive: null,
      schoolTypeOptions: [],
      schoolPropertyOptions: [],
      provinceOptions: [], // 省列表
      cityOptions: [], // 市列表
      countyOptions: [], //县列表
      isShowSchoolSys: false,
      eduSys: [],
      primarySchoolList: [],
    };
  },
  methods: {
    getParams() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let data = JSON.parse(JSON.stringify(this.ruleForm));
            data.schoolPropertyName = this.schoolPropertyOptions.find(
              (el) => el.id == data.schoolProperty
            ).property;
            if (data.area) {
              data.areaName = this.provinceOptions.find(
                (el) => el.id == data.area
              ).districtName;
            }
            if (data.city) {
              data.cityName = this.cityOptions.find(
                (el) => el.id == data.city
              ).districtName;
            }
            if (data.county) {
              console.log(this.countyOptions);
              data.countyName = this.countyOptions.find(
                (el) => el.id == data.county
              ).districtName;
            }
            
            resolve(data);
          } else {
            reject(false);
          }
        });
      });
    },

    // 获取学校性质
    getSchoolType() {
      this.$axios({
        method: "get",
        url: "/common/schoolBaseInfo/ignore/getSchoolType?baseType=nature",
        headers: {
          request_base_type: true,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.schoolTypeOptions = res.data.filter(
            (el) => el.baseType == "nature"
          );
        } else this.$message.error(res.describe);
      });
    },

    // 获取学校类别
    getSchoolProperty() {
      this.$axios({
        method: "get",
        url: "/common/schoolBaseInfo/ignore/getProperty",
        headers: {
          request_base_type: true,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.schoolPropertyOptions = res.data;
        } else this.$message.error(res.describe);
      });
    },

    // 获取省下拉
    getProOptions() {
      this.$axios({
        method: "get",
        url: "/common/schoolBaseInfo/ignore/getArea",
        headers: {
          request_base_type: true,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.provinceOptions = res.data;
        } else this.$message.error(res.describe);
      });
    },

    // 获取市下拉
    getCityOptions(id) {
      this.$axios({
        method: "get",
        url: "/common/schoolBaseInfo/ignore/getCity?pid=" + id,
        headers: {
          request_base_type: true,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.cityOptions = res.data;
          // this.countyOptions = [];
        } else this.$message.error(res.describe);
      });
    },

    // 获取县下拉
    getCountyOptions(id) {
      this.$axios({
        method: "get",
        url: "/common/schoolBaseInfo/ignore/getCounty?pid=" + id,
        headers: {
          request_base_type: true,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.countyOptions = res.data;
        } else this.$message.error(res.describe);
      });
    },

    changeSchoolPro(e) {
      this.ruleForm.primarySchool = "";
      this.eduSys = [];
      this.ruleForm.schEduSys = "";
      this.primarySchoolList = [];
      if (e) {
        this.getEduSys(e);
      } else this.isShowSchoolSys = false;
    },

    //学校学制发生改变
    primarySchoolChange(id) {
      this.ruleForm.schEduSys = "";
      if (id) {
        this.$axios({
          method: "get",
          url: "/common/schoolBaseInfo/ignore/getEduSys?pid=" + id,
          headers: {
            request_base_type: true,
          },
        }).then((res) => {
          if (res.code == 200) {
            this.primarySchoolList = res.data;
            this.isShowSchoolSys = true;
          } else this.$message.error(res.describe);
        });
      }
    },

    // 获取学制下拉
    getEduSys(id) {
      this.$axios({
        method: "get",
        url: "/common/schoolBaseInfo/ignore/getEduSys?pid=" + id,
        headers: {
          request_base_type: true,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.eduSys = res.data;
          this.isShowSchoolSys = true;
        } else this.$message.error(res.describe);
      });
    },

    // 上传学校logo
    uploadImg(e) {
      let formData = new FormData();
      formData.append("fileData", e.file);
      formData.append("fileDir", "school_logo");
      this.$axios({
        method: "post",
        url: "/file/upload/uploadFileSingleByDate",
        data: formData,
      })
        .then((res) => {
          if (res.code == 200) {
            this.ruleForm.logoUrl = res.result.filePath;
          } else this.$utils.messageText(res);
        })
        .catch((err) => this.$message.error(err));
    },
    setSchoolInfo(){
        this.ruleForm.schoolName = this.schoolInfo.schoolName
        this.ruleForm.area = this.schoolInfo.area
        this.ruleForm.city = this.schoolInfo.city
        this.ruleForm.county = this.schoolInfo.county
        this.ruleForm.address = this.schoolInfo.address
        this.ruleForm.nature = this.schoolInfo.nature
        this.ruleForm.schEduSys = this.schoolInfo.schEduSys
        this.ruleForm.primarySchool = this.schoolInfo.primarySchool
        this.ruleForm.logoUrl = this.schoolInfo.logo
        this.ruleForm.schoolProperty = this.schoolInfo.schProperty
        this.ruleForm.code = this.schoolInfo.code
        this.ruleForm.id = this.schoolInfo.id
        this.ruleForm.forShort = this.schoolInfo.forShort
        this.getEduSys(Number(  this.ruleForm.schoolProperty));
    },  
    // 查询学校基本信息
    getSchoolBaseInfo() {

    },
    onSelctArea(){
      console.log(2222);
      this.ruleForm.city = "";
        this.cityOptions = [];
        this.ruleForm.county = "";
        this.countyOptions = [];
    },
    onSelctCity(){
      console.log(111);
      this.ruleForm.county = "";
        this.countyOptions = [];
    }
  },
  mounted() {
    this.getSchoolType();
    this.getSchoolProperty();
    this.getProOptions();
    this.setSchoolInfo()
  },
  watch: {
    "ruleForm.area": {
      handler(newVal, oldVal) {
        if (newVal) this.getCityOptions(newVal);
      },
      immediate: true,
    },

    "ruleForm.city": {
      handler(newVal, oldVal) {
        if (newVal) this.getCountyOptions(newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.el-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info_title {
  color: #333;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
}

/deep/ .school_system_info {
  width: 100%;
  .info_content {
    display: flex;
    flex: 1;
  }
  .el-form-item {
    width: 100%;
  }
  .el-form-item__content {
    width: 100%;
  }
  .el-select {
    width: 100% !important;
  }
}

/deep/ .school_info {
  width: 100%;
  .el-form-100 {
    .el-form-item__content {
      width: 100% !important;
      .el-input {
        width: 100% !important;
      }
    }
  }
  .left_right_cont {
    width: 100%;
    display: flex;
    .el-form-item__content {
      width: 100% !important;
    }
    .el-select {
      width: 100% !important;
    }
    .left {
      flex: 1;
      margin-right: 32px;
    }
    .right {
      flex: 1;
    }
  }
}
/deep/ .school_address {
  .city_box {
    width: 100%;
    display: flex;
    .el-form-item {
      flex: 1;
    }
  }
  .el-form-item__content {
    width: 100% !important;
  }
}

.upload_box {
  width: 100%;
  > p {
    color: #8c9196;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
  }
  /deep/ .el-upload {
    margin-top: 16px;
    width: 100% !important;
  }
  .img {
    width: 100%;
    height: 176px;
    border-radius: 8px;
    border: 1px dashed #dedede;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .have_img {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        // width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .note_img {
      display: flex;
      flex-direction: column;
      align-items: center;
      .p1 {
        margin-top: 16px;
        color: #666;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .p2 {
        margin-top: 8px;
        color: #999;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
    }
  }
}
</style>
