<!--
 * @Author:
 * @Date: 2024-03-07 17:46:26
 * @LastEditors: yuan wei lin
 * @LastEditTime: 2024-08-19 15:10:32
 * @Description:
-->
<template>
  <div class="home_cont">
    <div class="home_cont_left">
      <Menu @isCollapse="setCollapse" ref="menuRef"></Menu>
    </div>
    <div class="home_cont_right">
      <Heade ref="header"></Heade>
      <div class="page_con">
        <!-- 面包屑 -->
        {{ breadcrumbList }}
        <div class="crumbs_box">
          <el-breadcrumb>
            <el-breadcrumb-item v-for="item in crumbsParamsList" :key="item.id"
              >{{ item.label }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 路由出口 -->
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Menu from "./menu_new.vue";
import Heade from "./head.vue";
export default {
  data() {
    return {
      crumbsParamsList: [], // 面包屑路由配置
      currentTabName: "",
    };
  },
  methods: {
    setCollapse(e) {
      this.$refs.header.updateCollapse(e);
    },

    resetMenu(bool) {
      if (this.$refs.menuRef) {
        let id = localStorage.getItem("schoolId");
        this.$refs.menuRef.getMenu(id, bool);
      }
    },

    // 设置面包屑
    setCrumbs() {
      if (this.$route.path.includes("desk/desk")) {
        this.crumbsParamsList = [
          {
            id: 1,
            label: "首页",
          },
        ];
      } else {
        let text = this.$route.meta.tabName;
        if (text) {
          let crumbs = text.split("/");
          this.crumbsParamsList = [];
          crumbs.forEach((item, index) => {
            this.crumbsParamsList.push({
              id: index,
              label: item,
              path: "",
            });
          });
        }
      }
    },
  },

  watch: {
    "$route.path": {
      handler(val) {
        this.setCrumbs();
      },
    },
  },
  components: { Heade, Menu },
};
</script>

<style scoped lang="less">
// 淡出淡入
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.home_cont {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #f1f2f6 !important;
  .home_cont_left {
    // width: 240px;
    height: 100%;
    overflow: hidden;
    flex-shrink: 0;
  }
  .home_cont_right {
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .page_con {
      width: 100%;
      flex: 1;
      padding: 16px;
      box-sizing: border-box;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }

  // 面包屑样式
  /deep/ .el-breadcrumb {
    font-size: 12px !important;
  }

  /deep/ .el-breadcrumb__inner {
    color: #999 !important;
  }

  .crumbs_box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    border-radius: 4px;
    user-select: none !important;
    margin: 0 0 8px 0;
    font-size: 12px !important;
    box-sizing: border-box;
  }
}
</style>
