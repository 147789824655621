<!--
 * @Author: Lijiahui
 * @Date: 2020-10-16 10:43:57
 * @LastEditors: Lijiahui
 * @LastEditTime: 2020-10-20 00:07:02
-->
<template>
  <div class="login_bck">
    <div class="topBar">
      <div style="margin-left: 34%; line-height: 60px" @click="handleLogin">
        <img src="../../../../assets/images/wjmm_ic_fanhui.png"  />
        <span style="font-size: 14px;font-weight: 500; color: #ffffff; margin-left: 10px;">返回登录</span>
      </div>
    </div>
    <div class="login_content">
    <psdOne ref="psdOne" v-if="success==='false'"></psdOne>
 <psdSuccess ref="psdSuccess" v-else></psdSuccess>
    </div>
    <div class="footer">
      <div>智慧物业管理</div>
      <div>
        备案号:京ICP备16017530号-1 版权商标所有“国天云®”,请勿侵权 Copyright ©
        2012-2017. All Rights Reserved
      </div>
    </div>
  </div>
</template>

<script>
  import forgetPsd from './js/forgetPsd'
  export default {
    ...forgetPsd
  }
</script>
<style lang='less' scoped>
  @import "./css/forgetPsd.less";
</style>
