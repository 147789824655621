// threejs基础参数，配置场景、相机、渲染器、动画、灯光等
import * as THREE from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { CSS3DRenderer } from "three/addons/renderers/CSS3DRenderer.js";
import { CSS2DRenderer } from "three/addons/renderers/CSS2DRenderer.js";
import campusBgimg from "@/assets/images/campusData/campusBg.png";
// 页面宽高
const width = window.innerWidth;
const height = window.innerHeight;
console.log(height);
// 场景
const scene = new THREE.Scene();

const texture = new THREE.TextureLoader().load(campusBgimg);
texture.colorSpace = THREE.SRGBColorSpace; //设置为SRGB颜色空间
scene.background = texture;

// 灯光
const ambientLight = new THREE.AmbientLight(0xffffff, 3);
scene.add(ambientLight);

// 相机
const camera = new THREE.PerspectiveCamera(30, width / height, 1, 10000);
camera.position.set(0, 9000, 7000);
camera.lookAt(0, 0, 0);

// 渲染器
const renderer = new THREE.WebGLRenderer({
  antialias: true,
});
renderer.setPixelRatio(window.devicePixelRatio); //防止输出模糊
renderer.setSize(width, height); // 定义渲染区域大小

// CSS3D渲染器
const css3DRenderer = new CSS3DRenderer();
css3DRenderer.setSize(width, height);
css3DRenderer.domElement.style.position = "absolute";
css3DRenderer.domElement.style.top = "0px";
css3DRenderer.domElement.style.pointerEvents = "none";

// CSS2D渲染器
const css2DRenderer = new CSS2DRenderer();
css2DRenderer.setSize(width, height);
css2DRenderer.domElement.style.position = "absolute";
css2DRenderer.domElement.style.top = "0px";
css2DRenderer.domElement.style.pointerEvents = "none";

// 相机轨道控制器
const controls = new OrbitControls(camera, renderer.domElement);
controls.dampingFactor = 0.1; // 阻尼系数
controls.enableDamping = true; // 阻尼开启
controls.minPolarAngle = -Math.PI; // 控制相机最小旋转角度
controls.maxPolarAngle = Math.PI / 2.5; // 控制相机最大旋转角度
controls.minDistance = 80;
controls.maxDistance = 1000;

// 画布跟随窗口变化
window.addEventListener("resize", () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  // cnavas画布宽高度重新设置
  renderer.setSize(width, height);
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
  css3DRenderer.setSize(width, height);
  css2DRenderer.setSize(width, height);
  css3DRenderer.render(scene, camera);
  css2DRenderer.render(scene, camera);
});

export { scene, camera, controls, renderer, css3DRenderer, css2DRenderer };
