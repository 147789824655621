import psdOne from "../psdOne";
import psdSuccess from "../psdSuccess";

export default {
  components: {
    psdOne,
    psdSuccess,
  },
  data() {
    return {
      success:'false',
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    handleLogin(){
      this.$router.push({
        name: 'login'
      })
    }
  },
};
