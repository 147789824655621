<template>
  <div class="menu-cont">
    <ul>
      <li class="menu-item" v-for="item in menu" :key="item.id">
        <div class="item-title" @click="foldMenu('一', item)">
          <img
            class="img"
            v-show="currentPathCode.substring(0, 4) == item.funCode"
            :src="imgPath + item.funClickImg"
          />
          <img
            class="img"
            v-show="currentPathCode.substring(0, 4) != item.funCode"
            :src="imgPath + item.funImg"
          />
          <p
            v-if="isShow"
            :style="{
              color:
                currentPathCode.substring(0, 4) == item.funCode
                  ? '#09B189'
                  : '#202020',
            }"
            class="name"
          >
            {{ item.name }}
          </p>

          <!-- 箭头 -->
          <template v-if="item.children && item.children.length">
            <img
              :class="[
                'arraw',
                activeMenuCode == item.funCode ? 'active_arraw' : '',
              ]"
              :src="
                currentPathCode.substring(0, 4) == item.funCode
                  ? menuActiveIcon
                  : menuDefaultIcon
              "
            />
          </template>
        </div>
        <Collapse>
          <!-- 二级 -->
          <div
            class="children-item"
            v-if="
              item.children &&
              item.children.length &&
              activeMenuCode == item.funCode
            "
          >
            <div class="chil-item" v-for="Item in item.children" :key="Item.id">
              <div class="chil-item-title" @click="foldMenu('二', Item)">
                <img
                  v-if="Item.children && Item.children.length"
                  :class="[
                    'item_icon',
                    activeMenuSubCode == Item.funCode ? 'active_arraw' : '',
                  ]"
                  src="../../assets/img/menu_sub_close_icon.png"
                />
                <p
                  :style="{
                    marginLeft:
                      !Item.children || !Item.children.length ? '30px' : '',
                  }"
                >
                  {{ Item.name }}
                </p>
              </div>

              <!-- 三级 -->
              <Collapse>
                <div
                  class="chil-item-children"
                  v-if="
                    Item.children &&
                    Item.children.length &&
                    activeMenuSubCode == Item.funCode
                  "
                >
                  <div
                    @click="openLink(ITEM)"
                    v-for="ITEM in Item.children"
                    :key="ITEM.id"
                    :class="[
                      'sub-menu-item-title',
                      currentPathCode == ITEM.funCode ? 'active-sub-menu' : '',
                    ]"
                  >
                    <p>{{ ITEM.name }}</p>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </Collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import Collapse from "../../components/foldTemplate.vue";
import Storage from "@/commons/utils/storage.js";
export default {
  props: ["isCollapse", "menu"],
  data() {
    return {
      user: {},
      menuActiveIcon: require("../../assets/img/menu_active_icon.svg"),
      menuDefaultIcon: require("../../assets/img/menu_close_icon.png"),
      imgPath: process.env.VUE_APP_FILE_URL,
      isShow: true,
      activeMenuCode: "", // 选中的一级
      activeMenuSubCode: "", // 选中的二级
      currentPathCode: "", // 选中的三级
      externaljumpList: ["自选课管理", "德育维度", "农业平台"],
    };
  },
  methods: {
    // 折叠菜单
    foldMenu(grade, item) {
      this.$emit("clearMenuOpen");
      if (grade == "一") {
        if (this.activeMenuCode == item.funCode) {
          this.activeMenuCode = "";
        } else {
          if (item.children) {
            this.activeMenuCode = item.funCode;
          } else {
            this.currentPathCode = item.funCode;
            this.activeMenuCode = item.funCode;
            this.openLink(item);
          }
        }
      }

      if (grade == "二") {
        if (this.activeMenuSubCode == item.funCode) {
          this.activeMenuSubCode = "";
        } else {
          if (item.children) {
            this.activeMenuSubCode = item.funCode;
          } else this.openLink(item);
        }
      }
    },

    clearActiveType() {
      this.activeMenuCode = ""; // 选中的一级
      // this.activeMenuSubCode = ""; // 选中的二级
      // this.currentPathCode = ""; // 选中的三级
    },

    openLink(row) {
      if (this.externaljumpList.includes(row.name)) {
        if (row.name == "自选课管理" || row.name == "德育维度") {
          this.goMyProjects(row);
        } else if (row.name == "农业平台") {
          this.goNongYe(row);
        }
      } else this.$router.push({ path: row.link });
    },

    setActive() {
      let { path, meta } = this.$route;
      if (path.includes("/base/main/desk/desk")) {
        this.activeMenuCode = "1111";
        this.currentPathCode = "1111";
        this.activeMenuSubCode = "1111";
      } else {
        this.activeMenuCode = meta.funCode.substring(0, 4);
        this.activeMenuSubCode = meta.funCode.substring(0, 8);
        this.currentPathCode = meta.funCode;
      }
    },

    // 跳转马屿小学项目
    goMyProjects(item) {
      let SID = this.user.token;
      let mobile = this.user.mobile;
      let type, url;
      url = this.getProxyLink();
      // url = "http://localhost/campus-web-my/";
      if (item.name == "自选课管理") type = "view_course";
      if (item.name == "德育维度") type = "view_deyu_evaluation";
      let queryString = "?SID=" + SID + "&mobile=" + mobile + "&type=" + type;
      window.open(url + queryString, "_blank");
    },

    goNongYe() {
      let orgId = localStorage.getItem("schoolId") || null;
      if (orgId == "2208010064") {
        // 测试实验小学
        window.open(
          "http://121.40.125.107:30575/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else if (orgId == "2209140066") {
        // 正式毓蒙
        window.open(
          "http://agriculture.prod.camp.huaching.com/dovey-environment/login/input"
        );
      } else if (orgId == "2407090079") {
        // 正式马鞍山实验小学
        window.open(
          "http://121.40.125.107:31138/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else if (orgId == "2411110082") {
        // 文成县珊溪镇中心小学
        window.open(
          "http://121.40.125.107:31011/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else {
        // 其他
        window.open(
          "http://agriculture.prod.camp.huaching.com/dovey-environment/login/input"
        );
      }
    },

    getProxyLink() {
      let type = process.env.VUE_APP_ENV;
      let url = "";
      switch (type) {
        case "test":
          // 测试
          url =
            "https://nginx.file.campus.project.kube.huaching.com/proxy/campus-web-my/";
          break;
        // 演示
        case "pre":
          url = "https://campus.huaching.com/campus-web-my/";
          break;
        // 正式
        case "prod":
          url = "https://campus.huaching.com/campus-web-my/";
          break;

        default:
          break;
      }
      return url;
    },
  },
  mounted() {
    let userInfo = Storage.get("user");
    if (userInfo) {
      this.user = JSON.parse(decodeURIComponent(decodeURIComponent(userInfo)));
    }
    this.setActive();
  },
  watch: {
    "$route.path": {
      handler(val) {
        this.setActive();
      },
    },
  },
  components: {
    Collapse,
  },
};
</script>
<style lang="less" scoped>
.menu-cont {
  ul {
    .menu-item {
      overflow: hidden;
      .item-title {
        height: 36px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin: 2px 0;
        padding: 4px 16px;
        box-sizing: border-box;
        cursor: pointer;
        .img {
          width: 20px;
          height: 20px;
          margin-bottom: 2px;
        }
        .name {
          flex: 1;
          font-family: "PingFang SC";
          margin-left: 10px;
          white-space: nowrap !important;
          color: #202020;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        .arraw {
          width: 16px;
          height: 16px;
          margin-left: 20px;
          transition: all 0.2s;
        }
        &:hover {
          background-color: #f6f6f7 !important;
        }
        &:active {
          background-color: #f6f6f7 !important;
        }
      }
    }

    .children-item {
      padding: 4px;
      box-sizing: border-box;
      border-radius: 8px;
      background: #fafbfb;
      .chil-item {
        padding: 1px 12px;
        box-sizing: border-box;
        .chil-item-title {
          display: flex;
          height: 38px;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          color: #333;
          border-radius: 8px;
          img {
            margin-right: 10px;
            border-radius: 4px;
            &:hover {
              background-color: #f6f6f7;
            }
          }
        }
        // 三级
        .chil-item-children {
          box-sizing: border-box;
          transition: all 0.2s;
          .sub-menu-item-title {
            box-sizing: border-box;
            padding-left: 30px;
            height: 38px;
            font-size: 14px;
            display: flex;
            color: #5c5f62;
            align-items: center;
            cursor: pointer;
            border-radius: 8px;
            &:hover {
              background-color: #f6f6f7 !important;
            }
          }
        }
      }
      .item_icon {
        width: 20px;
        height: 20px;
        transition: all 0.2s;
      }
    }
  }
}
.active-sub-menu {
  color: #09b189 !important;
  background-color: #f1f2f3 !important;
}

.active_arraw {
  transform: rotate(90deg);
}
</style>
