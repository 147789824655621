<!--
 * @Author: 
 * @Date: 2024-03-07 17:46:26
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2024-05-31 14:51:47
 * @Description: 
-->
<template>
  <div class="home_cont">
    <Heade></Heade>
    <div class="contents">
      <img src="../../assets/images/noteAuth.svg" alt="" />
      <p class="p1">平台使用权限已到期</p>
      <p class="p2">为了不影响您的使用,请及时联系服务商处理</p>
    </div>
  </div>
</template>
<script>
import Heade from "../home/head.vue";
export default {
  data() {
    return {
      crumbsParamsList: [], // 面包屑路由配置
      currentTabName: "",
    };
  },

  methods: {
    resetMenu(bool) {},
    setCrumbs() {},
  },

  components: {
    Heade,
  },
};
</script>

<style scoped lang="less">
.home_cont {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #f1f2f6 !important;
  display: flex;
  flex-direction: column;
  .contents {
    margin-top: 8px;
    background-color: #fff;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .p1 {
      color: #333;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .p2 {
      margin-top: 16px;
      color: #999;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}
</style>
