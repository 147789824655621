import * as THREE from "three";
// 引入d3.js
import * as d3 from "d3";
// 片元着色器代码
import outputFragment from "./output_fragment.glsl.js";
const waterObj = {};
const mapModel = new THREE.Group();
mapModel.rotateX(-Math.PI / 2);
mapModel.name = "地图模型";
// 墨卡托投影转换
const projection = d3
  .geoMercator()
  .center([120.734152, 27.786612])
  .translate([0, 0]);
// 省会城市数据
const cityData = [];
// 地图模型侧边材质
const sideMaterial = new THREE.MeshBasicMaterial({
  // color: '#00BFFF',
  color: "#082475",
  transparent: true,
  opacity: 1,
});
// 侧边材质uniform
let mapUf = {
  uTime: { value: 0.0 },
  uHeight: { value: 4 },
  uColor: { value: new THREE.Color("#4169E1") },
  uStart: { value: -4 },
  uSpeed: { value: 6 },
};
sideMaterial.onBeforeCompile = (shader) => {
  shader.uniforms = {
    ...shader.uniforms,
    ...mapUf,
  };
  shader.vertexShader = shader.vertexShader.replace(
    "void main() {",
    `
              varying vec3 vPosition;
              void main() {
                vPosition = position;
            `
  );
  shader.fragmentShader = shader.fragmentShader.replace(
    "void main() {",
    `
              varying vec3 vPosition;
              uniform float uTime;
              uniform vec3 uColor;
              uniform float uSpeed;
              uniform float uStart;
              uniform float uHeight;
              void main() {
            `
  );
  shader.fragmentShader = shader.fragmentShader.replace(
    "vec3 outgoingLight = reflectedLight.indirectDiffuse;",
    outputFragment
  );
};
// 加载全国地图数据
async function loadChinaMapData() {
  // 文件加载器，设置类型为json
  const fileLoader = new THREE.FileLoader();
  fileLoader.responseType = "json";
  // 加载全国地图数据
  const mapData = await fileLoader.loadAsync("./ruianMap.json");
  operationData(mapData);
}
// 解析地图json数据
function operationData(data) {
  // 全部信息
  const features = data.features;
  features.map((feature) => {
    // 城市模型
    const cityModel = new THREE.Object3D();
    cityModel.name = feature.properties.name;
    mapModel.add(cityModel);
    const coordinates = feature.geometry.coordinates;
    // 绘制地级市边界线
    if (feature.geometry.type === "MultiPolygon") {
      coordinates.forEach((coordinate) => {
        coordinate.forEach((rows) => {
          // 城市模型
          const mesh = drawExtrudeMesh(rows);
          mesh.rotateX(Math.PI);
          cityModel.add(mesh);
          // 边线
          const line = lineDraw(rows);
          line.name = "边线";
          line.position.z += 0.15;
          cityModel.add(line);
        });
      });
    }
    // 创建省份边界线和模型-单个多边形
    if (feature.geometry.type === "Polygon") {
      coordinates.forEach((coordinate) => {
        // 城市模型
        const mesh = drawExtrudeMesh(coordinate);
        mesh.rotateX(Math.PI);
        cityModel.add(mesh);
        // 边线
        const line = lineDraw(coordinate);
        line.position.z += 0.15;
        line.name = "边线";
        cityModel.add(line);
      });
    }
  });
}

// 绘制模型
function drawExtrudeMesh(polygon) {
  // 创建形状
  const shape = new THREE.Shape();
  // 遍历坐标数组，绘制形状
  polygon.forEach((row, i) => {
    // 坐标点转换
    const [x, y] = projection(row);
    if (i === 0) {
      shape.moveTo(x, y);
    }
    shape.lineTo(x, y);
  });
  // 将形状进行拉伸
  const geometry = new THREE.ExtrudeGeometry(shape, {
    depth: 4,
    bevelEnabled: true,
    bevelSegments: 10,
    bevelThickness: 0.1,
  });
  // 模型顶部材质
  const material = new THREE.MeshStandardMaterial({
    // 默认先使用深蓝色，不适用计算插值的color
    color: new THREE.Color("#082475"),
    emissiveIntensity: 0.1,
    transparent: true,
    opacity: 0.5,
  });
  const mesh = new THREE.Mesh(geometry, [material, sideMaterial]);
  return mesh;
}
// 绘制边界线
function lineDraw(polygon) {
  const lineGeometry = new THREE.BufferGeometry();
  const pointsArray = new Array();
  polygon.forEach((row) => {
    const [x, y] = projection(row);
    // 创建三维点
    pointsArray.push(new THREE.Vector3(x, -y, 0));
  });
  // 放入多个点
  lineGeometry.setFromPoints(pointsArray);
  const lineMaterial = new THREE.LineBasicMaterial({
    color: "#9ED0EA",
  });
  return new THREE.Line(lineGeometry, lineMaterial);
}
export { loadChinaMapData, mapModel, cityData, mapUf, projection, waterObj };
