
export default {
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    handleLogin_1(){
      this.$parent.handleLogin();
    }
  },
};
