<template>
  <div class="viewWrap">
    <div class="name">瑞安中学</div>
    <div class="schoolSection">
      <div class="scItem">
        <div class="scTitle">班级数量</div>
        <div class="scnums">482</div>
      </div>
      <div class="scItem">
        <div class="scTitle">班级数量</div>
        <div class="scnums">482</div>
      </div>
      <div class="scItem">
        <div class="scTitle">班级数量</div>
        <div class="scnums">482</div>
      </div>
      <div class="scItem">
        <div class="scTitle">班级数量</div>
        <div class="scnums">482</div>
      </div>
      <div class="scItem">
        <div class="scTitle">班级数量</div>
        <div class="scnums">482</div>
      </div>
    </div>
    <div class="footerSection">
      <div class="fItem">
        <div class="fLabel">学生人数</div>
        <div class="nums">2897</div>
      </div>
      <div class="fItemBox">
        <div class="proportion">16:9</div>
        <div class="desc">师生对比</div>
      </div>
      <div class="fItem">
        <div class="fLabel">老师人数</div>
        <div class="nums">2897</div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.viewWrap {
  position: fixed;
  left: 50%;
  top: 30%;
  width: 379px;
  height: 414px;
  background: url("@/assets/images/campusData/schoolViewAlert.png") no-repeat;
  background-size: 100% 100%;
  padding: 46px 40px;
  box-sizing: border-box;
  .name {
    color: #fff;
    text-shadow: 2px 2px 6px #1559be;
    font-family: "Alimama FangYuanTi VF";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.16px;
    margin-bottom: 35px;
    padding-left: 4px;
  }
  .schoolSection {
    display: flex;
    flex-wrap: wrap;
    .scItem {
      margin-right: 40px;
      margin-bottom: 29px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      .scTitle {
        color: #1a95d9;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 10px;
        position: relative;
        padding-left: 13px;
        box-sizing: border-box;
        &::after {
          content: "";
          width: 8px;
          height: 14px;

          background: linear-gradient(
            270deg,
            rgba(14, 79, 115, 0) 0%,
            #1a95d9 100%
          );

          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-7px);
        }
      }
      .scnums {
        color: #ffc000;
        font-family: Akrobat;
        font-size: 22px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.44px;
        text-align: center;
      }
    }
  }
  .footerSection {
    display: flex;
    justify-content: center;
    .fItem {
      flex: 1;
      text-align: center;
      position: relative;
      box-sizing: border-box;
      padding-top: 28px;
      &::after {
        content: "";
        background: url("@/assets/images/campusData/toplineIcon.png") no-repeat;
        background-size: 40px 4px;
        position: absolute;
        left: 0;
        top: 0;
      }

      .fLabel {
        color: #1a95d9;
        font-family: "PingFang SC";
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 6px;
      }
      .nums {
        color: #188df3;
        font-family: Akrobat;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.4px;
      }
    }
    .fItemBox {
      width: 85px;
      height: 85px;
      text-align: center;
      background: url("@/assets/images/campusData/qbiliIcon.png") center
        no-repeat;
      background-size: 85px 85px;
      padding-top: 28px;
      box-sizing: border-box;
      .proportion {
        color: #ffc000;
        text-align: center;
        font-family: Akrobat;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.32px;
      }
      .desc {
        color: #1a95d9;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 7px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
</style>
