/*
 * @Author: 
 * @Date: 2023-02-26 16:50:35
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2023-06-29 15:17:54
 * @Description: 
 */
import rules from "@/components/EditForm/js/rules.js";
export default {
  components: {},
  data() {
    return {
      forgetForm: {
        phoneNumber: "",
        Pnumber: "",
        newPassword: "",
      },
      forgetRules: {
        phoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator:rules.FormValidate.Form().validatePhone, message: "请输入正确的手机号", trigger: "blur" },
        ],

        Pnumber: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 3, max: 5, message: "长度在4个字符", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新的密码", trigger: "blur" },
          { min: 3, max: 5, message: "长度在6个字符", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {

  },
  methods: {
    goSuccess(){
      // console.log(1111);
      let this_=this;
      if(this_.success=='false'){
        this_.success = ''
      }else{
        this_.success = 'false'
      }
    },
  },
};
