import request from "../commons/utils/axios";

// 查询权限
export function searchMenu(name, cancelToken) {
  return request({
    url: `/system/func/app/getRoleFuncByNamePC?funcName=${name}`,
    method: "get",
    cancelToken: cancelToken,
  });
}

// 新增搜索历史
export function addMenuSearchHistory(data) {
  return request({
    url: `/system/func/app/addMenuSearchHistoryPC`,
    method: "post",
    data,
  });
}

// 查询搜索历史
export function getSearchHistory() {
  return request({
    url: "/system/func/app/getUserMenuSearchHistoryPC",
    method: "get",
  });
}

// 删除搜索历史
// type: 1:单个 2:全部
export function deleteSearchHistory(type, funcId) {
  let url;
  if (type == 1) url = `deleteMenuSearchHistoryPC?funcId=${funcId}`;
  else url = "deleteAllMenuSearchHistoryPC";
  return request({
    url: `system/func/app/${url}`,
    method: "post",
  });
}
