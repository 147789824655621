import request from "../commons/utils/axios";
import qs from "qs";

// 查询当前学校信息
export function regionClassOverview(data) {
  return request({
    url: "/data_governance/region/baseinfo/region_class_overview",
    method: "get",
    data: {},
    params: data,
  });
}

//区域人员概况
export function regionPeopleOverview(data) {
  return request({
    url: "/data_governance/region/baseinfo/region_people_overview",
    method: "get",
    data: {},
    params: data,
  });
}

//学生考勤今日出勤

export function nowDayRegionStudentAttendance(data) {
  return request({
    url: "/data_governance/region/student_biz/now_day_region_student_attendance",
    method: "get",
    data: {},
    params: data,
  });
}

//学生考勤迟到
export function lastMonthRegionStudentAttendance(data) {
  return request({
    url: "/data_governance/region/student_biz/last_month_region_student_attendance",
    method: "get",
    data: {},
    params: data,
  });
}

//近15日学生请假次数统计
export function pastDaysRegionStudentLeave(data) {
  return request({
    url: "/data_governance/region/student_biz/past_days_region_student_leave",
    method: "get",
    data: {},
    params: data,
  });
}

//近15日 学生请假趋势
export function pastDaysRegionStudentLeaveTrend(data) {
  return request({
    url: "/data_governance/region/student_biz/past_days_region_student_leave_trend",
    method: "get",
    data: {},
    params: data,
  });
}

//区域下老师今日考勤
export function nowDayRegionTeacherAttendance(data) {
  return request({
    url: "/data_governance/region/teacher_biz/now_day_region_teacher_attendance",
    method: "get",
    data: {},
    params: data,
  });
}

//区域下老师7日考勤
export function pastDaysRegionTeacherAttendanceTrend(data) {
  return request({
    url: "/data_governance/region/teacher_biz/past_days_region_teacher_attendance_trend",
    method: "get",
    data: {},
    params: data,
  });
}

//区域下访客数量

export function regionVisitorNum(data) {
  return request({
    url: "/data_governance/region/teacher_biz/region_visitor_num",
    method: "get",
    data: {},
    params: data,
  });
}

//区域本月到访事由统计
export function regionVisitorReason(data) {
  return request({
    url: "/data_governance/region/teacher_biz/region_visitor_reason",
    method: "get",
    data: {},
    params: data,
  });
}

//宿舍楼信息
export function regionDormitoryInfo(data) {
  return request({
    url: "/data_governance/region/logistic_biz/region_dormitory_info",
    method: "get",
    data: {},
    params: data,
  });
}

//近15日归勤趋势
export function regionDormitoryGoBedStuNumTrend(data) {
  return request({
    url: "/data_governance/region/logistic_biz/region_dormitory_go_bed_stu_num_trend",
    method: "get",
    data: {},
    params: data,
  });
}
//区域下近15日平均归寝率、请假率、未归率
export function regionDormitoryGoBedPercent(data) {
  return request({
    url: "/data_governance/region/logistic_biz/region_dormitory_go_bed_percent",
    method: "get",
    data: {},
    params: data,
  });
}

//区域下近15日归寝时间段下平均归寝人数
export function regionDormitoryTimePeriodGoBedStuNum(data) {
  return request({
    url: "/data_governance/region/logistic_biz/region_dormitory_time_period_go_bed_stu_num",
    method: "get",
    data: {},
    params: data,
  });
}

//车辆信息
export function pastDaysRegionCarNumTrend(data) {
  return request({
    url: "/data_governance/region/teacher_biz/past_days_region_car_num_trend",
    method: "get",
    data: {},
    params: data,
  });
}
//用电概况
export function regionEnergySumData(data) {
  return request({
    url: "/data_governance/region/logistic_biz/region_energy_sum_data",
    method: "get",
    data: {},
    params: data,
  });
}

//加载天气
export function todayTomorrow(data) {
  return request({
    url: "/common/weather/app/org/today_tomorrow",
    method: "get",
    data: {},
    params: data,
  });
}

//根据区域查询学校列表
export function schoolListByRegion(data) {
  return request({
    url: "/data_governance/region/baseinfo/school_list_by_region",
    method: "get",
    data: {},
    params: data,
  });
}

//用水趋势图
export function waterPastDaysTrend(data) {
  return request({
    url: "/data_governance/region/logistic_biz/water_past_days_trend",
    method: "get",
    data: {},
    params: data,
  });
}

//用电趋势图
export function electricityPastDaysTrend(data) {
  return request({
    url: "/data_governance/region/logistic_biz/electricity_past_days_trend",
    method: "get",
    data: {},
    params: data,
  });
}

//学校概况
export function schoolOverview(data) {
  return request({
    url: "/data_governance/region/baseinfo/school_overview",
    method: "get",
    data: {},
    params: data,
  });
}
