<template>
  <div class="header_cont">
    <div class="header_l">
      <p v-if="$route.path == '/base/main/desk/desk'">首页</p>
      <p v-else>{{ $route.meta.title }}</p>
    </div>
    <div class="header_r">
      <div class="search-menu-inp" ref="yourElement">
        <!-- 新版搜索框 -->
        <el-input
          class="inpt"
          placeholder="请输入学生姓名或功能名称"
          v-model="menuName"
          readonly
          @click.stop="focusInput2"
          maxlength="20"
        >
          <template #suffix>
            <p class="input-suffix-text" @click="clearInput2" v-if="menuName">
              清除
            </p>
          </template></el-input
        >

        <el-dialog
          :show-close="false"
          v-model="searchDialogShow"
          class="my-dialog"
        >
          <template #header>
            <div class="my-header">
              <el-input
                class="input"
                placeholder="请输入学生姓名或功能名称"
                v-model="menuName"
                :clearable="false"
                ref="searchInputRef"
                @input="inputSearch"
                maxlength="20"
              >
                <template #suffix>
                  <p
                    class="input-suffix-text"
                    @click="clearInput"
                    v-if="menuName"
                  >
                    清除
                  </p>
                </template>
              </el-input>
              <div @click.stop="handleClose2" class="closebtn">
                <img src="../../assets/images/search_dialog_close.svg" />
              </div>
            </div>
          </template>
          <div class="my-content">
            <div class="tabs">
              <div
                @click="changeSearchTab(1)"
                :class="['item', currentSearchType == 1 ? 'active_item' : '']"
              >
                <p>全部</p>
                <div v-if="currentSearchType == 1" class="line"></div>
              </div>
              <div
                @click="changeSearchTab(2)"
                :class="['item', currentSearchType == 2 ? 'active_item' : '']"
              >
                <p>学生</p>
                <div v-if="currentSearchType == 2" class="line"></div>
              </div>
              <div
                @click="changeSearchTab(3)"
                :class="['item', currentSearchType == 3 ? 'active_item' : '']"
              >
                <p>功能</p>
                <div v-if="currentSearchType == 3" class="line"></div>
              </div>
            </div>
            <div class="cont">
              <Loading
                v-if="menuName && !isLoading && !isShowEmptyImg"
              ></Loading>
              <!-- 搜索历史记录 -->
              <div
                class="search-cont"
                v-if="
                  !menuName && searchHistory.length && currentSearchType == 1
                "
              >
                <div class="title">
                  <p>历史记录</p>
                  <img
                    v-if="!isDeleteHistory"
                    @click="isDeleteHistory = true"
                    src="../../assets/img/global-menu-delete-grey.svg"
                  />
                  <div v-else>
                    <p class="p1" @click="setSearchHistory(null, 'clear')">
                      全部删除
                    </p>
                    <div class="line"></div>
                    <p class="p2" @click="isDeleteHistory = false">完成</p>
                  </div>
                </div>

                <div class="content">
                  <div
                    class="item"
                    v-for="(item, index) in searchHistory"
                    :key="item"
                  >
                    <p @click="inputSearch(item)">{{ item }}</p>
                    <img
                      v-if="isDeleteHistory"
                      @click="setSearchHistory(null, 'del', index)"
                      src="../../assets/images/del-search-hitory-item-icon.svg"
                    />
                  </div>
                </div>
              </div>

              <!-- 学生画像 -->
              <div
                v-if="studentPortraitList.length"
                class="cont-info st-pri-box"
              >
                <p class="title">学生画像({{ studentPortraitTotal }})</p>
                <div class="list">
                  <ul>
                    <li
                      v-for="item in studentPortraitList"
                      :key="item.studentId"
                      @click="jumpPage(item, 1)"
                    >
                      <div class="left">
                        <img src="../../assets/images/student-pro-icon.svg" />
                      </div>
                      <div class="right">
                        <p v-html="highlightMatch(item.displayName)"></p>
                        <p>的学生画像</p>
                      </div>
                    </li>
                  </ul>

                  <div
                    class="more more2"
                    @click="getMoreStPicList"
                    v-if="
                      studentPortraitList.length < studentPortraitTotal &&
                      !stPortraitLoading
                    "
                  >
                    <p>展开查看更多</p>
                    <img
                      style="transform: rotate(180deg)"
                      src="../../assets/images/icon/arrowTopIcon.png"
                    />
                  </div>

                  <div class="more" v-if="stPortraitLoading">
                    <img
                      style="width: 12px; height: 12px; margin-right: 4px"
                      src="../../assets/images/search-home-student-pri-loading.gif"
                    />
                    <p>加载中</p>
                  </div>

                  <div
                    class="more"
                    v-if="studentPortraitList.length >= studentPortraitTotal"
                  >
                    <p>暂无更多数据</p>
                  </div>
                </div>
              </div>

              <!-- 学生相关功能 -->
              <div
                class="cont-info st-func-box"
                v-if="studentFunctionList.length"
              >
                <p class="title">学生功能</p>
                <div class="list">
                  <ul>
                    <li
                      v-for="item in studentFunctionList"
                      :key="item.studentId"
                      @click="jumpPage(item, 2)"
                    >
                      <div class="left">
                        <img :src="imgPath + item.funClickImg" />
                      </div>
                      <div class="right">
                        <p class="p1">{{ item.menuName }}</p>
                        <p class="p2">
                          包含学生:
                          <span v-html="highlightMatch(item.stNameStr)"></span>
                        </p>
                      </div>
                    </li>
                  </ul>

                  <div
                    class="more more2"
                    @click="getStudentFunction"
                    v-if="studentFuncIsData && !studentFuncLoading"
                  >
                    <p>展开查看更多</p>
                    <img
                      style="transform: rotate(180deg)"
                      src="../../assets/images/icon/arrowTopIcon.png"
                    />
                  </div>

                  <div class="more" v-if="studentFuncLoading">
                    <img
                      style="width: 12px; height: 12px; margin-right: 4px"
                      src="../../assets/images/search-home-student-pri-loading.gif"
                    />
                    <p>加载中</p>
                  </div>

                  <div class="more" v-if="!studentFuncIsData">
                    <p>暂无更多数据</p>
                  </div>
                </div>
              </div>

              <!-- 菜单 -->
              <div
                v-if="searchMenuTree.length"
                class="cont-info menu-tree-cont"
              >
                <p class="title">功能 ({{ searchMenuTreeTotal }})</p>
                <div class="list">
                  <div
                    class="tree-item"
                    v-for="item in searchMenuTree"
                    :key="item.id"
                  >
                    <div class="list-item-title">
                      <img :src="imgPath + item.funClickImg" />
                      <p
                        v-html="
                          highlightMatch(`${item.name} (${item.childrenTotal})`)
                        "
                      ></p>
                    </div>
                    <div
                      class="list-item-children"
                      v-for="Item in item.children"
                      :key="Item.id"
                    >
                      <div class="children-name">
                        <p v-html="highlightMatch(Item.name)"></p>
                      </div>
                      <div class="children-item">
                        <p
                          @click="jumpPage(ITEM, 3)"
                          v-for="ITEM in Item.children"
                          :key="ITEM.id"
                          v-html="highlightMatch(ITEM.name)"
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 缺省图 -->
              <div class="empty-img" v-if="isLoading && !menuName">
                <img src="../../assets/img/global-searchmenu-noteicon.svg" />
                <p class="msg">暂无内容</p>
                <p class="msg2">请输入学生姓名或功能名称进行搜索</p>
              </div>

              <!-- 缺省图 -->
              <div
                class="empty-img"
                v-if="isLoading && menuName && isShowEmptyImg"
              >
                <img src="../../assets/img/global-searchmenu-noteicon.svg" />
                <p class="msg">
                  没有找到“<span style="color: #09b189">{{ menuName }}</span
                  >”相关的<span>{{
                    currentSearchType == 1
                      ? "功能或学生"
                      : currentSearchType == 2
                      ? "学生"
                      : "功能"
                  }}</span>
                </p>
                <p class="msg2">
                  请检查关键词是否正确，或尝试更换关键词进行搜索
                </p>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>

      <div class="return-old-box" v-if="oldLabel">
        <span class="jump-old-btn" @click="goOldSystem">返回旧版</span>
        <span class="line"></span>
      </div>
      <div style="margin-right: 20px">
        <!-- 学校 -->
        <el-dropdown
          @command="changeDropdownItem"
          trigger="click"
          :key="dropDownNum"
          v-if="options.length && options.length != 1"
        >
          <span class="el-dropdown-link">
            <span>{{ schoolName }}</span>
            <el-icon class="el-icon--right">
              <ArrowDown />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in options"
                :key="item.schoolId"
                :command="item.schoolId"
                >{{ item.schoolName }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span class="el-dropdown-link" v-else>
          <span>{{ schoolName }}</span>
        </span>
      </div>
      <!-- 修改密码,退出 -->
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <span>{{ user.userName }}</span>
          <el-icon class="el-icon--right">
            <ArrowDown />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="pwdDialog = true"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item @click="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>

  <!--修改密码-->
  <el-dialog
    title="修改密码"
    v-model="pwdDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="500px"
  >
    <edit-form
      ref="editForm"
      :form-items="fromItems"
      :form-config="{
        labelPosition: 'top',
      }"
      :data="editForm"
    >
    </edit-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="pwdDialog = false">取消</el-button>
        <el-button type="primary" @click="changePwd">确认 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
let timer = null;
import md5 from "md5";
import { getSchoolInfoApi } from "@/api/common.js";
import Storage from "@/commons/utils/storage.js";
import domain from "@/env-config/index";
import {
  searchMenu,
  getSearchHistory,
  deleteSearchHistory,
  addMenuSearchHistory,
} from "@/api/searchMenu.js";

export default {
  data() {
    const checkNewPsw = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("新密码不能为空"));
      } else if (
        !/^(?![\d]+$)(?![a-z]+$)(?![A-Z]+$)(?![~!@#$%^&*.]+$)[\da-zA-z~!@#$%^&*.]{6,12}$/.test(
          value
        )
      ) {
        callback(
          new Error("请输入6-12位字母、数字、特殊符号且包含两种及以上!")
        );
      } else {
        callback();
      }
    };
    const checkPsw = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("确认密码不能为空"));
      } else if (value != this.editForm.password) {
        callback(new Error("确认密码与新密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      isLoading: false,
      isShowEmptyImg: false, // 判断是否显示占位图
      loadingStatus: [false, false, false],
      dropDownNum: 0,
      editForm: {},
      options: [],
      oldLabel: "",
      schoolName: "",
      fromItems: [
        {
          label: "旧密码",
          prop: "oldPassword",
          component: "input",
          props: {
            placeholder: "请输入旧密码",
            showPassword: true,
          },
          rules: [
            { required: true, message: "旧密码不能为空", trigger: "blur" },
          ],
        },
        {
          label: "新密码(请输入6-12位字母、数字、特殊符号且包含两种及以上!)",
          prop: "password",
          component: "input",
          props: {
            placeholder: "请输入新密码",
            showPassword: true,
          },
          rules: [
            { required: true, message: "新密码不能为空", trigger: "blur" },
            { required: true, message: "新密码不能为空", trigger: "change" },
            { validator: checkNewPsw, trigger: "blur" },
            { validator: checkNewPsw, trigger: "change" },
          ],
        },
        {
          label: "确认密码",
          prop: "repeatPassword",
          component: "input",
          props: {
            placeholder: "请输入确认密码",
            showPassword: true,
          },
          rules: [
            { required: true, message: "确认密码不能为空", trigger: "blur" },
            { required: true, message: "确认密码不能为空", trigger: "change" },
            { validator: checkPsw, trigger: "blur" },
            { validator: checkPsw, trigger: "change" },
          ],
        },
      ],
      copeId: "",
      imgPath: process.env.VUE_APP_FILE_URL,
      user: {},
      pwdDialog: false,
      menuName: "",
      isSearch: false,
      isHistory: false,
      inputHistory: [],
      searchResult: [],
      cancelTokenSource: null, // 添加取消令牌源
      isShow: false,
      isCollapse: false,
      externaljumpList: ["自选课管理", "德育维度", "农业平台"],
      searchDialogShow: false, // 搜索弹窗显隐
      currentSearchType: 1, // 1:全部,2学生 3功能
      isSearchStPortrait: false, // 是否有学生画像权限
      stPortraitPageNum: 1, // 学生画像分页
      stPortraitLoading: false,
      studentPortraitList: [], // 学生画像数据
      studentPortraitTotal: 0, // 学生画像数据总数
      authList: [], // 有学生数据的功能查询权限
      menuTree: [], // 总树形
      searchMenuTree: [], // 筛选后的功能树形
      searchMenuTreeTotal: 0, // 功能所有三级总数
      searchHistory: [], // 搜索历史记录
      isDeleteHistory: false, //
      studentFunctionList: [], // 学生功能列表
      studentFuncIsData: true, // 判断学生功能列表是否还有分页数据
      studentFuncLoading: false,
      studentFuncLastIndex: 0,
    };
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        if (val.path == "/base/main/desk/desk") {
          this.isCollapse = false;
        } else {
          this.isCollapse = true;
        }
      },
      deep: true, // 深度观察监听
    },
    menuName(n, o) {
      if (!n) {
        this.getSearchHistory();
      }
    },
  },
  methods: {
    // 切换搜索类型
    changeSearchTab(type) {
      this.currentSearchType = type;
      this.isShowEmptyImg = false;
      this.isLoading = false;
      this.clearParams();
      this.inputSearch(this.menuName);
    },

    clearInput2() {
      this.clearInput();
    },

    // 输入框输入时
    inputSearch(value) {
      this.menuName = value.replace(/\s+/g, "");
      this.isShowEmptyImg = false;
      this.isLoading = false;
      this.studentFuncLastIndex = 0;
      if (this.timer !== null) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.menuName) this.searchAPI();
        else this.clearParams();
      }, 500);
    },

    clearInput() {
      this.menuName = "";
      this.isShowEmptyImg = false;
      this.isLoading = false;
      this.studentFuncLastIndex = 0;
      this.clearParams();
    },

    searchAPI() {
      if (this.currentSearchType != 3) {
        this.getStudentPortrait();
        this.getStudentFunction();
      }
      if (this.currentSearchType != 2) {
        this.getMenuFunc();
      }
    },

    // 清空参数
    clearParams() {
      // 学生画像相关
      this.stPortraitPageNum = 1; // 学生画像分页
      this.stPortraitLoading = false; // 学生画像加载状态
      this.studentPortraitList = []; // 学生画像数据
      this.studentPortraitTotal = 0; // 学生画像数据总数

      // 学生功能相关
      this.studentFunctionList = []; // 学生功能列表
      this.studentFuncIsData = true; // 判断学生功能列表是否还有分页数据
      this.studentFuncLoading = false; // 学生功能加载状态
      this.studentFuncLastIndex = 0;

      // 功能菜单相关
      this.searchMenuTree = [];
      this.searchMenuTreeTotal = 0;

      this.isLoading = true;
    },

    // 搜索学生画像
    getStudentPortrait() {
      if (!this.isSearchStPortrait) return;
      this.$axios({
        method: "post",
        url: "/system/func/app/getStudentImageList",
        data: {
          stuName: this.menuName, //学生姓名、别名等
          pageNum: this.stPortraitPageNum,
          pageSize: 2,
        },
      }).then((res) => {
        this.stPortraitLoading = false;
        this.isLoading = true;
        if (res.code == 200) {
          if (this.stPortraitPageNum > 1) {
            if (res.data && res.data.length) {
              this.studentPortraitList.push(...res.data);
            } else {
              this.stPortraitPageNum--;
            }
          } else this.studentPortraitList = res.data;
          this.studentPortraitTotal = res.total;
        } else this.$message.error(res.describe);
        this.updateEmpty();
      });
    },

    // 查看更多--学生画像
    getMoreStPicList() {
      this.stPortraitLoading = true;
      this.stPortraitPageNum++;
      this.getStudentPortrait();
    },

    // 搜索学生相关
    getStudentFunction() {
      this.studentFuncLoading = true;
      let data = {
        list: this.authList,
        stuName: this.menuName,
        lastIndex: this.studentFuncLastIndex,
      };
      this.$axios({
        method: "post",
        url: "/system/func/app/getIncludeStudentMenuList",
        data: data,
      }).then((res) => {
        this.studentFuncLoading = false;
        this.isLoading = true;
        if (res.code == 200) {
          if (res.data?.length) {
            res.data.forEach((item) => {
              item.menuName = `${item.firstMenuName}/${item.secondMenuName}/${item.thirdMenuName}`;
              if (item.displayNameList.length == 3) {
                item.stNameStr = `${item.displayNameList.join(" ")}等...`;
              } else {
                item.stNameStr = item.displayNameList.join(" ");
              }
            });
            this.studentFuncLastIndex = res.data[res.data.length - 1].curIndex;
          } else this.studentFuncIsData = false;
          if (this.studentFuncLastIndex) {
            this.studentFunctionList = [
              ...this.studentFunctionList,
              ...res.data,
            ];
          } else {
            this.studentFunctionList = res.data;
          }
          this.updateEmpty();
        } else this.$message.error(res.describe);
      });
    },

    // type:add del clear
    setSearchHistory(str, type, index) {
      let hist = localStorage.getItem("homeSearchHistory");
      let list = hist ? JSON.parse(hist) : [];
      if (type == "add" || type == "del") {
        if (type == "add") {
          let ind = list.findIndex((el) => el == str);
          if (ind != -1) {
            list.splice(ind, 1);
          }
          list.unshift(str);
          list = list.splice(0, 10);
        } else {
          list.splice(index, 1);
        }
        localStorage.setItem("homeSearchHistory", JSON.stringify(list));
      } else {
        this.$confirm("确认删除全部历史记录吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showClose: false, //是否显示关闭按钮
          closeOnClickModal: false, //是否可以通过点击空白处关闭弹
        })
          .then(() => {
            list = [];
            localStorage.removeItem("homeSearchHistory");
            this.isDeleteHistory = false;
            this.getSearchHistory();
          })
          .catch(() => {});
      }
      this.getSearchHistory();
    },

    // 跳转页面
    jumpPage(row, type) {
      let data = {
        funcName: row.name || row.funName,
        funcId: row.id || row.funId,
        funcCode: row.funCode,
        funcShowName: null,
        parentFuncId: row.pId,
        funcUrl: row.link || row.funUrl,
        funcType: row.funcType,
        funcIcon: null,
        operationTime: null,
      };
      this.setSearchHistory(JSON.parse(JSON.stringify(this.menuName)), "add");

      // 跳转学生画像
      if (type == 1) {
        let data = {
          gradeId: row.gradeId,
          classId: row.classId,
          name: row.studentName,
        };
        sessionStorage.setItem("studenInfo_hx", JSON.stringify(data));
        this.$router.push({
          name: "/studentPortrait/index",
          query: {
            SID: row.studentId,
          },
        });
        this.handleClose();
      }
      if (type != 1) this.changeMneu(data, type);
    },

    // 判断缺省图是否需要显示
    updateEmpty() {
      let bool = false;
      if (this.currentSearchType == 1) {
        if (
          !this.studentPortraitTotal &&
          !this.studentFunctionList.length &&
          !this.searchMenuTreeTotal
        ) {
          bool = true;
        }
      }
      if (this.currentSearchType == 2) {
        if (!this.studentPortraitTotal && !this.studentFunctionList.length) {
          bool = true;
        }
      }
      if (this.currentSearchType == 3) {
        if (!this.searchMenuTreeTotal) bool = true;
      }
      this.isShowEmptyImg = bool;
    },

    // 搜索功能
    getMenuFunc() {
      let result = this.filterTreeNode(this.menuTree, this.menuName);
      if (result.length) {
        let allTotal = 0;
        result.forEach((item) => {
          let total = 0;
          item.children.forEach((Item) => {
            if (Item.children && Item.children.length) {
              total += Item.children.length;
            }
          });
          item.childrenTotal = total;
          allTotal += total;
        });
        this.searchMenuTree = result;
        this.searchMenuTreeTotal = allTotal;
      } else {
        this.searchMenuTree = [];
        this.searchMenuTreeTotal = 0;
      }
      this.isLoading = true;
      this.updateEmpty();
    },

    filterTreeNode(data, keyword) {
      function containsKeyword(node) {
        return node.name.includes(keyword);
      }
      function filterNode(node) {
        if (containsKeyword(node)) {
          return node;
        }
        const filteredChildren = [];
        if (node.children && node.children.length > 0) {
          for (const child of node.children) {
            const filteredChild = filterNode(child);
            if (filteredChild) {
              filteredChildren.push(filteredChild);
            }
          }
        }
        if (filteredChildren.length > 0) {
          return {
            ...node, // Include all properties of the node
            children: filteredChildren,
          };
        }
        return null;
      }
      return data
        .map((item) => filterNode(item))
        .filter((item) => item !== null);
    },

    focusInput2() {
      this.getSearchHistory();
      this.searchDialogShow = true;
      if (this.authList.length) return;
      let locaMenu = Storage.get("menu");
      this.menuTree = Storage.formatTreeData(
        locaMenu,
        "0",
        this.USERROLE
      ).children;
      this.menuTree.splice(0, 1);
      let authList = require("./auth.json");
      let list = JSON.parse(JSON.stringify(authList));
      let menu = locaMenu.filter((el) => el.funType == "menu");
      menu.forEach((item) => {
        if (item.link == "/moralEducationPortrait/school") {
          //  判断是否查询学生画像
          this.isSearchStPortrait = true;
        }
        let ind = list.findIndex((el) => el.link == item.link);
        if (ind != -1) {
          list[ind].funcId = item.id;
          list[ind].funcDesc = list[ind].funcCode;
          delete list[ind].funcCode;
          delete list[ind].link;
        }
      });
      this.authList = list;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.searchInputRef.focus();
        }, 0);
      });
    },

    // 关闭搜索弹窗
    handleClose() {
      this.searchDialogShow = false;
      this.menuName = "";
      this.currentSearchType = 1;
      this.stPortraitPageNum = 1; // 学生画像分页
      this.studentPortraitList = []; // 学生画像数据
      this.studentPortraitTotal = 0;
      this.searchMenuTree = [];
      this.searchMenuTreeTotal = 0; // 学生画像数据总数
      this.searchHistory = []; // 搜索历史记录
      this.isDeleteHistory = false;
      this.studentFunctionList = [];
    },

    handleClose2() {
      this.searchDialogShow = false;
    },

    getSearchHistory() {
      let list = localStorage.getItem("homeSearchHistory");
      this.searchHistory = list ? JSON.parse(list) : [];
    },

    updateCollapse(e) {
      this.isCollapse = e;
    },

    async changeMneu(row, jumpType) {
      let api = async (type) => {
        let res = await addMenuSearchHistory(row);
        if (res.code == 200) {
          if (type) {
            if (jumpType == 2) {
              this.$router.push({
                path: row.funcUrl,
                query: {
                  searchJump: true,
                  jumpValue: this.menuName,
                },
              });
            } else {
              this.$router.push(row.funcUrl);
            }
          }
          this.handleClose();
        } else this.$message.error(res.describe);
      };
      if (row.funcName == "自选课管理" || row.funcName == "德育维度") {
        this.goMyProjects(row);
        api(false);
      } else if (row.funcName == "农业平台") {
        this.goNongYe(row);
        api(false);
      } else api(true);
    },

    // 跳转农业平台
    goNongYe() {
      let orgId = localStorage.getItem("schoolId") || null;
      if (orgId == "2208010064") {
        // 测试实验小学
        window.open(
          "http://121.40.125.107:30575/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else if (orgId == "2209140066") {
        // 正式毓蒙
        window.open(
          "http://agriculture.prod.camp.huaching.com/dovey-environment/login/input"
        );
      } else if (orgId == "2407090079") {
        // 正式马鞍山实验小学
        window.open(
          "http://121.40.125.107:31138/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else if (orgId == "2411110082") {
        // 文成县珊溪镇中心小学
        window.open(
          "http://121.40.125.107:31011/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else {
        // 其他
        window.open(
          "http://agriculture.prod.camp.huaching.com/dovey-environment/login/input"
        );
      }
    },

    getProxyLink() {
      let type = process.env.VUE_APP_ENV;
      let url = "";
      switch (type) {
        case "test":
          // 测试
          url =
            "https://nginx.file.campus.project.kube.huaching.com/proxy/campus-web-my/";
          break;
        // 演示
        case "pre":
          url = "https://campus.huaching.com/campus-web-my/";
          break;
        // 正式
        case "prod":
          url = "https://campus.huaching.com/campus-web-my/";
          break;

        default:
          break;
      }
      return url;
    },

    // 跳转马屿小学项目
    goMyProjects(item) {
      let SID = this.user.token;
      let mobile = this.user.mobile;
      let type, url;
      url = this.getProxyLink();
      // url = "http://localhost/campus-web-my/";
      if (item.name == "自选课管理") type = "view_course";
      if (item.name == "德育维度") type = "view_deyu_evaluation";
      let queryString = "?SID=" + SID + "&mobile=" + mobile + "&type=" + type;
      window.open(url + queryString, "_blank");
    },

    highlightMatch(text) {
      if (!this.menuName) return text; // 如果没有搜索词，返回原文本
      const regex = new RegExp(`(${this.menuName})`, "gi"); // 创建正则表达式
      return text.replace(regex, '<span style="color:#09B189;">$1</span>'); // 高亮匹配部分
    },

    changeDropdownItem(id) {
      this.$confirm("确定要切换学校吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.clearRoutes();
          localStorage.setItem("schoolId", id);
          this.$store.state.copeId = id;
          this.copeId = id;
          this.$axios({
            method: "get",
            url: "/system/user/updateUserSchoolId?schoolId=" + id,
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success("切换成功");
              this.getCurrentSchoolInfo(id);
              this.branchSchoolMenu();
              this.queryUserInfo();
              this.$router.replace({
                path: "/base/main/desk/desk",
              });
            } else {
              if (res.code == 37018) {
                this.$router.replace({
                  path: "/applyError",
                  query: {
                    id,
                  },
                });
              } else this.$message.error(res.describe);
            }
          });
        })
        .catch((err) => (this.copeId = this.olval));
    },

    // 切换学校
    branchSchoolMenu() {
      this.$parent.resetMenu("update");
      this.$parent.setCrumbs();
      sessionStorage.removeItem("currentTabName");
    },

    async changePwd() {
      const valid = await this.$refs.editForm.validate();
      if (valid) {
        var this_ = this;
        this.http
          .ajaxHandler()
          .success((code, msg) => {
            if (code == 200) {
              this_.pwdDialog = false;
              this_.$message({
                type: "success",
                message: "密码修改成功!",
              });
              this_.$router.push("/");
            } else {
              this_.$message({
                message: msg,
                type: "warning",
              });
            }
          })
          .error(function (code, msg) {
            this_.$message.error(msg);
          })
          .post({
            url: "/system/user/updPsd",
            data: {
              password: md5(this.editForm.password).toUpperCase(),
              oldPassword: md5(this.editForm.oldPassword).toUpperCase(),
            },
          });
      }
    },

    logout() {
      Storage.remove("menu");
      Storage.remove("user");
      Storage.remove("isMenu");
      this.http
        .ajaxHandler()
        .success((code, msg) => {
          if (code == 200) {
            this.$message({
              type: "success",
              message: "退出成功!",
            });
            localStorage.clear();
            sessionStorage.clear();
            this.$router.push("/");
          } else {
            this.$message({
              message: msg,
              type: "warning",
            });
          }
        })
        .error((code, msg) => {
          this.$message.error(msg);
        })
        .post({ url: "/system/user/logout" });
    },

    // 获取当前选择学校的信息
    getCurrentSchoolInfo(id) {
      if (!id) return false;
      if (this.options && this.options.length) {
        let index = this.options.findIndex((item) => item.schoolId == id);
        if (index != -1) {
          this.schoolName = this.options[index].schoolName;
          this.options.splice(0, 0);
        } else this.schoolName = "--";
        this.dropDownNum++;
      } else {
        setTimeout(() => {
          this.getCurrentSchoolInfo(this.copeId);
        }, 600);
      }
    },

    // 跳转到老系统
    goOldSystem() {
      let url = `${domain.oldSystemUrl}${
        this.oldLabel == "ym"
          ? "/index.html?"
          : "-" + this.oldLabel + "/index.html?"
      }sid=${this.user.token}&mobile=${this.user.mobile}&oldLabel=${
        this.oldLabel
      }`;
      window.open(url, "_blank");
    },

    queryUserInfo(bool) {
      this.$axios({
        method: "post",
        url: "/system/user/getCurUser",
      }).then((res) => {
        if (res.code == 200) {
          this.user = res.result;
          localStorage.setItem("schoolId", res.result.schoolId);
          localStorage.setItem(
            "userDingtalkList",
            JSON.stringify(res.result.userDingtalkList)
          );
          Storage.set(
            "user",
            encodeURIComponent(encodeURIComponent(JSON.stringify(res.result)))
          );
          this.copeId = res.result.schoolId;
          this.$store.state.copeId = res.result.schoolId;
          this.options = res.result.userDingtalkList;
          if (bool) {
            this.getCurrentSchoolInfo(this.copeId);
            this.getSchoolInfo(this.copeId);
            this.$parent.resetMenu();
            this.$parent.setCrumbs();
          }
        } else this.$message.error(res.describe);
      });
    },

    // 获取学校信息是否有老系统的接口
    async getSchoolInfo(id) {
      const res = await getSchoolInfoApi(id);
      if (res.code === 200) {
        this.oldLabel = res.result.oldLabel;
      } else {
        this.$message({
          message: res.describe,
          type: "warning",
        });
      }
    },
  },

  created() {
    this.queryUserInfo(true);
  },

  mounted() {
    this.getSearchHistory();
  },

  beforeDestroy() {},
};
</script>
<style scoped lang="less">
.header_cont {
  user-select: none;
  flex-shrink: 0;
  background-color: #fff !important;
  width: 100%;
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  color: #333;
  padding: 0 22px;
  box-sizing: border-box;
  .header_l {
    color: #8c9196;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .header_r {
    display: flex;
    align-items: center;
  }
  .return-old-box {
    display: flex;
    align-items: center;
    .line {
      width: 1px;
      height: 12px;
      flex-shrink: 0;
      border-radius: 100px;
      background: #259591;
      margin: 0 20px;
    }
  }
  .jump-old-btn {
    color: #259591;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    cursor: pointer;
    transition: all 0.45s;
    &:hover {
      color: #09b189;
    }
  }
}
.search-menu-inp {
  width: 320px;
  height: 32px;
  margin-right: 16px;
  position: relative;
  ::v-deep .el-input {
    .el-input__wrapper {
      border-radius: 100px !important;
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
    }
    .el-input__inner {
      line-height: 32px;
      height: 32px;
    }
  }
}

// 搜索弹窗样式
::v-deep .my-dialog {
  width: 720px;
  height: 96vh;
  overflow: hidden;
  .el-dialog__header,
  .el-message-box__header {
    box-shadow: none !important;
  }
  .my-header {
    display: flex;
    align-items: center;
    .el-input {
      height: 40px;
      .el-input__wrapper {
        background-color: #fff;
      }
    }
    .closebtn {
      cursor: pointer;
      margin-left: 16px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  .my-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    .tabs {
      height: 32px;
      padding: 0 16px;
      box-sizing: border-box;
      display: flex;
      .item {
        position: relative;
        color: #333;
        font-family: "PingFang SC";
        font-size: 14px;
        font-weight: 400;
        margin-right: 32px;
        cursor: pointer;
        .line {
          position: absolute;
          bottom: 0;
          width: 32px;
          height: 4px;
          border-radius: 4px 4px 0px 0px;
          background: #09b189;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .active_item {
        color: #09b189;
      }
    }
    .cont {
      flex: 1;
      background-color: #f8f8f8;
      overflow-y: auto;
      padding: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .cont-info {
        margin-bottom: 16px;
        .title {
          color: #333;
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
          margin-bottom: 8px;
        }
        .list {
          border-radius: 8px;
          background: #fff;
          padding: 8px;
          box-sizing: border-box;
          ul {
            li {
              height: 64px;
              display: flex;
              align-items: center;
              padding: 16px 8px;
              box-sizing: border-box;
              transition: all 0.2s;
              border-radius: 8px;
              &:hover {
                background: #f6f6f6;
                cursor: pointer;
              }
              .left {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
                background-image: url("../../assets/images/student-pro-icon-bgc.svg");
                background-size: 100% 100%;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
              .right {
                display: flex;
                color: #333;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
              }
            }
          }
          .more {
            height: 40px;
            display: flex;
            align-items: center;
            border-radius: 4px;
            justify-content: center;
            transition: all 0.2s;
            box-sizing: border-box;
            p {
              color: #6d7175;
              text-align: center;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
            img {
              width: 18px;
              height: 18px;
            }
          }
          .more2 {
            cursor: pointer;
            &:hover {
              background: #f6f6f6;
            }
          }
        }
      }
      .menu-tree-cont {
        margin-bottom: 0;
        .list {
          padding: 16px;
          box-sizing: border-box;
          .tree-item {
            margin-bottom: 24px;
            .list-item-title {
              margin-bottom: 16px;
              height: 20px;
              display: flex;
              align-items: center;
              color: #333;
              font-family: "PingFang SC";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
              }
            }
            .list-item-children {
              display: flex;
              color: #333;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              .children-name {
                width: calc((100% - 24px - 24px - 24px) / 4);
                margin-right: 24px;
                height: 100%;
                color: #333;
                font-family: "PingFang SC";
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                display: flex;
                align-items: flex-start;
                padding: 8px;
                box-sizing: border-box;
              }
              .children-item {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                p {
                  margin-right: 24px;
                  width: calc((100% - 48px) / 3);
                  height: 32px;
                  text-align: left;
                  color: #333;
                  font-family: "PingFang SC";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  margin-bottom: 8px;
                  cursor: pointer;
                  padding: 8px;
                  box-sizing: border-box;
                  transition: all 0.2s;
                }
                p:nth-child(3n) {
                  margin-right: 0;
                }
                p:hover {
                  border-radius: 8px;
                  background: #f6f6f6;
                }
              }
            }
            .list-item-children:last-child {
              .children-item {
                p {
                  margin-bottom: 0 !important;
                }
              }
            }
          }
          .tree-item:last-child {
            margin-bottom: 0;
          }
        }
      }
      .search-cont {
        .title {
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #6d7175;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 16px;
          img {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
          > div {
            display: flex;
            align-items: center;
            .p1 {
              cursor: pointer;
              color: #6d7175;
              text-align: right;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              &:hover {
                color: #333;
              }
            }
            .p2 {
              cursor: pointer;
              color: #09b189;
              text-align: right;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
            }
            .line {
              width: 1px;
              height: 10px;
              background: #9698a1;
              margin: 0 8px;
            }
          }
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          .item {
            height: 24px;
            cursor: pointer;
            margin-right: 12px;
            margin-bottom: 12px;
            padding: 0 12px;
            border-radius: 12px;
            border: 1px solid rgba(222, 222, 222, 0);
            background-color: #f1f1f1;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            p {
              margin: 0;
              font-size: 12px;
              font-style: normal;
              line-height: 1.5;
              color: #6d7175;
              font-family: "PingFang SC";
              font-weight: 400;
            }
            &:hover {
              background-color: #eaeaea;
              color: #333;
            }
            img {
              cursor: pointer;
              width: 16px;
              height: 16px;
              margin-left: 4px;
            }
          }
        }
      }

      .empty-img {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 58px;
          height: 64px;
        }
        .msg {
          color: #333;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin-top: 16px;
        }
        .msg2 {
          color: #8c9196;
          text-align: center;
          margin-top: 8px;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .st-func-box {
        .list {
          ul {
            li {
              .right {
                display: flex;
                flex-direction: column;
                .p1 {
                  color: #333;
                  font-family: "PingFang SC";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px; /* 142.857% */
                  margin-bottom: 6px;
                }
                .p2 {
                  color: #6d7175;
                  font-family: "PingFang SC";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px; /* 133.333% */
                }
              }
            }
          }
        }
      }
    }
  }
}
.input-suffix-text {
  padding: 2px 4px;
  border-radius: 4px;
  text-align: right;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  color: #8c9196;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  &:hover {
    background: #f6f6f6;
    color: #4e5969;
  }
}

:deep(.dailog-model) {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  .img {
    width: 32px !important;
    height: 32px !important;
  }
}
</style>
