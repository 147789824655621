<!--
 * @Author:
 * @Date: 2024-03-07 17:50:48
 * @LastEditors: yuan wei lin
 * @LastEditTime: 2024-08-19 16:14:19
 * @Description:
-->
<template>
  <div :class="['menu_cont', isCollapse ? 'open-menu-cont' : '']">
    <div class="top_icon">
      <img src="../../assets/img/HouduBrandmark.svg" />
      <p v-show="isCollapse">数智校园</p>
    </div>

    <div class="el_menu_cont" v-if="isCollapse">
      <div class="primary_menu" v-show="pay_menu.length">
        <Openmenu
          ref="openMenuRefUp"
          @clearMenuOpen="clearMenuOpen('up')"
          :menu="pay_menu"
        ></Openmenu>
      </div>
      <!-- <div class="other_menu" v-show="oth_menu.length">
        <Openmenu
          ref="openMenuRefDown"
          @clearMenuOpen="clearMenuOpen('down')"
          :menu="oth_menu"
        ></Openmenu>
      </div> -->
    </div>

    <div class="el_menu_cont" v-else>
      <div class="primary_menu" v-show="pay_menu.length">
        <Closemenu :menu="pay_menu"></Closemenu>
      </div>
      <!-- <div class="other_menu" v-show="oth_menu.length">
        <Closemenu :menu="oth_menu"></Closemenu>
      </div> -->
    </div>

    <div class="collapse_icon">
      <div class="collapse_icon_inner" @click="hideCollapse">
        <div class="img_box">
          <img
            class="offCollapse"
            v-if="isCollapse"
            src="../../assets/img/campus_off_major.svg"
          />
          <img
            class="openCollapse"
            v-else
            src="../../assets/img/campus_open_major.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Openmenu from "./openMenu.vue";
import Closemenu from "./closeMenu.vue";
import Storage from "@/commons/utils/storage.js";
export default {
  data() {
    return {
      openeds: [],
      user: {},
      imgPath: process.env.VUE_APP_FILE_URL,
      isCollapse: true, // 是否展开
      menuListAll: [],
      pay_menu: [],
      oth_menu: [],
      leftIconList: [],
      currentMenuParentId: "",
      currentMenuParentIconId: "",
      externaljumpList: ["自选课管理", "德育维度", "农业平台"],
    };
  },

  methods: {
    clearMenuOpen(type) {
      let key = "";
      if (type == "up") key = "openMenuRefDown";
      else key = "openMenuRefUp";
      // this.$refs[key].clearActiveType();
    },

    hideCollapse() {
      this.isCollapse = !this.isCollapse;
      // this.$emit("isCollapse", this.isCollapse);
    },

    filterMenu() {
      let pay_menu = [];
      let oth_menu = [];
      // this.menuListAll.forEach((item) => {
      //   if (
      //     item.funCode == "0006" ||
      //     item.funCode == "0016" ||
      //     item.funCode == "0030"
      //   ) {
      //     oth_menu.push(item);
      //   } else pay_menu.push(item);
      // });
      this.pay_menu = this.menuListAll;
      this.oth_menu = oth_menu;
    },

    getMenu(id, bool) {
      let userInfo = Storage.get("user");
      if (userInfo) {
        this.user = JSON.parse(
          decodeURIComponent(decodeURIComponent(userInfo))
        );
      }
      this.menuList = [];
      this.menuListAll = [];
      let locaMenu = Storage.get("menu");
      if (locaMenu && !bool) {
        this.menuListAll = Storage.formatTreeData(
          locaMenu,
          "0",
          this.USERROLE
        ).children;
        this.filterMenu();
      } else {
        this.$axios({
          url: "/system/user/getFunc?schoolId=" + id,
          method: "get",
        }).then((res) => {
          let list = [
            {
              params: {},
              page: 1,
              size: 15,
              id: "24031217341000000623",
              text: null,
              name: "首页",
              pId: "0",
              meta: {
                id: "1111",
              },
              link: "/base/main/desk/desk",
              funImg: "/menuIco/Home_minor-Default3.svg",
              funClickImg: "/menuIco/Home_minor-Click3.svg",
              funType: "menu",
              orderBy: 0,
              funCode: "1111",
            },
          ];
          if (res.code == 200) {
            if (res.result != null) {
              res.result.push(...list);
              Storage.set("menu", res.result);
              let dataMenu = Storage.formatTreeData(
                res.result,
                "0",
                this.USERROLE
              ).children;
              this.$router.options.routes.forEach((router) => {
                if (router.path == "/index") {
                  let tmp = [];
                  Storage.set("isMenu", "yes");
                  Storage.getDataRouter(dataMenu, tmp);
                  tmp = router.children.concat(tmp);
                  router.children = tmp;
                  this.$router.addRoute(router);
                }
              });
              if (bool) {
                this.menuListAll = [];
                this.menuList = [];
                this.$router.replace({
                  path: "/base/main/desk/desk",
                });
              }
              this.menuListAll = dataMenu;
              this.filterMenu();
            }
          }
        });
      }
    },
  },

  mounted() {},

  components: {
    Openmenu,
    Closemenu,
  },
};
</script>
<style scoped lang="less">
.open-menu-cont {
  width: 240px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: #dedede !important;
}

// ::-webkit-scrollbar {
//   width: 6px;
//   background-color: rgba(0, 0, 0, 0.1);
// }

// ::-webkit-scrollbar-track-piece {
//   background-color: #fff;
// }

// ::-webkit-scrollbar:horizontal {
//   height: 10px;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: rgb(204, 208, 216);
//   border-radius: 4px;
//   box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
// }

.menu_cont {
  overflow-x: hidden;
  width: 64px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
  padding: 8px 0 0 0;
  user-select: none !important;
  transition: all 0.25s;
  .top_icon {
    flex-shrink: 0;
    width: 100%;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    box-sizing: border-box;
    color: #202020;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    img {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }

  .el_menu_cont {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 4px;
    box-sizing: border-box;
  }

  .collapse_icon {
    border-top: 1px solid #d8d8d8;
    padding-top: 8px;
    margin-left: 4px;
    width: calc(100% - 8px);
    height: 64px;
    box-sizing: border-box;
    transition: all 0.2s;
    display: flex;
    justify-content: flex-end;
    .collapse_icon_inner {
      width: 56px;
      height: 48px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      .img_box {
        width: 32px;
        height: 32px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f2f3;
        img {
          width: 16px;
          height: 16px;
        }
      }
      &:hover {
        background-color: #f6f6f7;
      }
      &:active {
        background-color: #f1f2f3;
        .img_box {
          background-color: #f1f2f3;
        }
      }
    }
  }
}
</style>
