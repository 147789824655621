<template>
  <div>
    <!-- 为 ECharts 准备一个定义了宽高的 DOM -->
    <div ref="eCharts" :style="style"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  name: "MyCharts",
  props: {
    options: {
      type: Object,
    },
    canvasWidth: {
      type: String,
      default: "200px",
      require: true,
    },
    canvasHeight: {
      type: String,
      default: "200px",
      require: true,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    style() {
      return {
        width: this.canvasWidth,
        height: this.canvasHeight,
      };
    },
  },
  mounted() {
    // eCharts在初始化的时候有时捕捉不到元素高度，这里手动设置一下
    this.$refs.eCharts.style.height = this.height;
    this.$nextTick(() => {
      if (this.type == "custom") {
        this.drawShape();
      } else {
        this.initChart(this.options);
      }
    });
  },
  beforeDestroy() {
    // 组件销毁时主动释放eCharts内存空间
    const chartsInstance = echart.getInstanceByDom(this.$refs.eCharts);
    chartsInstance && chartsInstance.dispose();
    window.onresize = null;
  },
  watch: {
    // options() {
    //   const chartsInstance = echarts.getInstanceByDom(this.$refs.eCharts);
    //   chartsInstance && chartsInstance.setOption(this.options);
    // },
    options: {
      handler: function (newValue, oldValue) {
        const chartsInstance = echarts.getInstanceByDom(this.$refs.eCharts);
        console.log(this.options);
        if (this.options == null) {
          chartsInstance.clear();
          return;
        }
        chartsInstance.clear();
        chartsInstance && chartsInstance.setOption(this.options);
      },
      // 开启深度观察
      deep: true,
    },
  },
  methods: {
    initChart() {
      if (!this.$refs.eCharts) {
        // 确保执行初始化时Container存在
        return;
      }
      const chartInstace = echarts.init(this.$refs.eCharts);
      chartInstace.setOption(this.options);
      // 视口大小变化时调用resize调整eCharts大小，如果Container尺寸并非自适应则无需设置
      window.addEventListener("resize", () => {
        //监听浏览器窗口大小改变
        //浏览器变化执行动作
        chartInstace.resize();
      });
    },
    drawShape() {
      const leftShape = echarts.graphic.extendShape({
        buildPath(ctx, shape) {
          const { topBasicsYAxis, bottomYAxis, basicsXAxis } = shape;
          // 侧面宽度
          const WIDTH = 15;
          // 斜角高度
          const OBLIQUE_ANGLE_HEIGHT = 3.6;

          const p1 = [
            basicsXAxis - WIDTH,
            topBasicsYAxis - OBLIQUE_ANGLE_HEIGHT,
          ];
          const p2 = [basicsXAxis - WIDTH, bottomYAxis];
          const p3 = [basicsXAxis, bottomYAxis];
          const p4 = [basicsXAxis, topBasicsYAxis];

          ctx.moveTo(p1[0], p1[1]);
          ctx.lineTo(p2[0], p2[1]);
          ctx.lineTo(p3[0], p3[1]);
          ctx.lineTo(p4[0], p4[1]);
        },
      });

      const rightShape = echarts.graphic.extendShape({
        buildPath(ctx, shape) {
          const { topBasicsYAxis, bottomYAxis, basicsXAxis } = shape;
          // 侧面宽度
          const WIDTH = 15;
          // 斜角高度
          const OBLIQUE_ANGLE_HEIGHT = 3.6;

          const p1 = [basicsXAxis, topBasicsYAxis];
          const p2 = [basicsXAxis, bottomYAxis];
          const p3 = [basicsXAxis + WIDTH, bottomYAxis];
          const p4 = [
            basicsXAxis + WIDTH,
            topBasicsYAxis - OBLIQUE_ANGLE_HEIGHT,
          ];

          ctx.moveTo(p1[0], p1[1]);
          ctx.lineTo(p2[0], p2[1]);
          ctx.lineTo(p3[0], p3[1]);
          ctx.lineTo(p4[0], p4[1]);
        },
      });

      const topShape = echarts.graphic.extendShape({
        buildPath(ctx, shape) {
          const { topBasicsYAxis, basicsXAxis } = shape;
          // 侧面宽度
          const WIDTH = 15;
          // 斜角高度
          const OBLIQUE_ANGLE_HEIGHT = 3.6;

          const p1 = [basicsXAxis, topBasicsYAxis];
          const p2 = [
            basicsXAxis + WIDTH,
            topBasicsYAxis - OBLIQUE_ANGLE_HEIGHT,
          ];
          const p3 = [basicsXAxis, topBasicsYAxis - OBLIQUE_ANGLE_HEIGHT * 2];
          const p4 = [
            basicsXAxis - WIDTH,
            topBasicsYAxis - OBLIQUE_ANGLE_HEIGHT,
          ];

          ctx.moveTo(p1[0], p1[1]);
          ctx.lineTo(p2[0], p2[1]);
          ctx.lineTo(p3[0], p3[1]);
          ctx.lineTo(p4[0], p4[1]);
        },
      });

      echarts.graphic.registerShape("leftShape", leftShape);
      echarts.graphic.registerShape("rightShape", rightShape);
      echarts.graphic.registerShape("topShape", topShape);
      this.initChart(this.options);
    },
  },
};
</script>
