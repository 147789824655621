<template>
  <div class="logisticsWrap">
    <div class="content">
      <div class="xsqj">宿舍信息</div>
      <div class="sxSection">
        <div class="labele">近15日宿舍信息统计</div>
        <div class="leaveStatistics">
          <div
            v-for="(it, index) in dormitoryStatistics"
            :key="index"
            class="item"
          >
            <img :src="require('@/assets/images/campusData/' + it.img)" alt />
            <div class="info">
              <div class="qjamouts">{{ it.name }}</div>
              <div class="nums">
                {{ it.num }}
                <span>{{ index == 1 ? "个" : index == 2 ? "人" : "栋" }}</span>
              </div>
            </div>
          </div>
        </div>
        <img
          class="rline"
          src="@/assets/images/campusData/longLineIcon.png"
          alt
        />
        <div class="labele">近15日归寝趋势</div>
        <div class="trendSection">
          <div ref="trendL" class="trendL">
            <compontEcharts
              v-if="trendOption"
              :options="trendOption"
              :canvasWidth="trendOption.w"
              :canvasHeight="trendOption.h"
            ></compontEcharts>
          </div>
          <div class="trendR">
            <div class="centerimg"></div>
            <compontEcharts
              v-if="pieTrendOption"
              :options="pieTrendOption"
              canvasWidth="340px"
              canvasHeight="138px"
            ></compontEcharts>
          </div>
        </div>
        <img
          class="rline"
          src="@/assets/images/campusData/longLineIcon.png"
          alt
        />
        <div class="labele">近15日各时段归寝平均人数</div>
        <div ref="peopleAverageNumber" class="peopleAverageNumber">
          <compontEcharts
            v-if="peopleAverageNumberOption"
            :options="peopleAverageNumberOption"
            :canvasWidth="peopleAverageNumberOption.w"
            :canvasHeight="peopleAverageNumberOption.h"
          ></compontEcharts>
        </div>
      </div>
    </div>
    <div class="right left">
      <div class="titlebox">水电概况</div>
      <div v-if="regionEnergySumDataData" class="infosBox">
        <div class="leftTop">
          <div class="wlabel">本月用水量</div>
          <div class="amount">
            {{ regionEnergySumDataData.monthWaterTotal }}<span>m³</span>
          </div>
        </div>
        <div class="rightTop">
          <div class="wlabel">本年用水量</div>
          <div class="amount">
            {{ regionEnergySumDataData.yearWaterTotal }}<span>m³</span>
          </div>
        </div>
        <div class="leftbot">
          <div class="wlabel">本月用电量</div>
          <div class="amount">
            {{ regionEnergySumDataData.monthElectricityTotal }}<span>kwh</span>
          </div>
        </div>
        <div class="rightbot">
          <div class="wlabel">本年用电量</div>
          <div class="amount">
            {{ regionEnergySumDataData.yearElectricityTotal }}<span>kwh</span>
          </div>
        </div>
      </div>
      <div class="jrcq mrbot">用水趋势图</div>
      <compontEcharts
        v-if="waterTrendChartOption"
        :options="waterTrendChartOption"
        canvasWidth="508px"
        canvasHeight="252px"
      ></compontEcharts>
      <div class="jrcq mrbot">用电趋势图</div>
      <compontEcharts
        v-if="electricTrendOption"
        :options="electricTrendOption"
        canvasWidth="508px"
        canvasHeight="252px"
      ></compontEcharts>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import compontEcharts from "@/components/echarts.vue";
import { getPie3D } from "./index.ts";

import {
  regionDormitoryInfo,
  regionDormitoryGoBedStuNumTrend,
  regionDormitoryGoBedPercent,
  regionDormitoryTimePeriodGoBedStuNum,
  regionEnergySumData,
  waterPastDaysTrend,
  electricityPastDaysTrend,
} from "@/api/campusDataScreen";
export default {
  components: {
    compontEcharts,
  },
  data() {
    return {
      county: 330381,
      electricTrendOption: null, //用电趋势
      trendOption: null, //近15日归寝趋势
      pieTrendOption: null, //饼图15日归寝率
      peopleAverageNumberOption: null, //近15日各时归寝平级人数
      waterTrendChartOption: null, //用水趋势图
      dormitoryStatistics: [
        //请假统计
        {
          code: "dormitoryNum",
          name: "宿舍楼总数",
          num: "0",
          img: "sslzsIcon.png",
        },
        {
          code: "dormitoryRoomNum",
          name: "寝室总数",
          num: "0",
          img: "qszsIcon.png",
        },
        {
          code: "dormitoryStuNum",
          name: "入住人数",
          num: "0",
          img: "rzrsIcon.png",
        },
      ],
      regionEnergySumDataData: null,
    };
  },
  mounted() {
    this.getRegionDormitoryInfo(); // 获取宿舍楼信息
    this.getElectricTrendOption(); //用电趋势
    this.getTrendOption(); //近15日归寝趋势
    this.getPieTrendOption(); //近15日归寝率
    this.getPeopleAverageNumberOption(); //近15日各时归寝平级人数
    this.getWaterTrendChartOption(); //用水趋势图

    this.getRegionEnergySumData(); //用电概况
  },
  methods: {
    getRegionEnergySumData() {
      regionEnergySumData({ county: this.county }).then((res) => {
        this.regionEnergySumDataData = res.data;
      });
    },
    getRegionDormitoryInfo() {
      regionDormitoryInfo({ county: this.county }).then((res) => {
        this.dormitoryStatistics.map((item) => {
          item.num = res.data[item.code];
        });
      });
    },
    getElectricTrendOption() {
      electricityPastDaysTrend({ county: this.county }).then((res) => {
        let seriesData = [];
        let xAxisData = [];
        res.data.map((item) => {
          seriesData.push(item.energyValue);
          xAxisData.push(item.energyDate);
        });

        this.electricTrendOption = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          // title: {
          //   text: "单位：人数",
          //   textStyle: {
          //     fontSize: 14,
          //     color: "rgba(255, 255, 255, 0.80)",
          //   },
          // },
          // legend: {
          //   data: ["本月"],
          //   right: 0,
          //   itemHeight: 6,
          //   itemWidth: 20,
          //   icon: "rect",
          //   textStyle: {
          //     color: "rgba(255, 255, 255, 0.80)",
          //   },
          // },
          grid: {
            top: "10%",
            bottom: "10%",
            right: "10%",
            left: "2%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#04D2FF29",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            data: xAxisData,
          },
          yAxis: {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#04D2FF29",
                type: "solid",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          series: [
            {
              name: "电量",
              type: "bar",
              emphasis: {
                focus: "series",
              },
              barWidth: 10,
              itemStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#107288", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#09A9C4", // 100% 处的颜色
                    },
                  ],
                },
              },
              // label: {
              //   show: true,
              //   position: "top",
              //   color: "#fff",
              // },
              data: seriesData,
            },
          ],
        };
      });
    },
    getTrendOption() {
      regionDormitoryGoBedStuNumTrend({ county: this.county }).then((res) => {
        let xAxisData = [],
          series1 = [],
          series2 = [],
          series3 = [];
        res.data.map((item) => {
          xAxisData.push(item.goBackDate);
          series1.push(item.goBackStudentNum);
          series2.push(item.leaveStudentNum);
          series3.push(item.noGoBackStudentNum);
        });
        this.trendOption = {
          w: this.$refs.trendL.clientWidth + "px",
          h: this.$refs.trendL.clientHeight + "px",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: ["归寝人数", "请假人数", "未归人数"],
            right: 0,
            itemHeight: 6,
            itemWidth: 20,
            icon: "rect",
            textStyle: {
              color: "rgba(255, 255, 255, 0.80)",
            },
          },
          grid: {
            top: "10%",
            left: "0%",
            right: "0%",
            bottom: "0%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#04D2FF29",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            data: xAxisData,
          },
          yAxis: {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#04D2FF29",
                type: "solid",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          series: [
            {
              name: "归寝人数",
              type: "bar",
              stack: "Ad",
              emphasis: {
                focus: "series",
              },
              barWidth: 10,
              data: series1,
            },
            {
              name: "请假人数",
              type: "bar",
              stack: "Ad",
              barWidth: 10,
              emphasis: {
                focus: "series",
              },
              data: series2,
            },
            {
              name: "未归人数",
              type: "bar",
              stack: "Ad",
              barWidth: 10,
              emphasis: {
                focus: "series",
              },
              data: series3,
            },
          ],
        };
      });
    },
    getPieTrendOption() {
      regionDormitoryGoBedPercent({ county: this.county }).then((res) => {
        var xdata1 = Number(res.data.goBackPercent) / 100; //接收x数据 goBackPercent 归寝率
        var xdata2 = Number(res.data.leavePercent) / 100; //接收x数据 leavePercent 请假率
        var xdata3 = Number(res.data.noGoBackPercent) / 100; //接收x数据 noGoBackPercent /未归率
        var ydata = 100; //接收y数据
        var datas_outer = []; //存放外层颜色小块
        var num = 50; //定义小块个数
        // var rate = xdata / ydata; //完成率
        for (var i = num; i >= 0; i--) {
          if (i <= xdata1 * 50) {
            datas_outer.push({
              value: res.data.noGoBackPercent, //占位用
              name: "未归率",
              itemStyle: {
                color: "#CC3BFF",
                borderWidth: 3,
                borderColor: "#00133B",
              },
            });
          } else if (i <= (xdata2 + xdata1) * 50) {
            datas_outer.push({
              value: res.data.leavePercent, //占位用
              name: "请假率",
              itemStyle: {
                color: "#44CFFD",
                borderWidth: 3,
                borderColor: "#00133B",
              },
            });
          } else if (i <= (xdata2 + xdata1 + xdata3) * 50) {
            datas_outer.push({
              value: res.data.goBackPercent,
              name: "归寝率",
              itemStyle: {
                color: "#FFCA33", //浅绿色
                borderWidth: 3,
                borderColor: "#00133B", //背景色 融入
              },
            });
          }
        }
        this.pieTrendOption = {
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "0%",
            containLabel: true,
          },
          tooltip: {
            show: false,
          },
          legend: {
            icon: "circle",
            orient: "vertical",
            data: datas_outer.map((dItem, dIndex) => {
              return {
                ...dItem,
                textStyle: {
                  rich: {
                    // percent: {
                    //   color: colorList[dIndex],
                    // },
                  },
                },
              };
            }),
            right: "5%",
            top: "10%",
            bottom: "10%",
            itemGap: 10,
            itemWidth: 8,
            itemHeight: 8,
            selectedMode: false, // 关闭图例选择
            textStyle: {
              color: "rgba(255, 255, 255, 0.80);",
              fontSize: 14,
              fontFamily: "PingFang SC",
              rich: {
                name: {
                  color: "#FFF",
                  fontSize: 14,
                  padding: [0, 0, 4, 8],
                },
                value: {
                  color: "#fff",
                  fontSize: 24,
                  padding: [0, 0, 0, 20],
                },
              },
            },
            formatter: (name) => {
              let obj = datas_outer.find((item) => item.name === name);
              console.log(obj);
              let datas = datas_outer;
              let total = 0;
              let target = obj.value;
              for (let i = 0; i < datas.length; i++) {
                total += Number(datas[i].value);
              }
              const arr = [
                `{name|${name}}{value|${obj.value}%}`, //{percent|${((target / total) *100).toFixed(0)}}{unit|%}`
              ];
              return arr.join("");
            },
          },
          series: [
            {
              name: "完成情况外层",
              type: "pie",
              radius: ["86%", "100%"],
              center: ["25%", "50%"],
              clockwise: false,
              data: datas_outer,
              startAngle: 0,
              hoverAnimation: false,
              legendHoverLink: false,
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          ],
        };
      });
    },
    getPeopleAverageNumberOption() {
      regionDormitoryTimePeriodGoBedStuNum({
        county: this.county,
      }).then((res) => {
        let xAxisData = [],
          seriesData = [];
        res.data.map((item) => {
          xAxisData.push(item.timePeriodStr);
          seriesData.push(item.goBackStudentNum);
        });
        this.peopleAverageNumberOption = {
          w: this.$refs.peopleAverageNumber.clientWidth + "px",
          h: this.$refs.peopleAverageNumber.clientHeight + "px",
          title: {
            text: "单位/人数",
            textStyle: {
              fontSize: 14,
              color: "rgba(255, 255, 255, 0.80)",
            },
          },

          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: [
              // {
              //   name: "平均人数",
              // },
            ],
            right: 0,
            itemHeight: 4,
            itemWidth: 12,
            icon: "rect",
            textStyle: {
              color: "rgba(255, 255, 255, 0.80)",
            },
          },
          grid: {
            left: "0%",
            right: "4%",
            bottom: "3%",
            top: "18%",
            containLabel: true,
            backgroundColor: "#04D2FF29",
          },

          xAxis: {
            type: "category",
            data: xAxisData,
            axisLine: {
              lineStyle: {
                color: "#04D2FF29",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#04D2FF29",
                type: "solid",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          series: [
            {
              name: "平均人数",
              type: "line",
              stack: "Total",
              smooth: true,
              lineStyle: {
                color: "#58F4FF",
              },
              itemStyle: {
                color: "#58F4FF",
              },
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#051234", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#09A9C4", // 100% 处的颜色
                    },
                  ],
                },
              },
              data: seriesData,
            },
          ],
        };
      });
    },
    getWaterTrendChartOption() {
      //用水趋势
      waterPastDaysTrend({ county: this.county }).then((res) => {
        let xAxisData = [];
        let seriesData = [];
        res.data.map((item) => {
          xAxisData.push(item.energyDate);
          seriesData.push(item.energyValue);
        });
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          // title: {
          //   text: "单位：人数",
          //   textStyle: {
          //     fontSize: 14,
          //     color: "rgba(255, 255, 255, 0.80)",
          //   },
          // },
          // legend: {
          //   data: ["本月"],
          //   right: 0,
          //   itemHeight: 6,
          //   itemWidth: 20,
          //   icon: "rect",
          //   textStyle: {
          //     color: "rgba(255, 255, 255, 0.80)",
          //   },
          // },
          grid: {
            top: "10%",
            bottom: "10%",
            right: "10%",
            left: "2%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#04D2FF29",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            data: xAxisData,
          },
          yAxis: {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#04D2FF29",
                type: "solid",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          series: [
            {
              name: "水量",
              type: "bar",
              emphasis: {
                focus: "series",
              },
              barWidth: 10,
              itemStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#107288", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#09A9C4", // 100% 处的颜色
                    },
                  ],
                },
              },
              // label: {
              //   show: true,
              //   position: "top",
              //   color: "#fff",
              // },
              data: seriesData,
            },
          ],
        };
        this.waterTrendChartOption = option;
        console.log(this.waterTrendChartOption);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.logisticsWrap {
  display: flex;
  padding: 0 50px;
  padding-top: 115px;
  box-sizing: border-box;
  position: relative;
  .left {
    width: 380px;
    box-sizing: border-box;
    .titlebox {
      width: 100%;
      margin-bottom: 24px;
      height: 40px;
      background: url("@/assets/images/campusData/bjgk.png") no-repeat;
      background-size: 470px 40px;
      padding-left: 22px;
      box-sizing: border-box;
      color: #fff;
      text-shadow: 2px 2px 6px #1559be;
      font-family: "Alimama FangYuanTi VF";
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1.8px;
      line-height: 40px;
    }
    .jrcq {
      color: rgba(255, 255, 255, 0.88);
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2.56px;
      padding-left: 8px;
      position: relative;
      margin-bottom: 24px;
      &::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 14px;
        left: 0;
        top: 50%;
        transform: translateY(-7px);
        background: linear-gradient(
          to bottom,
          #0499ff 0%,
          #04d2ff 50%,
          #0499ff 100%
        );
      }
    }
    .mrbot {
      margin-bottom: 20px;
    }
  }
  .content {
    position: absolute;
    left: 50px;
    top: 90px;
    flex: 1;
    margin-right: 40px;
    padding-top: 90px;
    box-sizing: border-box;
    .xsqj {
      background: url("@/assets/images/campusData/xskqIcon.png") no-repeat;
      background-size: 1000px 40px;
      color: #fff;
      text-shadow: 2px 2px 6px #1559be;
      font-family: "Alimama FangYuanTi VF";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2.16px;
      padding-left: 47px;
      margin-bottom: 24px;
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
    }
    .sxSection {
      padding: 24px;
      padding-top: 0;
      border: 1px solid #04d2ff52;
      border-top: none;
      border-bottom: none;
      margin-bottom: 24px;
      box-sizing: border-box;
      .labele {
        color: rgba(255, 255, 255, 0.88);
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.56px;
        padding-left: 8px;
        position: relative;
        margin-bottom: 24px;
        box-sizing: border-box;
        &::after {
          content: "";
          position: absolute;
          width: 3px;
          height: 14px;
          left: 0;
          top: 50%;
          transform: translateY(-7px);
          background: linear-gradient(
            to bottom,
            #0499ff 0%,
            #04d2ff 50%,
            #0499ff 100%
          );
        }
      }
      .rline {
        display: block;
        width: 100%;
        object-fit: cover;
        margin: 32px 0;
      }
      .leaveStatistics {
        display: flex;
        margin-bottom: 24px;
        .item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            justify-content: flex-start;
          }
          &:last-child {
            justify-content: flex-end;
          }
          img {
            display: block;
            width: 88px;
            height: 89px;
            margin-right: 24px;
          }
          .info {
            .qjamouts {
              color: rgba(255, 255, 255, 0.8);
              font-family: "PingFang SC";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 2.24px;
              margin-bottom: 4px;
            }
            .nums {
              color: #fff;
              font-family: Akrobat;
              font-size: 24px;
              font-style: normal;
              font-weight: 900;
              line-height: normal;
              letter-spacing: 0.96px;
              span {
                color: #fff;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 2.24px;
              }
            }
          }
        }
      }
      .trendSection {
        display: flex;
        .trendL {
          flex: 1;
          height: 215px;
          margin-right: 40px;
        }
        .trendR {
          width: 340px;
          height: 138px;
          padding-top: 20px;
          box-sizing: border-box;
          position: relative;
          .centerimg {
            width: 100px;
            height: 100px;
            position: absolute;
            top: 80px;
            left: 34px;
            transform: translate(0%, -40px);
            text-align: center;
            box-sizing: border-box;
            background: url("@/assets/images/campusData/hotel_filled.png")
              center no-repeat;
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: url("@/assets/images/campusData/cqlboxIcon.png")
                no-repeat;
              background-size: 100px 100px;
              animation: rotate-ani 2s linear infinite;
            }
            @keyframes rotate-ani {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
      .peopleAverageNumber {
        width: 100%;
        height: 220px;
      }
    }
  }
  .right {
    position: absolute;
    right: 50px;
    top: 90px;
    flex: 1;
    box-sizing: border-box;
    padding-top: 90px;
    width: 530px;
    .infosBox {
      width: 508px;
      height: 200px;
      background: url("@/assets/images/campusData/watercontentBgIcon.png")
        center no-repeat;
      background-size: 366px 187px;
      position: relative;
      margin-bottom: 20px;
      .leftTop {
        position: absolute;
        left: 0;
        top: 0;
        background: url("@/assets/images/campusData/waterBgIcon.png") top left
          no-repeat;
        background-size: 216px 32px;
      }
      .leftbot {
        position: absolute;
        left: 0;
        bottom: 0;
        background: url("@/assets/images/campusData/electricBgIcon.png") top
          left no-repeat;
        background-size: 216px 32px;
      }

      .rightTop {
        position: absolute;
        right: 0;
        top: 0;
        background: url("@/assets/images/campusData/waterBgBottomIcon.png") top
          right no-repeat;
        background-size: 216px 32px;
        .wlabel {
          padding-left: 0;
          padding-right: 56px;
        }
        .amount {
          padding-right: 32px;
          padding-left: 0;
        }
      }
      .rightbot {
        position: absolute;
        right: 0;
        bottom: 0;
        background: url("@/assets/images/campusData/electricBgBottomIcon.png")
          right top no-repeat;
        background-size: 216px 32px;
        .wlabel {
          padding-left: 0;
          padding-right: 56px;
        }
        .amount {
          padding-right: 32px;
          padding-left: 0;
        }
      }

      .wlabel {
        color: rgba(255, 255, 255, 0.8);
        font-family: "PingFang SC";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        padding-left: 56px;
        box-sizing: border-box;
        line-height: 32px;
        margin-bottom: 6px;
      }
      .amount {
        color: #fff;
        font-family: Akrobat;
        font-size: 25px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 1.2px;
        text-align: center;
        padding-left: 32px;
        span {
          color: #fff;
          font-family: Akrobat;
          font-size: 14px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          letter-spacing: 0.56px;
        }
      }
    }
  }
}
</style>
