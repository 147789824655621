<template>
  <div>
    <div class="success_img"><img src="src/assets/images/wjmm_img_chenggong.png"></div>
    <div class="text_s"><span>您已设置新的密码，快去登陆吧</span></div>
     <el-button type="primary" class="goLogin_btn" @click="handleLogin_1">去登录</el-button>
  </div>
</template>

<script>
  import psdSuccess from './js/psdSuccess'
  export default {
    ...psdSuccess
  }
</script>

<style lang='less' scoped>
  @import "./css/psdSuccess.less";
</style>
