<template>
  <div class="ca-desc">
    <div v-if="classDetails" class="scaleLClass left">
      <!-- <div class="showIcon"></div> -->
      <div class="titlebox">班级概况</div>
      <div class="classdesc">
        <div class="echartsBox">
          <compontEcharts
            canvasWidth="144px"
            canvasHeight="144px"
            :options="dataSource"
          ></compontEcharts>
        </div>
        <div class="r">
          <img
            class="mrb img"
            src="@/assets/images/campusData/pieTopimg.png"
            alt
          />
          <div class="mrb ritem">
            <img src="@/assets/images/campusData/xxIcon.png" alt="" />
            小学占比
            <span class="n">
              {{ classDetails.primarySchoolClassPercent }}
              <span class="s">%</span>
            </span>
          </div>
          <div class="mrb ritem">
            <img src="@/assets/images/campusData/czIcon.png" alt="" />
            初中占比
            <span class="n">
              {{ classDetails.juniorHighSchoolClassPercent }}
              <span class="s">%</span>
            </span>
          </div>
          <div class="mrb ritem">
            <img src="@/assets/images/campusData/gzIcon.png" alt="" />
            高中占比
            <span class="n">
              {{ classDetails.highSchoolClassPercent }}
              <span class="s">%</span>
            </span>
          </div>
          <img
            class="img"
            src="@/assets/images/campusData/pieBottomimg.png"
            alt
          />
        </div>
      </div>
      <div class="titlebox">班级数量</div>
      <div class="classdesc nomrb">
        <div class="classAmout">
          <div class="numsbox">
            <img
              class="img"
              src="@/assets/images/campusData/classAmountIcon.png"
              alt
            />
            <div class="text">{{ classDetails.classNum }}</div>
          </div>
          <div class="classtip">
            <div class="classTitle">班级总数量</div>
            <div class="classUpdateTime">更新时间：{{ getCurrentDate() }}</div>
          </div>
        </div>
      </div>
      <div class="scNums">
        <div class="lineBox">
          <span class="t">小学总数</span>
          <div class="progressline">
            <div
              :style="{ width: classDetails.primarySchoolClassPercent + '%' }"
              class="progress"
            ></div>
          </div>
          <span class="s">{{ classDetails.primarySchoolClassNum }}</span>
          <span class="d">个</span>
        </div>
        <div class="lineBox">
          <span class="t">初中总数</span>
          <div class="progressline">
            <div
              :style="{
                width: classDetails.juniorHighSchoolClassPercent + '%',
              }"
              class="progress"
            ></div>
          </div>
          <span class="s">{{ classDetails.juniorHighSchoolClassNum }}</span>
          <span class="d">个</span>
        </div>
        <div class="lineBox">
          <span class="t">高中总数</span>
          <div class="progressline">
            <div
              :style="{ width: classDetails.highSchoolClassPercent + '%' }"
              class="progress"
            ></div>
          </div>
          <span class="s">{{ classDetails.highSchoolClassNum }}</span>
          <span class="d">个</span>
        </div>
      </div>
    </div>
    <div v-if="regionPeopleOverviewData" class="right left scaleRClass">
      <!-- <div class="showIcon"></div> -->
      <div class="titlebox mrd">人员概况</div>
      <div class="userbox">
        <div class="pitem">
          <div class="label">教师总数</div>
          <div class="nums">{{ regionPeopleOverviewData.teacherNum }}</div>
        </div>
        <div class="pitem">
          <div class="label">学生总数</div>
          <div class="nums">{{ regionPeopleOverviewData.studentNum }}</div>
        </div>
      </div>
      <div class="titlebox mrd">学生概况</div>
      <div class="proportionBox">
        <div class="item">
          <div class="proportion">
            {{ regionPeopleOverviewData.studentMaleFemaleRatio }}
          </div>
          <div class="proportionTip">男女比例</div>
        </div>
        <div class="item">
          <div class="proportion">
            {{ regionPeopleOverviewData.teacherStudentRatio }}
          </div>
          <div class="proportionTip">师生占比</div>
        </div>
        <div class="item">
          <div class="proportion">
            {{ regionPeopleOverviewData.graduateNum }}
          </div>
          <div class="proportionTip">上学年毕业人数</div>
        </div>
      </div>
      <div class="echarts">
        <compontEcharts
          type="custom"
          canvasWidth="422px"
          canvasHeight="300px"
          :options="columnarOption"
        ></compontEcharts>
      </div>
    </div>

    <!-- <schoolViewAlert></schoolViewAlert> -->
  </div>
</template>
<script>
require("../bmap.min.js");
import { loadBMap } from "@/utils/index";
import compontEcharts from "@/components/echarts.vue";
import * as echarts from "echarts";
import debounce from "lodash.debounce";
import {
  regionClassOverview,
  regionPeopleOverview,
} from "@/api/campusDataScreen";
import { getCurrentDate } from "@/utils/index";
import schoolViewAlert from "./schoolViewAlert.vue";
export default {
  components: {
    compontEcharts,
    schoolViewAlert,
  },
  data() {
    return {
      county: 330381,
      classOptin: null,
      dataSource: null,
      columnarOption: null,
      classDetails: null,
      regionPeopleOverviewData: null,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    getCurrentDate,
    init() {
      //班级概况
      regionClassOverview({
        county: this.county,
      }).then((res) => {
        this.classDetails = res.data;
        //饼图  班级概况
        this.dataSource = {
          graphic: {
            /**
             * 【主要代码】图形中心展示图片
             */
            elements: [
              {
                type: "image",
                style: {
                  image: require("@/assets/images/campusData/pieIconContent.png"), // 图片地址
                  width: 96,
                  height: 96,
                },
                // left: '18.5%',
                left: "center", // 【主要代码1】
                top: "center",
              },
            ],
          },
          tooltip: {
            trigger: "item",
          },
          series: [
            {
              type: "pie",
              radius: ["95%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: this.classDetails.primarySchoolClassNum,
                  name: "小学",
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#49FDFF" },
                      { offset: 1, color: "#33A2D7" },
                    ]),
                  },
                },
                {
                  value: this.classDetails.juniorHighSchoolClassNum,
                  name: "初中",
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#FDC442" },
                      { offset: 1, color: "#FF9816" },
                    ]),
                  },
                },

                {
                  value: this.classDetails.highSchoolClassNum || 0,
                  name: "高中",
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#D55FFF" },
                      { offset: 1, color: "#6F1EF3" },
                    ]),
                  },
                },
              ],
              center: ["50%", "50%"],
              itemStyle: {
                borderWidth: 6, // 设置间隔的宽度
                borderColor: "#0E1F43", // 与饼图背景色相同，形成间隔效果
              },
            },
          ],
        };
      });

      //人员概况
      regionPeopleOverview({ county: this.county }).then((res) => {
        this.regionPeopleOverviewData = res.data;
        let arr = [
          res.data.primarySchoolStudentNum,
          res.data.juniorHighSchoolStudentNum,
          res.data.highSchoolStudentNum,
        ];
        this.columnarOption = {
          tooltip: {
            trigger: "item",
          },
          xAxis: {
            type: "category",
            data: ["小学", "初中", "高中"],
            axisLabel: {
              color: "#fff",
            },
          },
          yAxis: {
            type: "value",
            // max: 200,
            axisLabel: {
              color: "#fff",
            },
            splitLine: {
              lineStyle: {
                color: "#154099",
                type: "solid",
              },
            },
          },
          series: [
            {
              type: "custom",
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    textStyle: {
                      color: "#fff",
                    },
                  },
                },
              },
              data: arr,
              renderItem(params, api) {
                // 基础坐标
                const basicsCoord = api.coord([api.value(0), api.value(1)]);
                // 顶部基础 y 轴
                const topBasicsYAxis = basicsCoord[1];
                // 基础 x 轴
                const basicsXAxis = basicsCoord[0];
                // 底部 y 轴
                const bottomYAxis = api.coord([api.value(0), 0])[1];
                return {
                  type: "group",
                  children: [
                    {
                      type: "leftShape",
                      shape: {
                        topBasicsYAxis,
                        basicsXAxis,
                        bottomYAxis,
                      },
                      style: {
                        fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: "rgb(0, 192, 238,0.8)",
                          },

                          {
                            offset: 0.8,
                            color: "rgb(0, 194, 239,0.2)",
                          },
                          {
                            offset: 1,
                            color: "rgb(0, 194, 239,0)",
                          },
                        ]),
                        emphasis: {
                          fill: "yellow", // 鼠标高亮时的填充颜色
                        },
                      },
                    },
                    {
                      type: "rightShape",
                      shape: {
                        topBasicsYAxis,
                        basicsXAxis,
                        bottomYAxis,
                      },
                      style: {
                        fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: "#00CCF5 ",
                          },

                          {
                            offset: 0.8,
                            color: "rgb(4, 88, 115,0.8)",
                          },
                          {
                            offset: 1,
                            color: "rgb(4, 88, 115,0.6)",
                          },
                        ]),
                      },
                    },
                    {
                      type: "topShape",
                      shape: {
                        topBasicsYAxis,
                        basicsXAxis,
                        bottomYAxis,
                      },
                      style: {
                        fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0.3,
                            color: "#6DF0FF",
                          },
                          {
                            offset: 1,
                            color: "#6DF0FF",
                          },
                        ]),
                      },
                    },
                  ],
                };
              },
            },
            {
              type: "bar",

              // label: {
              //   normal: {
              //     show: true,
              //     position: "top",
              //     color: "#fff",
              //     fontSize: 20,
              //     offset: [4, -10],
              //     formatter: function (params) {
              //       if (params.value > 0) {
              //         return params.value;
              //       } else {
              //         return "";
              //       }
              //     },
              //   },
              // },
              emphasis: {
                focus: "series",
              },
              barWidth: 14,
              itemStyle: {
                color: "transparent",
              },
              tooltip: {
                show: true,
              },
              data: arr,
            },
          ],
        };
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ca-desc {
  display: flex;
  padding: 0 40px;
  padding-top: 115px;
  box-sizing: border-box;
  .left {
    width: 470px;
    position: relative;
    pointer-events: all;
    .showIcon {
      position: absolute;
      right: -50px;
      top: 50%;
      width: 33px;
      height: 150px;
      background: url("@/assets/images/campusData/showIcon.png") no-repeat;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .weather {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: #fff;
      margin-bottom: 32px;
    }
    .titlebox {
      width: 100%;
      margin-bottom: 72px;
      height: 40px;
      background: url("@/assets/images/campusData/bjgk.png") no-repeat;
      background-size: 100% 40px;
      padding-left: 22px;
      box-sizing: border-box;
      color: #fff;
      text-shadow: 2px 2px 6px #1559be;
      font-family: "Alimama FangYuanTi VF";
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1.8px;
      line-height: 40px;
    }
    .classdesc {
      display: flex;
      margin-bottom: 74px;

      .echartsBox {
        width: 172px;
        height: 172px;
        border-radius: 50%;
        padding: 10px;
        box-sizing: border-box;
        border: 3px solid #254497;
        margin-top: 28px;
      }
      .r {
        width: 208px;
        height: 227px;
        flex-shrink: 0;
        margin-left: 41px;
        text-align: center;
        .mrb {
          margin-bottom: 19px;
        }
        .ritem {
          color: rgba(255, 255, 255, 0.8);
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          box-sizing: border-box;
          padding-left: 23px;
          display: flex;
          align-items: center;
          img {
            display: block;
            width: 10px;
            height: 18px;
            margin-right: 10px;
          }
          span.n {
            color: #fff;
            font-family: Akrobat;
            font-size: 26px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 0.52px;
            margin-left: 10px;
            .s {
              color: #fff;
              font-family: Akrobat;
              font-size: 16px;
              font-style: normal;
              font-weight: 900;
              line-height: normal;
              letter-spacing: 0.32px;
            }
          }
        }
        .img {
          width: 100%;
        }
      }

      .classAmout {
        display: flex;
        .numsbox {
          width: 146px;
          height: 104px;
          position: relative;
          margin-right: 40px;
          .img {
            width: 100%;
            height: 100%;
            display: block;
          }

          .text {
            color: #70ffd6;
            text-align: center;
            font-family: Akrobat;
            font-size: 50px;
            font-style: normal;
            font-weight: 900;
            line-height: 62px;
            letter-spacing: 1px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
          }
        }
        .classtip {
          padding-top: 33px;
          .classTitle {
            color: #fff;
            font-family: "PingFang SC";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 2.52px;
            margin-bottom: 10px;
          }
          .classUpdateTime {
            color: rgba(255, 255, 255, 0.8);
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
    .classdesc.nomrb {
      margin-bottom: 30px;
    }
    .scNums {
      color: #fff;
      .lineBox {
        display: flex;
        margin-bottom: 55px;
        align-items: center;
        &:last-child {
          margin-bottom: 0;
        }
        .t {
          color: #fff;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .progressline {
          width: 254px;
          height: 8px;
          background: #152a60;
          border-radius: 10px;
          margin: 0 20px;
          position: relative;
          .progress {
            position: absolute;
            width: 0px;
            height: 100%;
            background: linear-gradient(to right, #2645f4, #07ebe2);
            transition: width 0.5s ease;
            overflow: hidden;
            &::after {
            }
          }
        }
        .s {
          color: #fff;
          font-family: Akrobat;
          font-size: 20px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
        .d {
          color: #fff;
          font-family: Akrobat;
          font-size: 10px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          padding-top: 5px;
        }
      }
    }
  }
  .scaleLClass {
    transform-origin: 0 0;
    position: fixed;
    left: 40px;
    top: 115px;
    transition: 0.3s;
  }
  .content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .ruianMap {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .ruianimg {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50%,
        75% {
          transform: scale(3);
        }
        78%,
        100% {
          opacity: 0;
        }
      }
      .dropIcon {
        position: absolute;
        left: 50px;
        width: 38px;
        height: 51px;
        border-radius: 50%;
        // margin: 100px 100px 50px 200px;
        background: url("@/assets/images/campusData/dropIcon.png") no-repeat;
        background-size: 38px 51px;
        transform: translateZ(60px);
      }
      .dropIcon:before {
        content: "";
        display: block;
        width: 38px;
        height: 51px;
        border-radius: 50%;
        opacity: 0.7;
        background-color: #02e8ff;
        animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
        animation-delay: 200ms;
      }
    }
  }
  .right {
    width: 470px;
    .showIcon {
      right: auto;
      left: -50px;
    }
    .mrd {
      margin-bottom: 40px;
    }
    .userbox {
      display: flex;
      justify-content: center;
      margin-bottom: 31px;
      .pitem {
        width: 160px;
        height: 300px;
        background: url("@/assets/images/campusData/teacherBg.png") no-repeat;
        background-size: 100% 100%;
        margin-right: 50px;
        padding-top: 197px;
        text-align: center;
        box-sizing: border-box;
        &:last-child {
          margin-right: 0;
          background: url("@/assets/images/campusData/studentBg.png") no-repeat;
        }
        .label {
          color: rgba(255, 255, 255, 0.8);
          font-family: "PingFang SC";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .nums {
          color: #fff;
          font-family: Akrobat;
          font-size: 32px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          letter-spacing: 0.64px;
          margin-top: 9px;
        }
      }
    }
    .proportionBox {
      display: flex;
      justify-content: center;
      margin-bottom: 33px;
      .item {
        width: 100px;
        margin-right: 61px;
        &:last-child {
          margin-right: 0;
        }
        .proportion {
          color: #fff;
          text-align: center;
          font-family: "Alimama FangYuanTi VF";
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.52px;
          margin-bottom: 7px;
        }
        .proportionTip {
          color: rgba(255, 255, 255, 0.8);
          text-align: center;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100px;
          height: 69px;
          background: url("@/assets/images/campusData/xwIcon.png") center
            no-repeat;
        }
      }
    }
  }
  .scaleRClass {
    position: fixed;
    transition: 0.3s;
    right: 40px;
    top: 115px;
    transform-origin: 0 0;
  }
}
</style>
