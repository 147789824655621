import request from "../commons/utils/axios";
//学生信息导出
export function studentBasicInfoExport(data) {
  return request({
    url: "/base-info/studentBasicInfo/export",
    method: "post",
    data: data,
  });
}

//menu
export function getMenuList(id) {
  return request({
    url: "/system/user/getFunc?schoolId=" + id,
    method: "get",
  });
}

export function getCardBalance(data) {
  return request({
    url: "/base-info/card/app/balance",
    data,
    method: "post",
  });
}

// 获取设备列表
export function getEquipmentOptions(type) {
  return request({
    url: `/hdf-third/thirdDevice/app/list?bizType=${type}`,
    method: "get",
  });
}
