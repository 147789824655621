/*
 * @Author: yuan wei lin
 * @Date: 2022-10-09 13:46:47
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2024-05-28 10:08:42
 * @Descripttion:
 */
import md5 from "md5";
import Storage from "@/commons/utils/storage.js";
import {
  searchMenu,
  getSearchHistory,
  deleteSearchHistory,
  addMenuSearchHistory,
} from "@/api/searchMenu.js";
import { getMenuList } from "@/api/base";
export default {
  watch: {
    openSchoolIfonType(n, o) {
      if (!n) {
        this.$refs.schoolIfonDialog.title = "";
        this.$refs.schoolIfonDialog.CloseDialog();
      } else {
        this.$refs.schoolIfonDialog.title = "完善信息";
        this.$refs.schoolIfonDialog.OpenDialog();
        this.$refs.gradeinformationadd.buttonShow1 = true;
      }
    },
    dialogVisible(n, o) {
      if (!n) {
        this.$refs.gradeinformationadd.title = "";
        this.$refs.gradeinformationadd.CloseDialog();
      } else {
        this.$refs.gradeinformationadd.title = "用户信息填写";
        this.$refs.gradeinformationadd.OpenDialog();
        this.$refs.gradeinformationadd.buttonShow1 = false;
      }
    },
  },
  data() {
    var checkNewPsw = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("新密码不能为空"));
      } else if (
        !/^(?![\d]+$)(?![a-z]+$)(?![A-Z]+$)(?![~!@#$%^&*.]+$)[\da-zA-z~!@#$%^&*.]{6,12}$/.test(
          value
        )
      ) {
        callback(
          new Error("请输入6-12位字母、数字、特殊符号且包含两种及以上!")
        );
      } else {
        callback();
      }
    };
    var checkPsw = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("确认密码不能为空"));
      } else if (value != this.editForm.password) {
        callback(new Error("确认密码与新密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      isScrollActive: true,
      pwdDialog: false,
      fromItems: [
        {
          label: "旧密码",
          prop: "oldPassword",
          component: "input",
          props: {
            placeholder: "请输入旧密码",
            showPassword: true,
          },
          rules: [
            { required: true, message: "旧密码不能为空", trigger: "blur" },
          ],
        },
        {
          label: "新密码(请输入6-12位字母、数字、特殊符号且包含两种及以上!)",
          prop: "password",
          component: "input",
          props: {
            placeholder: "请输入新密码",
            showPassword: true,
          },
          rules: [
            { required: true, message: "新密码不能为空", trigger: "blur" },
            { required: true, message: "新密码不能为空", trigger: "change" },
            { validator: checkNewPsw, trigger: "blur" },
            { validator: checkNewPsw, trigger: "change" },
          ],
        },
        {
          label: "确认密码",
          prop: "repeatPassword",
          component: "input",
          props: {
            placeholder: "请输入确认密码",
            showPassword: true,
          },
          rules: [
            { required: true, message: "确认密码不能为空", trigger: "blur" },
            { required: true, message: "确认密码不能为空", trigger: "change" },
            { validator: checkPsw, trigger: "blur" },
            { validator: checkPsw, trigger: "change" },
          ],
        },
      ],
      editForm: {},
      imgPath: process.env.VUE_APP_FILE_URL,
      tabItemActive: 0,
      historyList: [],
      menuListAll: [],
      dialogVisible: false,
      openSchoolIfonType: false,
      activeName: "first",
      user: {},
      input: "",
      sendCodeText: "获取验证码",
      isClickSendCode: false,
      addManagerForm: {},
      schoolInfo: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phoneNum: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "change",
          },
        ],
        twoPassword: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "change",
          },
        ],
      },
      childrenModule: [
        {
          name: "人脸库管理",
          id: "22102513491800000159",
        },
        {
          name: "学生考勤统计",
          id: "22101810344900000152",
        },
        {
          name: "课堂评价报告",
          id: "24062411524700001008",
        },
        {
          name: "课堂评价记录",
          id: "24062411480800001006",
        },
        {
          name: "学校德育画像",
          id: "24071815141900001089",
        },
        {
          name: "调课管理",
          id: "23111510083400000485",
        },
        {
          name: "评分人员管理",
          id: "22121613591200000179",
        },
        {
          name: "评分记录管理",
          id: "22121614002200000181",
        },
        {
          name: "称号授予记录",
          id: "23121313500500000518",
        },
        {
          name: "日常评价报告",
          id: "24070517575100001035",
        },
        {
          name: "学生评价记录",
          id: "23081817184700000410",
        },
        {
          name: "寝室点评记录",
          id: "23120514052600000510",
        },
        {
          name: "点评人员管理",
          id: "23120110243400000502",
        },
        {
          name: "称号授予记录",
          id: "24011114425500000553",
        },
      ],
      sections: [],
      homeDom: null,
    };
  },
  methods: {
    updatePsd() {
      //修改密码
      this.pwdDialog = true;
    },
    async changePwd() {
      const valid = await this.$refs.editForm.validate();
      if (valid) {
        var this_ = this;
        this.http
          .ajaxHandler()
          .success(function (code, msg) {
            if (code == 200) {
              this_.pwdDialog = false;
              this_.$message({
                type: "success",
                message: "密码修改成功!",
              });
              this_.$router.push("/");
            } else {
              this_.$message({
                message: msg,
                type: "warning",
              });
            }
          })
          .error(function (code, msg) {
            this_.$message.error(msg);
          })
          .post({
            url: "/system/user/updPsd",
            data: {
              password: md5(this.editForm.password).toUpperCase(),
              oldPassword: md5(this.editForm.oldPassword).toUpperCase(),
            },
          });
      }
    },
    setTabName(item, pname) {
      if (item.funcShowName) {
        sessionStorage.setItem("currentTabName", item.funcShowName);
        localStorage.setItem("activeIndex", item.funcId);
      } else {
        let pn = this.menuListAll[this.tabItemActive].name;
        sessionStorage.setItem("currentTabName", `${pn}/${pname}`);
        localStorage.setItem("activeIndex", item.funcId);
      }
    },
    filterMenu(item) {
      let list = [];
      let id = item.funcId || item.id;
      switch (id) {
        case "22102513491800000159":
          list = [
            {
              name: "学生人脸库管理",
              link: "/faceInput/index?type=1",
            },
            {
              name: "教职工人脸库管理",
              link: "/faceInput/index?type=2",
            },
          ];
          break;
        case "22101810344900000152":
          list = [
            {
              name: "今日在校学生",
              link: "/schoolAttendance/Statistics/peopleNumDetails?type=在校&classId=&gradeId=",
            },
            {
              name: "今日缺勤学生",
              link: "/schoolAttendance/Statistics/peopleNumDetails?type=旷课&classId=&gradeId=",
            },
            {
              name: "今日请假学生",
              link: "/schoolAttendance/Statistics/peopleNumDetails?type=请假&classId=&gradeId=",
            },
          ];
          break;
        case "24062411480800001006":
          list = [
            {
              name: "新增评分",
              link: "/classEvaluation/record?add=1",
            },
          ];
          break;

        case "24062411524700001008":
          list = [
            {
              name: "热门评价分布",
              link: "/classEvaluation/hotRanking",
            },
            {
              name: "学生榜单",
              link: "/classEvaluation/ranking",
            },
            {
              name: "班级人均排行",
              link: "/classEvaluation/classRanking",
            },
          ];
          break;

        case "24071815141900001089":
          list = [
            {
              name: "学生榜单",
              link: "/moralEducationPortrait/studentRanking",
            },
            {
              name: "班级人均排行",
              link: "/moralEducationPortrait/classRanking",
            },
            {
              name: "点评人员统计",
              link: "/moralEducationPortrait/evaluatorRanking",
            },
          ];
          break;
        case "23081817184700000410":
          list = [
            {
              name: "新增评分",
              link: "/studentEvaluate/record?add=1",
            },
          ];
          break;

        case "24070517575100001035":
          list = [
            {
              name: "热门点评榜单",
              link: "/studentEvaluate/hotRanking",
            },
            {
              name: "学生榜单",
              link: "/studentEvaluate/rankingNew",
            },
            {
              name: "班级人均排行",
              link: "/studentEvaluate/classRanking",
            },
          ];
          break;
        case "23121313500500000518":
          list = [
            {
              name: "颁发荣誉称号",
              link: "/honoraryTitle/honorRecord?add=1",
            },
          ];
          break;
        case "22121614002200000181":
          list = [
            {
              name: "新增评分",
              link: "/scoreModel/scoreRecord?add=1",
            },
          ];
          break;
        case "22121613591200000179":
          list = [
            {
              name: "新增评分人员",
              link: "/scoreModel/scorePeople?add=1",
            },
          ];
          break;
        case "23111510083400000485":
          list = [
            {
              name: "发起调课",
              link: "/adjustClassesMessage/index?add=1",
            },
          ];
          break;
        case "23120514052600000510":
          list = [
            {
              name: "新增寝室点评",
              link: "/bedroomEvaluate/index?add=1",
            },
          ];
          break;
        case "23120110243400000502":
          list = [
            {
              name: "新增点评人员",
              link: "/peopleManagement/index?add=1",
            },
          ];
          break;
        case "24011114425500000553":
          list = [
            {
              name: "颁发寝室荣誉称号",
              link: "/civilizedDormitory/record?add=1",
            },
          ];
          break;
        default:
          break;
      }
      return list;
    },

    isShowChildren(item) {
      let id = item.funcId || item.id;
      let idExists = this.childrenModule.some((obj) => obj.id == id);
      return idExists;
    },

    handleHistoryItem(item) {
      //点击历史记录itemm
      this.changeMneu(item);
    },

    async changeMneu(row, menuLink) {
      row.funcId = row.funcId ? row.funcId : row.id;
      let url = menuLink ? menuLink : row.link || row.funcUrl;
      let name = row.funcName || row.name;
      let api = async (type) => {
        let res = await addMenuSearchHistory(row);
        if (res.code == 200) {
          if (type) {
            this.$router.push(`${url}`);
          }
        } else this.$message.error(res.describe);
      };
      if (name == "自选课管理" || name == "德育维度") {
        this.goMyProjects(row);
        api(false);
      } else if (name == "农业平台") {
        this.goNongYe(row);
        api(false);
      } else api(true);
    },

    // 跳转农业平台
    goNongYe() {
      let orgId = localStorage.getItem("schoolId") || null;
      if (orgId == "2208010064") {
        // 测试实验小学
        window.open(
          "http://121.40.125.107:30575/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else if (orgId == "2209140066") {
        // 正式毓蒙
        window.open(
          "http://agriculture.prod.camp.huaching.com/dovey-environment/login/input"
        );
      } else if (orgId == "2407090079") {
        // 正式马鞍山实验小学
        window.open(
          "http://121.40.125.107:31138/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else if (orgId == "2411110082") {
        // 文成县珊溪镇中心小学
        window.open(
          "http://121.40.125.107:31011/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else {
        // 其他
        window.open(
          "http://agriculture.prod.camp.huaching.com/dovey-environment/login/input"
        );
      }
    },

    getProxyLink() {
      let type = process.env.VUE_APP_ENV;
      let url = "";
      switch (type) {
        case "test":
          // 测试
          url =
            "https://nginx.file.campus.project.kube.huaching.com/proxy/campus-web-my/";
          break;
        // 演示
        case "pre":
          url = "https://campus.huaching.com/campus-web-my/";
          break;
        // 正式
        case "prod":
          url = "https://campus.huaching.com/campus-web-my/";
          break;

        default:
          break;
      }
      return url;
    },

    // 跳转马屿小学项目
    goMyProjects(item) {
      let SID = this.user.token;
      let mobile = this.user.mobile;
      let type, url;
      url = this.getProxyLink();
      // url = "http://localhost/campus-web-my/";
      if (item.name == "自选课管理") type = "view_course";
      if (item.name == "德育维度") type = "view_deyu_evaluation";
      let queryString = "?SID=" + SID + "&mobile=" + mobile + "&type=" + type;
      window.open(url + queryString, "_blank");
    },

    async getHistoryList() {
      let that = this;
      await getSearchHistory().then((res) => {
        this.historyList = res.data;
      });
      let locaMenu = Storage.get("menu");
      if (locaMenu && locaMenu.length) {
        this.menuListAll = Storage.formatTreeDataMain(locaMenu, "0").children;
        this.menuListAll.splice(0, 1);
      } else {
        let id = localStorage.getItem("schoolId");
        let res = await getMenuList(id);
        this.menuListAll = Storage.formatTreeDataMain(res.result, "0").children;
      }
      this.$nextTick(() => {
        this.sections = this.menuListAll.map((item) =>
          document.getElementById(item.id)
        );
      });
    },

    getDisplayName(name) {
      if (name) {
        if (name.length === 2) {
          return name.slice(-1); // 显示最后一个字
        } else if (name.length >= 3) {
          return name.slice(-2); // 显示最后两个字
        }
      }
      return name; // 默认返回名字本身
    },
    getSchoolInfo() {
      let corpId = localStorage.getItem("schoolId");
      this.$axios({
        method: "get",
        url: "/base-info/schoolCorpId/selectById?corpId=" + corpId,
      })
        .then((res) => {
          if (res.code == 200) {
            this.schoolInfo = res.result;
            this.checkManager();
          }
          // else this.$utils.messageText(res);
        })
        .catch((err) => console.log(err));
    },
    //判断学校基本信息是否完善
    checkSchoolInfo() {
      this.$axios({
        method: "get",
        url: "/base-info/schoolCorpId/app/checkSchoolInfo",
      })
        .then((res) => {
          if (res.code == 200) {
            if (res.data == 0 && res.code != 18023) {
              this.openSchoolIfonType = true;
            }
          }
          // else this.$utils.messageText(res);
        })
        .catch((err) => console.log(err));
    },

    handleClick(event, link) {
      event.preventDefault(); // 阻止默认跳转行为
      const target = document.querySelector(link.href.slice(1)); // 获取目标元素
      if (target) {
        target.style.margin = "70px 0 0 0 ";
        target.scrollIntoView({
          behavior: "smooth",
        }); // 平滑滚动到目标
      }
    },

    addClick() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.addManagerForm.name) {
            this.$message.error("请填写姓名");
            return;
          }
          if (!this.addManagerForm.phoneNum) {
            this.$message.error("请填写手机号");
            return;
          }
          if (!this.addManagerForm.code) {
            this.$message.error("请填写验证码");
            return;
          }
          if (!this.addManagerForm.password) {
            this.$message.error("请填写密码");
            return;
          }
          if (!this.addManagerForm.twoPassword) {
            this.$message.error("请再次填写密码");
            return;
          }
          let regExp = /^1[3|5|7|8|9]\d{9}$/;
          if (!regExp.test(this.addManagerForm.phoneNum)) {
            this.$message.error("请填写正确的手机号");
            return;
          }
          if (!this.validatePassword(this.addManagerForm.password)) {
            this.$message.error("密码不符合要求！");
            return;
          }
          if (this.addManagerForm.password != this.addManagerForm.twoPassword) {
            this.$message.error("两次输入密码不一致");
            return;
          }

          this.checkPhoneHas();
        } else {
        }
      });
    },
    validatePassword(password) {
      // 使用正则表达式进行校验
      var lengthCheck = /^.{6,12}$/;
      // 正则表达式分别检查包含字母、数字和特殊符号
      var containsLetter = /[A-Za-z]/;
      var containsDigit = /\d/;
      var containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
      // 计算包含的类型数量
      var typeCount = 0;
      if (containsLetter.test(password)) typeCount++;
      if (containsDigit.test(password)) typeCount++;
      if (containsSpecialChar.test(password)) typeCount++;

      // 返回是否满足长度要求且包含至少两种类型
      return lengthCheck.test(password) && typeCount >= 2;
      // return regex.test(password);
    },
    updateSchoolInfo() {
      let result = this.$refs.schoolInfoRef.getParams();
      result
        .then((params) => {
          let param = JSON.parse(JSON.stringify(params));
          param.schoolRecordId = this.schoolId;

          this.$axios({
            method: "post",
            url: "/base-info/schoolCorpId/renewSchoolInfo",
            data: params,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.openSchoolIfonType = false;
              } else this.$utils.messageText(res);
              //
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },
    //主管理员信息完善
    addManager() {
      this.addManagerForm.password = md5(
        this.addManagerForm.password
      ).toUpperCase();
      this.addManagerForm.twoPassword = md5(
        this.addManagerForm.twoPassword
      ).toUpperCase();

      this.$axios({
        method: "post",
        url: "/system/user/addManager",
        data: this.addManagerForm,
      })
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false;
            // if (this.addManagerForm.coverPassword ==0) {
            //   this.logout()
            // }else{
            this.$confirm(
              "用户账号创建完毕，请使用手机号及对应密码登录",
              "提示",
              {
                confirmButtonText: "我知道了",
                showCancelButton: false,
                // cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.logout();
              })
              .catch((err) => console.log(err));
            // }

            // this.checkSchoolInfo()
          } else this.$utils.messageText(res);
          //
        })
        .catch((err) => console.log(err));
    },
    logout() {
      var this_ = this;
      Storage.remove("menu");
      Storage.remove("user");
      Storage.remove("isMenu");
      this.http
        .ajaxHandler()
        .success(function (code, msg) {
          if (code == 200) {
            this_.$message({
              type: "success",
              message: "退出成功!",
            });
            //确认退出，清除token
            // localStorage.removeItem("token");
            // localStorage.removeItem("userDingtalkList");
            // localStorage.removeItem("schoolId");
            // localStorage.removeItem("activeIndex");
            // localStorage.removeItem("houseId");
            // localStorage.removeItem("houseDetails");
            // sessionStorage.removeItem("currentTabName");
            // sessionStorage.removeItem("currentButtonWeekStatus");
            // sessionStorage.removeItem("weekTime");

            localStorage.clear();
            sessionStorage.clear();

            //跳转登录页面(编程式导航)
            this_.$router.push("/");
          } else {
            this_.$message({
              message: msg,
              type: "warning",
            });
          }
        })
        .error(function (code, msg) {
          this_.$message.error(msg);
        })
        .post({
          url: "/system/user/logout",
        });
    },
    //校验手机号是否存在
    checkPhoneHas() {
      this.$axios({
        method: "get",
        url:
          "/system/user/checkPhoneHas?phoneNum=" +
          this.addManagerForm.phoneNum +
          "&code=" +
          this.addManagerForm.code,
      })
        .then((res) => {
          if (res.code == 200) {
            if (res.data == 1) {
              this.$confirm(
                "当前手机号已有账号，请选择：使用原密码登录或修改为新密码！",
                "提示",
                {
                  confirmButtonText: "使用原始密码",
                  cancelButtonText: "修改为新密码",
                  type: "warning",
                }
              )
                .then(() => {
                  this.addManagerForm.coverPassword = 0;
                  this.addManager();
                })
                .catch(() => {
                  this.addManagerForm.coverPassword = 1;
                  this.addManager();
                });
            } else {
              this.addManagerForm.coverPassword = 0;
              this.addManager();
            }
          } else this.$utils.messageText(res);
        })
        .catch((err) => console.log(err));
    },

    //发送验证码
    onSendCode() {
      if (this.isClickSendCode) {
        return;
      }
      if (!this.addManagerForm.phoneNum) {
        this.$message.error("请填入手机号");
        return;
      }
      let regExp = /^1[3|5|7|8|9]\d{9}$/;
      if (!regExp.test(this.addManagerForm.phoneNum)) {
        this.$message.error("请填写正确的手机号");
        return;
      }
      let bean = {
        mobile: this.addManagerForm.phoneNum,
        smsCodeBizType: 2,
      };
      this.$axios({
        method: "post",
        url: "/common/smscode/ignore/send_sms_code",
        data: bean,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("发送成功");
            this.isClickSendCode = true;
            this.startTimer();
          } else this.$utils.messageText(res);
        })
        .catch((err) => console.log(err));
    },
    startTimer() {
      var countdown = 60; // Countdown duration in seconds
      var this_ = this;
      var timer = setInterval(function () {
        countdown--;
        this_.sendCodeText = "重新获取（" + countdown + "s）";

        if (countdown == 0) {
          clearInterval(timer);
          this_.sendCodeText = "获取验证码";
          this_.isClickSendCode = false;
        }
      }, 1000);
      window.onload = startTimer(); // Update every second
    },
    //校验是不是运营平台创建的管理员
    checkManager() {
      this.$axios({
        method: "get",
        url: "/system/user/checkManager",
      })
        .then((res) => {
          if (res.code == 200) {
            if (res.data == 1) {
              this.dialogVisible = true;
            } else {
              this.checkSchoolInfo();
            }
          } else {
            this.$utils.messageText(res);
          }
        })
        .catch((err) => console.log(err));
    },

    updateActiveSection() {
      // if (!this.isScrollActive) return;
      let scrollPosition = this.homeDom.scrollTop;
      this.sections.forEach((item) => {
        if (
          item.offsetTop <= scrollPosition - 60 &&
          item.offsetTop + item.offsetHeight > scrollPosition - 60
        ) {
          this.tabItemActive = item.id;
        }
      });
    },

    handleTab(item, index) {
      const element = document.getElementById(item.id);
      if (element) {
        const targetScrollTop = element.offsetTop + 260;
        const startScrollTop = this.homeDom.scrollTop;
        const duration = 500; // 动画持续时间，单位为毫秒
        const startTime = performance.now();
        const easeInOutQuad = (t) =>
          t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        const animateScroll = (currentTime) => {
          const elapsed = currentTime - startTime;
          const progress = Math.min(elapsed / duration, 1); // 确保 progress 不超过 1
          const easedProgress = easeInOutQuad(progress);
          this.homeDom.scrollTop =
            startScrollTop + (targetScrollTop - startScrollTop) * easedProgress;
          if (progress < 1) {
            requestAnimationFrame(animateScroll);
            this.isScrollActive = false;
          } else {
            console.log("jieshu");
            this.isScrollActive = true;
          }
        };
        requestAnimationFrame(animateScroll);
      }
    },
  },

  created() {
    this.getSchoolInfo();
    this.getHistoryList();
    if (Storage.get("user")) {
      this.user = JSON.parse(
        decodeURIComponent(decodeURIComponent(Storage.get("user")))
      );
    } else {
      var this_ = this;
      this.http
        .ajaxHandler()
        .success(function (code, msg, data) {
          if (code == 200) {
            this_.user = data;
            Storage.set(
              "user",
              encodeURIComponent(encodeURIComponent(JSON.stringify(data)))
            );
          } else {
            this_.$message({
              message: msg,
              type: "warning",
            });
          }
        })
        .error(function (code, msg) {
          this_.$message.error(msg);
        })
        .post({
          url: "/system/user/getCurUser",
        });
    }
  },

  mounted() {
    this.homeDom = document.getElementsByClassName("deskWrap")[0];
  },
};
