<template>
  <div class="wrap">
    <div class="header">
      <div v-if="weatherContent" class="weatherContent">
        <i
          style="font-size: 40px; margin-right: 10px"
          :class="'qi-' + weatherContent.iconDay"
        ></i
        >{{ weatherContent.textDay }} {{ weatherContent.tempMin }}℃~{{
          weatherContent.tempMax
        }}℃
      </div>
      <div class="rightTime">{{ nowTime }}</div>
      <div class="title">区域校园综合数据平台</div>
    </div>

    <div class="row">
      <div class="tabs">
        <div
          v-for="(item, index) in tabs"
          @click="handler(index)"
          :key="index"
          class="item"
          :class="active == index ? 'ac' : ''"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <!-- 瑞安市三维地图 -->
    <mapRuian
      :schoolList="schoolList"
      v-show="active == 1"
      style="z-index: 2"
      ref="mapRuian"
    ></mapRuian>
    <campusDesc
      :style="styleObject"
      style="pointer-events: none"
      class="commonClass"
      v-if="active == 1"
    ></campusDesc>
    <flowData
      :style="styleObject"
      class="commonClass"
      v-if="active == 0"
    ></flowData>
    <logisticsData
      :style="styleObject"
      class="commonClass"
      v-if="active == 2"
    ></logisticsData>
    <div class="footerWrap"></div>
  </div>
</template>
<script>
import debounce from "lodash.debounce";
import campusDesc from "./common/campusDesc.vue";
import flowData from "./common/flowData.vue";
import logisticsData from "./common/logisticsData.vue";
import { getCurrentDateTime } from "@/utils/index";
import "qweather-icons/font/qweather-icons.css";
import { todayTomorrow, schoolListByRegion } from "@/api/campusDataScreen";
import { loadBMap, bMapTransQQMap } from "@/utils/index";
import mapRuian from "./map/map_ruian.vue";
export default {
  components: {
    campusDesc,
    flowData,
    logisticsData,
    mapRuian,
  },
  data() {
    return {
      tabs: ["流动数据概况", "校园概况", "后勤数据概况"],
      active: 1,
      nowTime: null,
      county: 330381,
      schoolList: [],
      weatherContent: null, //天气
      scale: this.getScale(),
    };
  },
  created() {
    document.title = "区域校园综合数据平台";
    this.upadteTime();
    this.getSchoolList();
  },
  computed: {
    styleObject() {
      const obj = {
        transform: `scaleY(${this.scale.y}) scaleX( ${this.scale.x}) translate(-50%, -50%)`,
        WebkitTransform: `scale(${this.scale}) translate(-50%, -50%)`,
        width: 1920 + "px",
        height: 1080 + "px",
      };
      return obj;
    },
    styleObjectHeader() {
      const obj = {
        transform: `scaleY(${this.scale.y}) scaleX( ${this.scale.x}) translate(-50%,0)`,
        WebkitTransform: `scale(${this.scale}) translate(-50%,0)`,
        width: 1920 + "px",
        height: 82 + "px",
      };
      console.log(obj);
      return obj;
    },
  },
  mounted() {
    this.getScale();
    window.addEventListener("resize", this.setScale);
  },
  beforeDestroy() {
    window.addEventListener("resize", this.setScale);
  },
  methods: {
    getScale() {
      // 固定好16:9的宽高比，计算出最合适的缩放比，宽高比可根据需要自行更改
      console.log(window.innerWidth, "window.innerWidth");
      const ww = window.innerWidth / 1920;
      const wh = window.innerHeight / 1080;
      // return ww < wh ? ww : wh
      return { x: ww, y: wh };
    },
    setScale: debounce(function () {
      // 获取到缩放比，设置它
      const scale = this.getScale();
      this.scale = scale;
    }, 300),
    upadteTime() {
      let that = this;
      setInterval(function () {
        that.nowTime = getCurrentDateTime();
      }, 1000);
    },
    getSchoolList() {
      schoolListByRegion({ county: this.county })
        .then((res) => {
          this.schoolList = res.data;
          return todayTomorrow({ orgId: res.data[0].orgId }); //获取天气
        })
        .then((res) => {
          this.weatherContent = res.data[0];
          this.init();
        });
    },
    init() {
      let mapKey = "Z57oGRXUiO1DQkS473UegXGpgRusVaFN";
      if (process.env.NODE_ENV != "test") {
        mapKey = "Z57oGRXUiO1DQkS473UegXGpgRusVaFN";
      }
      //高德
      // AMap.plugin("AMap.PlaceSearch", function () {
      //   var placeSearch = new AMap.PlaceSearch({
      //     //city 指定搜索所在城市，支持传入格式有：城市名、citycode 和 adcode
      //     city: "010",
      //   });
      //   placeSearch.search("北京大学", function (status, result) {
      //     //查询成功时，result 即对应匹配的 POI 信息
      //     console.log(result);
      //   });
      // });

      loadBMap(mapKey).then((res) => {
        var myGeo = new BMap.Geocoder();
        this.schoolList.map((item, index) => {
          myGeo.getPoint(
            item.orgName,
            function (point) {
              //百度转高德经纬度
              let lngLat = bMapTransQQMap(point.lng, point.lat);
              item.coordinate = [lngLat.lng, lngLat.lat];
            },
            item.cityName
          );
        });
        this.returnSchoolList();
      });
    },
    returnSchoolList() {
      let index = 0;
      let that = this;
      this.schoolList.map((item) => {
        if (item.coordinate && item.coordinate.length > 0) {
          index++;
        }
      });
      if (index == this.schoolList.length) {
        this.$refs.mapRuian.createMarker(this.schoolList);
      } else {
        setTimeout(() => {
          that.returnSchoolList();
        }, 500);
      }
    },
    handler(i) {
      this.active = i;
      // if (this.active == 1) {
      //   this.$refs.mapRuian.createMarker(this.schoolList);
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  width: 100%;
  min-height: 100vh;
  background: url("@/assets/images/campusData/campusBg.png") center center /
    100% 100% no-repeat;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    transform-origin: 0 0;
    height: 82px;
    background: url("@/assets/images/campusData/headerimg.png") no-repeat;
    background-size: 100% 82px;
    .rightTime {
      width: 260px;
      position: fixed;
      right: 180px;
      top: 12px;
      z-index: 99;
      color: #fff;
      font-family: "Alimama FangYuanTi VF";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 1.68px;
    }
    .weatherContent {
      position: absolute;
      left: 230px;
      top: 0px;
      z-index: 99;
      color: #fff;
      font-family: "Alimama FangYuanTi VF";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.68px;
      display: flex;
      align-items: center;
    }
    .img {
      display: block;
      width: 100%;
      object-fit: contain;
    }
    .title {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      color: #fff;
      display: flex;
      padding-top: 9px;
      justify-content: center;
      fofont-family: "Alimama FangYuanTi VF";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 8px;
      background: linear-gradient(180deg, #e6f4fd 0%, #41a1ff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .row {
    text-align: center;
    position: fixed;
    top: 115px;
    right: 0;
    left: 0;
    z-index: 99;
  }
  .tabs {
    position: relative;
    display: inline-flex;
    justify-content: center;
    z-index: 999;
    color: #83a6fe;
    .item {
      cursor: pointer;
      font-size: 18px;
      padding: 10px 36px;
      background: url("@/assets/images/campusData/tabItemBg.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 50px;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
    }
    .ac {
      color: #fff;
    }
  }
  .footerWrap {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 43px;
    background: url("@/assets/images/campusData/footerWrapimg.png") no-repeat;
    background-size: 100% 42px;
  }
  .commonClass {
    transform-origin: 0 0;
    position: fixed;
    left: 50%;
    right: 0;
    bottom: 50%;
    top: 50%;
    transition: 0.3s;
    z-index: 3;
  }
}
</style>
<style>
/* 隐藏地图bmap左下角logo */
.anchorBL {
  display: none;
}
</style>
