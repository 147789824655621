import request from "../commons/utils/axios";
import qs from "qs";

// 查询当前学校信息
export function getSchoolInfoApi(id) {
  return request({
    url: "/base-info/schoolCorpId/selectById?corpId=" + id,
    method: "get",
    data: {},
  });
}
