<template>
  <div>
    <!--<div class="title_1">忘记密码</div>-->
    <el-form
      :model="forgetForm"
      :rules="forgetRules"
      ref="forgetForm"
      label-width="60px"
      class="login-forgetForm"
    >
      <el-row :gutter="20">
        <el-col :span="20" :offset="2">
          <el-form-item prop="phoneNumber">
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-mobile"
              v-model="forgetForm.phoneNumber"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="15" :offset="2">
          <el-form-item prop="Pnumber">
            <el-input placeholder="验证码" v-model="forgetForm.Pnumber">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button class="tabsPsd_btn" type="primary">获取验证码</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20" :offset="2" style="position: relative">
          <el-form-item prop="newPassword">
            <el-input
              placeholder="输入新密码"
              prefix-icon="el-icon-lock"
              v-model="forgetForm.password"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="16" :offset="4">
          <el-button type="primary" class="tabsPhone_btn" @click="goSuccess">确定</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- <div class="bottom_1">去用手机号登录</div> -->
  </div>
</template>

<script>
  import psdOne from './js/psdOne'
  export default {
    ...psdOne
  }
</script>

<style lang='less' scoped>
  @import "./css/psdOne.less";
</style>
