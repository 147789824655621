<template>
  <div>
    <div v-for="(item, index) in menu" :key="item.id">
      <!-- 没有子级的情况 -->
      <template v-if="!item.children || !item.children.length">
        <div
          @click="openLink(item)"
          :class="[
            'menu-item',
            item.funCode == activeMenuCode ? 'active-menu-item' : '',
          ]"
        >
          <img
            class="img"
            v-show="item.funCode == activeMenuCode"
            :src="imgPath + item.funClickImg"
          />
          <img
            class="img"
            v-show="item.funCode != activeMenuCode"
            :src="imgPath + item.funImg"
          />
          <p class="name">{{ item.name }}</p>
        </div>
      </template>

      <!-- 有子级的情况 -->
      <el-popover
        v-else
        width="164"
        trigger="hover"
        placement="right-start"
        class="flexVBC"
        :show-after="200"
        :ref="'popover' + index"
      >
        <!-- 展开列表 -->
        <div class="sub-menu">
          <div
            class="sub-menu-item"
            v-for="Item in item.children"
            :key="Item.id"
          >
            <div
              @click="openMenuSubItem(Item)"
              :class="['sub-menu-item-title']"
            >
              <img
                v-if="Item.children && Item.children.length"
                :class="[
                  activeMenuSubCode == Item.funCode
                    ? 'active-sub-menu-icon'
                    : '',
                ]"
                src="../../assets/img/menu_sub_close_icon.png"
              />
              <p
                :style="{
                  marginLeft:
                    !Item.children || !Item.children.length ? '30px' : '',
                }"
              >
                {{ Item.name }}
              </p>
            </div>
            <!-- 三级 -->
            <div
              class="sub-menu-item-children"
              v-if="
                Item.children &&
                Item.children.length &&
                activeMenuSubCode == Item.funCode
              "
            >
              <div
                v-for="ITEM in Item.children"
                :key="ITEM.id"
                @click="openLink(ITEM, 'popover' + index)"
                :class="[
                  'sub-menu-item-title',
                  currentPathCode == ITEM.funCode ? 'active-sub-menu' : '',
                ]"
              >
                <p>{{ ITEM.name }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- icon列 -->
        <template #reference>
          <div
            :class="[
              'menu-item',
              item.funCode == activeMenuCode ? 'active-menu-item' : '',
            ]"
          >
            <img
              class="img"
              v-show="item.funCode == activeMenuCode"
              :src="imgPath + item.funClickImg"
            />
            <img
              class="img"
              v-show="item.funCode != activeMenuCode"
              :src="imgPath + item.funImg"
            />
            <p class="name">{{ item.name }}</p>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>
<script>
import Collapse from "../../components/foldTemplate.vue";
import Storage from "@/commons/utils/storage.js";
export default {
  props: ["menu"],
  data() {
    return {
      imgPath: process.env.VUE_APP_FILE_URL,
      activeMenuCode: "",
      activeMenuSubCode: "",
      currentPathCode: "",
      user: {},
      externaljumpList: ["自选课管理", "德育维度", "农业平台"],
    };
  },
  methods: {
    setActive() {
      let { path, meta } = this.$route;
      if (path.includes("/desk/desk")) {
        this.activeMenuCode = "1111";
      } else {
        this.activeMenuCode = meta.funCode.substring(0, 4);
        this.activeMenuSubCode = meta.funCode.substring(0, 8);
        this.currentPathCode = meta.funCode;
      }
    },

    openMenuSubItem(row) {
      if (row.children && row.children.length) {
        if (this.activeMenuSubCode != row.funCode) {
          this.activeMenuSubCode = row.funCode;
        } else this.activeMenuSubCode = "";
      } else this.openLink(row);
    },

    openLink(row, refsKey) {
      if (refsKey) this.$refs[refsKey][0].hide();
      if (this.externaljumpList.includes(row.name)) {
        if (row.name == "自选课管理" || row.name == "德育维度") {
          this.goMyProjects(row);
        } else if (row.name == "农业平台") {
          this.goNongYe(row);
        }
      } else this.$router.push({ path: row.link });
    },

    // 跳转马屿小学项目
    goMyProjects(item) {
      let SID = this.user.token;
      let mobile = this.user.mobile;
      let type, url;
      url = this.getProxyLink();
      // url = "http://localhost/campus-web-my/";
      if (item.name == "自选课管理") type = "view_course";
      if (item.name == "德育维度") type = "view_deyu_evaluation";
      let queryString = "?SID=" + SID + "&mobile=" + mobile + "&type=" + type;
      window.open(url + queryString, "_blank");
    },

    goNongYe() {
      let orgId = localStorage.getItem("schoolId") || null;
      if (orgId == "2208010064") {
        // 测试实验小学
        window.open(
          "http://121.40.125.107:30575/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else if (orgId == "2209140066") {
        // 正式毓蒙
        window.open(
          "http://agriculture.prod.camp.huaching.com/dovey-environment/login/input"
        );
      } else if (orgId == "2407090079") {
        // 正式马鞍山实验小学
        window.open(
          "http://121.40.125.107:31138/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else if (orgId == "2411110082") {
        // 文成县珊溪镇中心小学
        window.open(
          "http://121.40.125.107:31011/dovey-environment/login/doSSOlogin?userCode=" +
            localStorage.getItem("userId")
        );
      } else {
        // 其他
        window.open(
          "http://agriculture.prod.camp.huaching.com/dovey-environment/login/input"
        );
      }
    },

    getProxyLink() {
      let type = process.env.VUE_APP_ENV;
      let url = "";
      switch (type) {
        case "test":
          // 测试
          url =
            "https://nginx.file.campus.project.kube.huaching.com/proxy/campus-web-my/";
          break;
        // 演示
        case "pre":
          url = "https://campus.huaching.com/campus-web-my/";
          break;
        // 正式
        case "prod":
          url = "https://campus.huaching.com/campus-web-my/";
          break;

        default:
          break;
      }
      return url;
    },
  },

  mounted() {
    let userInfo = Storage.get("user");
    if (userInfo) {
      this.user = JSON.parse(decodeURIComponent(decodeURIComponent(userInfo)));
    }
    this.setActive();
  },

  watch: {
    "$route.path": {
      handler(val) {
        this.setActive();
      },
    },
  },

  components: {
    Collapse,
  },
};
</script>
<style scoped lang="less">
.menu-item {
  overflow-x: hidden;
  padding: 4px 0;
  height: 50px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 8px;
  .img {
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
  }
  .name {
    color: #6d7175;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
  }
  &:hover {
    background-color: #f6f6f7;
  }
  &:active {
    background-color: #f6f6f7;
  }
}

.active-menu-item {
  // background-color: #f1f2f3;
  border-radius: 8px;
  background: linear-gradient(0deg, #f2fffc 0%, #f2fffc 100%), #f1f2f3;
  .name {
    color: #09b189 !important;
  }
}

.sub-menu {
  user-select: none;
  transition: all 0.5s;
  .sub-menu-item {
    .sub-menu-item-children {
      box-sizing: border-box;
      .sub-menu-item-title {
        color: #5c5f62;
        padding-left: 30px;
        border-radius: 8px;
        box-sizing: border-box;
      }
      .sub-menu-item-title:hover {
        background-color: #f6f6f6;
      }
    }
  }
  .sub-menu-item-title {
    cursor: pointer;
    height: 36px;
    width: 100%;
    overflow: hidden;
    color: #333;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .active-sub-menu-icon {
    transform: rotate(90deg);
  }

  .active-sub-menu {
    color: #09b189 !important;
    background-color: #f1f2f3 !important;
  }
}
</style>
