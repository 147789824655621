<template>
  <div class="deskWrap" ref="deskwrapRef" @scroll="updateActiveSection">
    <div class="deskheader">
      <div class="haderL">
        <div class="tx">{{ getDisplayName(user.userName) }}</div>
        <div class="infos">
          <div class="name">Hi~ {{ user.userName }},欢迎登录 🎉</div>
          <div @click="updatePsd" class="updatepsd">修改密码</div>
        </div>
      </div>
      <img class="img" src="../../../../assets/images/mainHeaderCom.png" />
    </div>
    <template v-if="menuListAll.length">
      <div class="sectionHistory">
        <div class="historyLabel">常用功能</div>
        <div v-if="historyList.length" class="historyList">
          <div
            v-for="item in historyList"
            :key="item.funcId"
            class="historyItem"
            @click="handleHistoryItem(item)"
          >
            <div class="img" v-if="!isShowChildren(item)">
              <template
                v-if="
                  item.funcName == '农业平台' || item.funcName == '设备权限设置'
                "
              >
                <img src="../../../../assets/images/nyptIcon.svg" alt="" />
              </template>
              <img v-else :src="imgPath + item.funcIcon" alt="" />
            </div>
            <template v-if="isShowChildren(item)">
              <el-dropdown
                style="width: 100%"
                :teleported="false"
                class="eldropClassDrop"
                popper-class="eldropClass"
                placement="bottom-start"
              >
                <template>
                  <div class="img">
                    <template
                      v-if="
                        item.funcName == '农业平台' ||
                        item.funcName == '设备权限设置'
                      "
                    >
                      <img src="../../../../assets/images/nyptIcon.svg" />
                    </template>
                    <img v-else :src="imgPath + item.funcIcon" alt="" />
                  </div>
                  <p class="hist-text">{{ item.funcName }}</p>
                  <div class="arrow">
                    <img
                      class="arrowTopIcon"
                      src="../../../../assets/images/icon/arrowTopIcon.png"
                    />
                  </div>
                </template>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      class="eldropClassItem"
                      v-for="(itemValue, index) in filterMenu(item)"
                      :key="index"
                      @click.stop="changeMneu(item, itemValue.link)"
                      >{{ itemValue.name }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
            <p v-else class="hist-text">{{ item.funcName }}</p>
          </div>
        </div>
        <div v-else class="historyNoContent">
          <img src="../../../../assets/images/historyNoContentIcon.png" />
          您当前暂无常用功能
        </div>
      </div>
      <!-- 全部功能 -->
      <div class="sectionMenu sectionMenu2">
        <div class="title">全部功能</div>
        <div class="sectionMenu-cont">
          <div class="tabSection">
            <div v-for="Ite in menuListAll" :key="Ite.id" :id="Ite.id">
              <!-- 一级标题 -->
              <p class="tabSectionTitle">{{ Ite.name }}</p>
              <!-- 循环二级 -->
              <div v-for="(item, index) in Ite.children" :key="index">
                <!-- 二级标题 -->
                <div
                  v-if="item.children && item.children.length"
                  class="s-label"
                >
                  {{ item.name }}
                </div>
                <div
                  v-else
                  class="s-td"
                  @click="changeMneu(item, null)"
                >
                  {{ item.name }}
                </div>
                <div v-if="item.children && item.children.length" class="s-row">
                  <div
                    v-for="(it, inx) in item.children"
                    @click="changeMneu(it, null)"
                    :key="inx"
                    class="s-td"
                  >
                    <template v-if="isShowChildren(it)">
                      <el-dropdown
                        class="eldropClassDrop"
                        popper-class="eldropClass"
                        placement="bottom-start"
                        style="width: 100%"
                        :teleported="false"
                      >
                        <div style="width: 100%">
                          <p>{{ it.name }}</p>
                          <div class="arrow">
                            <img
                              class="arrowTopIcon"
                              src="../../../../assets/images/icon/arrowTopIcon.png"
                            />
                          </div>
                        </div>
                        <template #dropdown>
                          <el-dropdown-menu style="width: 100%">
                            <el-dropdown-item
                              style="width: 100%"
                              class="eldropClassItem"
                              v-for="(itemValue, index) in filterMenu(it)"
                              :key="index"
                              @click.stop="
                                changeMneu(
                                  it,
                                  itemValue.link,
                                  item.name + '/' + it.name
                                )
                              "
                              >{{ itemValue.name }}</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </template>
                    <template v-else>{{ it.name }}</template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs2">
            <div class="tabscont">
              <template v-for="(item, index) in menuListAll" :key="item.id">
                <div
                  v-if="item.link != '/base/main/desk/desk'"
                  @click="handleTab(item, index)"
                  class="tabItem"
                  :class="[tabItemActive == item.id ? 'active_tabitem' : '']"
                >
                  <div v-if="tabItemActive == item.id" class="line"></div>
                  <img
                    v-if="tabItemActive != item.id"
                    :src="imgPath + item.funImg"
                  />
                  <img v-else :src="imgPath + item.funClickImg" />
                  <p>{{ item.name }}</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="sectionMenu">
      <div class="menuLabel">常用功能</div>
      <div class="noMenu">
        <img class="menuImg" src="../../../../assets/images/noMenuIcon.png" />
        <div class="title">数智校园管理平台</div>
        <div class="desc">您暂无使用权限，请联系学校管理员～</div>
      </div>
    </div>
  </div>

  <!--修改密码-->
  <el-dialog
    title="修改密码"
    v-model="pwdDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="500px"
  >
    <edit-form
      ref="editForm"
      :form-items="fromItems"
      :form-config="{
        labelPosition: 'top',
      }"
      :data="editForm"
    >
    </edit-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="pwdDialog = false">取消</el-button>
        <el-button type="primary" @click="changePwd">确认 </el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 创建弹窗 -->
  <ModelNoneDialog
    ref="gradeinformationadd"
    @sure="addClick"
    :buttonShow="true"
    :buttonShow1="false"
    :width="'640px'"
    :height="'603px'"
    @close="dialogVisible = false"
    ><template #contents>
      <div class="dialog_base_view">
        <el-form :model="addManagerForm" :rules="rules" ref="ruleForm">
          <p class="text_title">登录用户信息填写</p>
          <p class="text_message">
            完善主管理员信息，信息请如实填写，下次登录时请使用手机号码登录
          </p>
          <el-form-item prop="name" label="姓名">
            <!-- <p class="text">姓名</p> -->
            <el-input
              placeholder="请输入姓名"
              v-model="addManagerForm.name"
              prop="name"
            ></el-input>
          </el-form-item>

          <div class="view_connect">
            <div class="view_item">
              <el-form-item prop="phoneNum" label="手机号">
                <!-- <p class="text">手机号</p> -->
                <el-input
                  placeholder="请输入手机号"
                  v-model="addManagerForm.phoneNum"
                ></el-input>
              </el-form-item>
            </div>

            <div class="view_item marginleft16">
              <el-form-item prop="code" label="验证码">
                <!-- <p class="text">验证码</p> -->
                <el-input
                  placeholder="请输入验证码"
                  v-model="addManagerForm.code"
                  autocomplete="new-password"
                >
                  <template #suffix>
                    <div>
                      <span @click="onSendCode" class="text_getcode">{{
                        sendCodeText
                      }}</span>
                    </div>
                  </template>
                </el-input>
              </el-form-item>
            </div>
          </div>
          <p class="text_title margintop32">设置密码</p>
          <p class="text_message">
            请输入6-12位字母、数字、特殊符号且包含两种及以上!
          </p>
          <el-form-item prop="password" label="登录密码">
            <!-- <p class="text">登录密码</p> -->
            <el-input
              placeholder="请输入密码"
              type="password"
              v-model="addManagerForm.password"
              autocomplete="new-password"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="twoPassword" label="再次输入">
            <!-- <p class="text">再次输入</p> -->
            <el-input
              placeholder="请再次输入密码"
              type="password"
              v-model="addManagerForm.twoPassword"
              autocomplete="new-password"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </template>
  </ModelNoneDialog>
  <ModelNoneDialog
    ref="schoolIfonDialog"
    @sure="updateSchoolInfo"
    :buttonShow="true"
    :buttonShow1="false"
    :width="'640px'"
    :cancalButtonText="'下次填写'"
    :height="'603px'"
    @close="openSchoolIfonType = false"
    ><template #contents>
      <div class="dialog_cont_box">
        <SchoolInfo ref="schoolInfoRef" :schoolInfo="schoolInfo"></SchoolInfo>
      </div>
    </template>
  </ModelNoneDialog>
</template>

<script>
import desk from "./js/desk";
import SchoolInfo from "@/components/schoolBaseInfo.vue";
export default {
  ...desk,
  components: {
    SchoolInfo,
  },
};
</script>

<style lang="less" scoped>
@import "css/desk.less";
</style>
<style lang="less">
.eldropClass {
  width: 100%;
  .el-dropdown-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 164px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    background: var(--Light-Surface-Default, #fff);
    .eldropClassItem {
      width: 100%;
      padding: 10px 8px;
      border-radius: 8px;
      color: #333;
      font-feature-settings: "liga" off, "clig" off;
      /* Desktop/Body */
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      box-sizing: border-box;
    }
  }

  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #f6f6f7;
    color: #333;
  }
}

.eldropClassDrop {
  .el-tooltip__trigger {
    width: 100%;
    padding: 8px 16px !important;
    box-sizing: border-box !important;
    justify-content: flex-start !important;
    .btn {
      width: 100%;
    }
  }
}
</style>
