"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPie3D = getPie3D;
// 颜色列表
var colorList = ["#F7A0FF", "#FCCA3C", "#44CFFD"];
// 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
function getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
    // 计算
    var midRatio = (startRatio + endRatio) / 2;
    var startRadian = startRatio * Math.PI * 2;
    var endRadian = endRatio * Math.PI * 2;
    var midRadian = midRatio * Math.PI * 2;
    // 如果只有一个扇形，则不实现选中效果。
    // if (startRatio === 0 && endRatio === 1) {
    //     isSelected = false;
    // }
    isSelected = false;
    // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
    k = typeof k !== "undefined" ? k : 1 / 3;
    // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
    var offsetX = isSelected ? Math.sin(midRadian) * 0.1 : 0;
    var offsetY = isSelected ? Math.cos(midRadian) * 0.1 : 0;
    // 计算高亮效果的放大比例（未高亮，则比例为 1）
    var hoverRate = isHovered ? 1.05 : 1;
    // 返回曲面参数方程
    return {
        u: {
            min: -Math.PI,
            max: Math.PI * 3,
            step: Math.PI / 32,
        },
        v: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20,
        },
        x: function (u, v) {
            if (u < startRadian) {
                return (offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate);
            }
            if (u > endRadian) {
                return (offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate);
            }
            return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        y: function (u, v) {
            if (u < startRadian) {
                return (offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate);
            }
            if (u > endRadian) {
                return (offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate);
            }
            return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        z: function (u, v) {
            if (u < -Math.PI * 0.5) {
                return Math.sin(u);
            }
            if (u > Math.PI * 2.5) {
                return Math.sin(u) * h * 0.1;
            }
            return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
        },
    };
}
// 生成模拟 3D 饼图的配置项
function getPie3D(pieData, internalDiameterRatio) {
    var series = [];
    var sumValue = 0;
    var startValue = 0;
    var endValue = 0;
    var legendData = [];
    var k = typeof internalDiameterRatio !== "undefined"
        ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
        : 1 / 3;
    // 为每一个饼图数据，生成一个 series-surface 配置
    for (var i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;
        var seriesItem = {
            name: typeof pieData[i].name === "undefined" ? "series".concat(i) : pieData[i].name,
            type: "surface",
            parametric: true,
            wireframe: {
                show: false,
            },
            pieData: pieData[i],
            pieStatus: {
                selected: false,
                hovered: false,
                k: 1 / 10,
            },
        };
        if (typeof pieData[i].itemStyle != "undefined") {
            var itemStyle = {};
            typeof pieData[i].itemStyle.color != "undefined"
                ? (itemStyle.color = pieData[i].itemStyle.color)
                : null;
            typeof pieData[i].itemStyle.opacity != "undefined"
                ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
                : null;
            seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
    }
    // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
    // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
    for (var i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value;
        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = getParametricEquation(series[i].pieData.startRatio, series[i].pieData.endRatio, false, false, k, series[i].pieData.value);
        startValue = endValue;
        legendData.push(series[i].name);
    }
    series.push({
        name: "mouseoutSeries",
        type: "surface",
        parametric: true,
        wireframe: {
            show: false,
        },
        itemStyle: {
            opacity: 0.2,
            color: "rgba(165, 247, 253, 1)",
        },
        parametricEquation: {
            u: {
                min: 0,
                max: Math.PI * 2,
                step: Math.PI / 20,
            },
            v: {
                min: 0,
                max: Math.PI / 4,
                step: Math.PI / 20,
            },
            x: function (u, v) {
                return ((Math.sin(v) * Math.sin(u) + Math.sin(u)) / Math.PI) * 2.5;
            },
            y: function (u, v) {
                return ((Math.sin(v) * Math.cos(u) + Math.cos(u)) / Math.PI) * 2.5;
            },
            z: function (u, v) {
                return Math.cos(v) > 0 ? -3 : -3;
            },
        },
    });
    // 准备待返回的配置项，把准备好的 legendData、series 传入。
    var option = {
        legend: {
            icon: "circle",
            orient: "vertical",
            data: pieData.map(function (dItem, dIndex) {
                return __assign(__assign({}, dItem), { textStyle: {
                        rich: {
                        // percent: {
                        //   color: colorList[dIndex],
                        // },
                        },
                    } });
            }),
            right: "5%",
            top: "10%",
            bottom: "10%",
            itemGap: 10,
            itemWidth: 8,
            itemHeight: 8,
            selectedMode: false, // 关闭图例选择
            textStyle: {
                color: "rgba(255, 255, 255, 0.80);",
                fontSize: 14,
                fontFamily: "PingFang SC",
                rich: {
                    name: {
                        color: "#FFF",
                        fontSize: 14,
                        padding: [0, 0, 4, 8],
                    },
                    value: {
                        color: "#fff",
                        fontSize: 20,
                        fontFamily: "Akrobat",
                        padding: [4, 8, 0, 8],
                    },
                    percent: {
                        color: "#fff",
                        fontSize: 14,
                        padding: [5, 0, 0, 0],
                    },
                    unit: {
                        color: "#ACDCE4",
                        fontSize: 24,
                        padding: [0, 0, 0, 5],
                    },
                },
            },
            formatter: function (name) {
                var obj = pieData.find(function (item) { return item.name === name; });
                var datas = pieData;
                var total = 0;
                var target = obj.value;
                for (var i = 0; i < datas.length; i++) {
                    total += Number(datas[i].value);
                }
                var arr = [
                    "{name|".concat(name, "}\n{value|").concat(obj.value, "%}"),
                ];
                return arr.join("");
            },
        },
        xAxis3D: {},
        yAxis3D: {},
        zAxis3D: {},
        grid3D: {
            viewControl: {
                autoRotate: true, // 自动旋转
            },
            left: "4%",
            width: "50%",
            show: false,
            boxHeight: 30,
            // boxWidth和boxDepth这两个属性值保持一致，才可以在调整饼图宽度的时候保持水平，不然就会歪歪扭扭
            boxWidth: 200,
            boxDepth: 200,
        },
        series: series,
    };
    return option;
}
