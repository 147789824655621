<template>
  <div class="flowDatawrap">
    <div class="left">
      <div class="titlebox">学生考勤</div>
      <div class="jrcq">昨日出勤</div>
      <compontEcharts
        v-if="pieOptin"
        class="echarts"
        canvasWidth="380px"
        canvasHeight="132px"
        :options="pieOptin"
      ></compontEcharts>
      <img class="rline" src="@/assets/images/campusData/line.png" alt />
      <div class="jrcq">近1个月平均迟到率</div>
      <template v-if="lastMonthRegionStudentAttendanceData">
        <div class="progressBar">
          <span class="t">小学</span>
          <div class="progress">
            <div
              :style="{
                width:
                  lastMonthRegionStudentAttendanceData.primarySchoolAvgLatePercent ||
                  0 + '%',
              }"
              class="progressAc"
            ></div>
          </div>
          <span class="n"
            >{{
              lastMonthRegionStudentAttendanceData.primarySchoolAvgLatePercent ||
              0
            }}%</span
          >
        </div>
        <div class="progressBar">
          <span class="t">初中</span>
          <div class="progress">
            <div
              :style="{
                width:
                  lastMonthRegionStudentAttendanceData.juniorHighSchoolAvgLatePercent +
                  '%',
              }"
              class="progressAc"
            ></div>
          </div>
          <span class="n"
            >{{
              lastMonthRegionStudentAttendanceData.juniorHighSchoolAvgLatePercent ||
              0
            }}%</span
          >
        </div>
        <div class="progressBar">
          <span class="t">高中</span>
          <div class="progress">
            <div
              :style="{
                width:
                  lastMonthRegionStudentAttendanceData.highSchoolAvgLatePercent ||
                  0 + '%',
              }"
              class="progressAc"
            ></div>
          </div>
          <span class="n"
            >{{
              lastMonthRegionStudentAttendanceData.highSchoolAvgLatePercent ||
              0
            }}%</span
          >
        </div>
        <img class="rline" src="@/assets/images/campusData/line.png" alt />
        <div class="jrcq">近1个月平均到校时间</div>
        <div class="timeSection">
          <div class="itemT">
            <img src="@/assets/images/campusData/timexIcon.png" alt />
            <div class="time">
              {{
                lastMonthRegionStudentAttendanceData.primarySchoolAvgArriveTime
              }}
            </div>
            <div class="name">小学</div>
          </div>
          <div class="itemT">
            <img src="@/assets/images/campusData/timecIcon.png" alt />
            <div class="time">
              {{
                lastMonthRegionStudentAttendanceData.juniorHighSchoolAvgArriveTime
              }}
            </div>
            <div class="name c">初中</div>
          </div>
          <div class="itemT">
            <img src="@/assets/images/campusData/timeGIcon.png" alt />
            <div class="time">
              {{ lastMonthRegionStudentAttendanceData.highSchoolAvgArriveTime }}
            </div>
            <div class="name g">高中</div>
          </div>
        </div>
        <img class="rline" src="@/assets/images/campusData/line.png" alt />
        <div class="jrcq">近1个月平均缺勤率</div>
        <div class="timeSection">
          <div
            v-for="(item, index) in progressList"
            :key="index"
            class="progressItem"
          >
            <compontEcharts
              v-if="item.option"
              class="echarts"
              canvasWidth="76px"
              canvasHeight="76px"
              :options="item.option"
            ></compontEcharts>
            <!-- <el-progress
              :width="76"
              :height="76"
              class="custom-progress"
              type="circle"
              :color="item.colors"
              :percentage="item.value"
              :stroke-width="10"
            >
              <template #default="{ percentage }">
                <span class="percentage-value">{{ percentage }}%</span>
              </template>
            </el-progress> -->
            <div class="label">{{ item.name }}</div>
          </div>
        </div>
      </template>
    </div>
    <div class="content">
      <div class="xsqj">学生请假</div>
      <div ref="sxSection" class="sxSection">
        <div class="labele">近15日请假统计</div>
        <div class="leaveStatistics">
          <div v-for="(it, index) in leaveStatistics" :key="index" class="item">
            <img :src="require('@/assets/images/campusData/' + it.img)" alt />
            <div class="info">
              <div class="qjamouts">{{ it.name }}</div>
              <div class="nums">
                {{ it.num }}
                <span v-if="it.isR != 0">
                  <img
                    :src="
                      it.isR == 1
                        ? require('@/assets/images/campusData/huanbiTop.png')
                        : require('@/assets/images/campusData/huanbiBot.png')
                    "
                    alt=""
                  />(环比)</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="labele">请假人数</div>
        <compontEcharts
          class="leaveStatisticsEcharts"
          v-if="leaveStatisticsOption"
          :canvasWidth="leaveStatisticsOption.w"
          :canvasHeight="leaveStatisticsOption.h"
          :options="leaveStatisticsOption"
        ></compontEcharts>
      </div>
      <div class="xsqj">教师考勤</div>
      <div class="sxSection teacherSection">
        <div class="l">
          <div class="labele">昨日出勤</div>
          <div class="row">
            <div class="proesscql">
              <compontEcharts
                v-if="proesscqlOption"
                :canvasWidth="proesscqlOption.w"
                :canvasHeight="proesscqlOption.h"
                :options="proesscqlOption"
              ></compontEcharts>
              <div v-if="proesscqlOption" class="cqlbox">
                <div class="toptext">
                  {{ proesscqlOption.attendTeacherPercent }}%
                </div>
                <div class="bottext">出勤率</div>
              </div>
            </div>
            <div v-if="proesscqlOption" class="cqrsbox">
              <div class="cqitem">
                应出勤人数：
                <span>{{ proesscqlOption.requireAttendTeacherNum }}</span>
              </div>
              <div class="cqitem cqrs">
                出勤人数：
                <span>{{ proesscqlOption.attendTeacherNum }}</span>
              </div>
              <div class="cqitem qqrs">
                缺勤人数：
                <span>{{ proesscqlOption.absenceTeacherNum }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="r">
          <div class="labele">近15日出勤</div>
          <div ref="attendance" class="attendance">
            <compontEcharts
              v-if="attendanceOption"
              :canvasWidth="attendanceOption.w"
              :canvasHeight="attendanceOption.h"
              :options="attendanceOption"
            ></compontEcharts>
          </div>
        </div>
      </div>
    </div>

    <div class="left right">
      <div class="titlebox">访客信息</div>
      <div class="jrcq">访客人数</div>
      <div v-if="regionVisitorNumData" class="visitorSection">
        <div class="vistoptitle">本月访客</div>
        <div class="visitorNums">
          <template
            v-for="(item, index) in regionVisitorNumData.curMonthVisitorNum"
          >
            <div
              :key="index"
              v-if="
                regionVisitorNumData.curMonthVisitorNum.length == 4 &&
                index == 1
              "
              class="s"
            >
              ,
            </div>
            <div :key="index + 'a'" v-else class="i">
              {{ item }}
            </div>
          </template>
        </div>
        <div class="row">
          <div class="td">
            <div class="vistoptitle">今日访客</div>
            <div class="visitorNums">
              <template
                v-for="(item, index) in regionVisitorNumData.nowDayVisitorNum"
              >
                <div
                  :key="index"
                  v-if="
                    regionVisitorNumData.nowDayVisitorNum.length == 4 &&
                    index == 1
                  "
                  class="s"
                >
                  ,
                </div>
                <div :key="index + 'a'" v-else class="i">
                  {{ item }}
                </div>
              </template>
            </div>
          </div>
          <div class="td">
            <div class="vistoptitle">本周访客</div>
            <div class="visitorNums">
              <template v-for="item in regionVisitorNumData.curWeekVisitorNum">
                <!-- <div
                  :key="index"
                  v-if="
                    regionVisitorNumData.curWeekVisitorNum.length == 4 &&
                    index == 1
                  "
                  class="s"
                >
                  ,
                </div> -->
                <div class="i">
                  {{ item }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="jrcq">本月到访事由统计</div>
      <div class="statisticsBox">
        <div v-for="it in regionVisitorReasonData" :key="it" class="item">
          <div class="resText">
            <div class="resTextLabel">{{ it.visitReason }}</div>
            <div class="resTextNum">{{ it.visitorNum }}</div>
          </div>
          <div class="schedule">
            <compontEcharts
              class="echarts"
              canvasWidth="380px"
              canvasHeight="10px"
              :options="it.option"
            ></compontEcharts>
          </div>
        </div>
      </div>
      <img
        style="margin: 18px 0"
        class="rline"
        src="@/assets/images/campusData/line.png"
        alt
      />
      <div class="titlebox">车辆信息</div>
      <div class="jrcq">进出统计</div>
      <compontEcharts
        v-if="carinfosOption"
        canvasWidth="390px"
        canvasHeight="186px"
        :options="carinfosOption"
      ></compontEcharts>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import compontEcharts from "@/components/echarts.vue";
import { getPie3D } from "./index.ts";
import {
  pastDaysRegionStudentLeave,
  nowDayRegionStudentAttendance,
  lastMonthRegionStudentAttendance,
  pastDaysRegionStudentLeaveTrend,
  nowDayRegionTeacherAttendance,
  pastDaysRegionTeacherAttendanceTrend,
  regionVisitorNum,
  regionVisitorReason,
  pastDaysRegionCarNumTrend,
} from "@/api/campusDataScreen";
export default {
  components: {
    compontEcharts,
  },
  data() {
    return {
      county: 330381,
      pieOptin: null, //学生出勤
      lastMonthRegionStudentAttendanceData: null, //学生迟到
      progressList: [
        {
          name: "小学",
          colors: ["#47D5FC", "#1E88F3", "#47D5FC"],
          value: null,
        },
        {
          name: "初中",
          colors: ["#FCC947", "#FF910F", "#FCC947"],
          value: null,
        },
        {
          name: "高中",
          colors: ["#D55FFF", "#6F1EF3", "#D55FFF"],
          value: null,
        },
      ],
      leaveStatistics: [
        //请假统计
        {
          code: "leaveTotalNum",
          name: "请假总数",
          num: "0",
          img: "qjzsIcon.png",
          codetrend: "leaveTotalTrend",
          isR: 0,
        },
        {
          code: "sickLeaveNum",
          name: "病假次数",
          num: "0",
          img: "bjcsIcon.png",
          codetrend: "sickLeaveTrend",
          isR: 0,
        },
        {
          code: "personalLeaveNum",
          name: "事假次数",
          num: "0",
          img: "sjcsIcon.png",
          codetrend: "personalLeaveTrend",
          isR: 0,
        },
        {
          code: "otherLeaveNum",
          name: "其他次数",
          num: "0",
          img: "qtcsIcon.png",
          codetrend: "otherLeaveTrend",
          isR: 0,
        },
      ],
      leaveStatisticsOption: null,
      attendanceOption: null, //近7日出勤
      carinfosOption: null, //车辆信息
      proesscqlOption: null, //教师考勤
      regionVisitorNumData: null, //访客数量
      regionVisitorReasonData: null, //访客事由
    };
  },
  created() {},
  mounted() {
    this.getNowDayRegionStudentAttendance(); //学生考勤
    this.getLastMonthRegionStudentAttendance(); //学生迟到 到校时间 平均缺勤
    this.getleaveStatisticsData(); //15日请假统计
    this.attendanceData(); //教师考勤
    this.getregionVisitorNum(); //访客数量
    this.getRegionVisitorReason(); //本月到访事由统计
    this.carinfos();
  },
  methods: {
    getNowDayRegionStudentAttendance() {
      //学生今日出勤
      nowDayRegionStudentAttendance({ county: this.county }).then((res) => {
        const data = [
          {
            name: "出勤率",
            value: res.data.attendStudentPercent,
          },
          {
            name: "缺勤率",
            value: res.data.absenceStudentPercent,
          },
          {
            name: "请假率",
            value: res.data.dayLeaveStudentPercent,
          },
        ];
        // 颜色列表
        const colorList = ["#F7A0FF", "#FCCA3C", "#44CFFD"];
        const serData = data.map((dItem, index) => {
          return {
            ...dItem,
            value: Number(dItem.value),
            itemStyle: {
              color: colorList[index],
            },
          };
        });
        let option = getPie3D(serData, 0.7);
        option.grid3D.left = 0;
        option.grid3D.boxHeight = 30;
        option.grid3D.boxWidth = 240;
        option.grid3D.boxDepth = 240;
        console.log(option);

        this.pieOptin = option;
      });
    },
    getLastMonthRegionStudentAttendance() {
      lastMonthRegionStudentAttendance({ county: this.county }).then((res) => {
        this.lastMonthRegionStudentAttendanceData = res.data;
        this.progressList.map((item, index) => {
          item.value =
            index == 0
              ? res.data.primarySchoolAvgAbsencePercent || 0
              : index == 1
              ? res.data.juniorHighSchoolAvgAbsencePercent || 0
              : res.data.highSchoolAvgAbsencePercent || 0;
          item.option = {
            tooltip: {
              trigger: "item",
            },
            title: [
              {
                text: item.value + "%",
                x: "center",
                y: "center",
                itemGap: 5, // 文本间距
                textStyle: {
                  color: "#FFF",
                  fontFamily: "Akrobat",
                  fontWeight: 900,
                  fontSize: 18,
                },
              },
            ],
            series: [
              {
                name: "Access From",
                type: "pie",
                radius: ["85%", "100%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                },
                data: [
                  {
                    value: item.value,
                    name: "Search Engine",
                    itemStyle: {
                      color: {
                        colorStops: [
                          {
                            offset: 0,
                            color: item.colors[0], // 0% 处的颜色
                          },
                          {
                            offset: 0,
                            color: item.colors[1], // 0% 处的颜色
                          },
                          {
                            offset: 1,
                            color: item.colors[2], // 100% 处的颜色
                          },
                        ],
                      },
                    },
                  },
                  {
                    value: 100 - Number(item.value),
                    tooltip: {
                      show: false,
                    },
                    emphasis: {
                      disabled: true,
                    },
                    itemStyle: {
                      borderWidth: 1,
                      color: "rgba(160, 225, 255, 0.2)",
                    },
                  },
                ],
              },
            ],
          };
        });
        console.log("数据=", this.progressList);
      });
    },
    getleaveStatisticsData() {
      pastDaysRegionStudentLeave({ county: this.county })
        .then((res) => {
          this.leaveStatistics.map((item) => {
            item.num = res.data[item.code];
            item.isR = res.data[item.codetrend];
          });
          return pastDaysRegionStudentLeaveTrend({ county: this.county });
        })
        .then((res) => {
          let xAxisData = [],
            leaveTotalStudentNumData = [],
            sickLeaveStudentNumData = [];
          res.data.map((item) => {
            xAxisData.push(item.leaveDateStr);
            leaveTotalStudentNumData.push(item.leaveTotalStudentNum);
            sickLeaveStudentNumData.push(item.sickLeaveStudentNum);
          });

          this.$nextTick(() => {
            this.leaveStatisticsOption = {
              w: this.$refs.sxSection.clientWidth - 48 + "px",
              h: this.$refs.sxSection.clientHeight + "px",
              title: {
                text: "单位/人数",
                textStyle: {
                  fontSize: 14,
                  color: "rgba(255, 255, 255, 0.80)",
                },
              },
              grid: {
                top: "20%",
                left: "0%",
                right: "0%",
                bottom: "0%",
                containLabel: true,
              },
              legend: {
                top: 0,
                right: 0,
                itemHeight: 4,
                itemWidth: 12,
                icon: "rect",
                textStyle: {
                  color: "rgba(255, 255, 255, 0.80)",
                },
              },
              xAxis: {
                type: "category",
                axisLine: {
                  lineStyle: {
                    color: "#04D2FF29",
                  },
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
                data: xAxisData,
              },
              yAxis: {
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#04D2FF29",
                    type: "solid",
                  },
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
              series: [
                {
                  name: "请假人数",
                  type: "pictorialBar",
                  symbol: "",
                  symbolSize: [15, 5], // 图形大小 [width，height]
                  color: "#02E8FF", // 柱形图颜色
                  symbolRepeat: true, // 图形是否重复
                  // symbolBoundingData: 2000, // 指定图形界限的值
                  label: {
                    // 是否显示label
                    show: true,
                    color: "#77C8FF",
                    fontSize: "14",
                    position: "top", // label 显示在文字的上方
                  },
                  data: leaveTotalStudentNumData,
                },
                {
                  name: "病假人数",
                  type: "line",
                  color: "#FFCA33", // 柱形图颜色
                  data: sickLeaveStudentNumData,
                },
              ],
            };
          });
        });
    },
    attendanceData() {
      nowDayRegionTeacherAttendance({ county: this.county })
        .then((res) => {
          console.log(res);
          this.getProesscql(res.data);
          return pastDaysRegionTeacherAttendanceTrend({ county: this.county });
        })
        .then((res) => {
          //7日出勤
          this.$nextTick(() => {
            let xAxisData = [],
              seriesData = [];
            res.data.map((item) => {
              xAxisData.push(item.attendanceDateStr);
              seriesData.push(item.attendTeacherNum);
            });
            this.attendanceOption = {
              w: this.$refs.attendance.clientWidth + "px",
              h: this.$refs.attendance.clientHeight + "px",
              tooltip: {
                // 触发类型，默认数据触发，可选为：'item' | 'axis'
                trigger: "axis",
              },
              grid: {
                left: "5%",
                bottom: "12%",
                right: "5%",
                top: "10%",
              },
              legend: {},
              xAxis: {
                type: "category",
                data: xAxisData,
                axisLabel: {
                  textStyle: {
                    color: "#fff", // 修改X轴标签文字颜色为蓝色
                  },
                },
              },
              yAxis: {
                type: "value",
                axisLabel: {
                  textStyle: {
                    color: "#fff", // 修改X轴标签文字颜色为蓝色
                  },
                },
              },
              series: [
                {
                  symbol: "none",
                  data: seriesData,
                  type: "line",
                  smooth: true,
                  lineStyle: {
                    color: new echarts.graphic.LinearGradient(
                      0,
                      0,
                      1,
                      0, // 渐变方向，从左到右
                      [
                        { offset: 0, color: "#FFEA7C" }, // 渐变起始颜色
                        { offset: 1, color: "#FFCA33" }, // 渐变结束颜色
                      ]
                    ),
                  },
                },
              ],
            };
          });
        });
    },
    getregionVisitorNum() {
      regionVisitorNum({ county: this.county }).then((res) => {
        res.data.curMonthVisitorNum = res.data.curMonthVisitorNum
          .toString()
          .split("");
        res.data.curWeekVisitorNum = res.data.curWeekVisitorNum
          .toString()
          .split("");
        res.data.nowDayVisitorNum = res.data.nowDayVisitorNum
          .toString()
          .split("");
        this.regionVisitorNumData = res.data;
        console.log(this.regionVisitorNumData);
      });
    },
    getRegionVisitorReason() {
      // 到访事由统计

      regionVisitorReason({
        county: this.county,
      }).then((res) => {
        this.regionVisitorReasonData = res.data;
        let maxData = 100;
        this.regionVisitorReasonData.map((item) => {
          item.option = {
            tooltip: {},
            xAxis: {
              show: false,
              max: maxData,
              splitLine: { show: false },
              offset: 10,
              axisLabel: {
                margin: 10,
              },
            },
            yAxis: {
              show: false,
              data: [item.visitReason],
              inverse: true,
              axisTick: { show: false },
              axisLine: { show: false },
            },
            grid: {
              top: 0,
              height: 10,
              left: 0,
              right: 0,
            },
            series: [
              {
                // current data
                type: "pictorialBar",
                symbol: "rect",
                symbolSize: [6, 10], // 指定矩形的尺寸，宽度和高度
                symbolRadius: 20, // 指定矩形的圆角，水平和垂直方向的半径
                symbolRepeat: "fixed",
                symbolMargin: "20%",
                symbolClip: true,
                symbolBoundingData: maxData,
                data: [item.visitorPercent],
                markLine: {
                  symbol: "none",
                  label: {
                    formatter: "max: {c}",
                    position: "start",
                  },
                  lineStyle: {
                    color: "#F35C30",
                    type: "dotted",
                    opacity: 0.2,
                    width: 2,
                  },
                },
                z: 10,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1, // 渐变方向从上到下
                    [
                      { offset: 0, color: "#E8AC34" }, // 柱图顶部颜色
                      { offset: 1, color: "#F35C30" }, // 柱图底部颜色
                    ]
                  ),
                },
              },
              {
                // full data
                type: "pictorialBar",
                itemStyle: {
                  opacity: 0.2,
                },
                // label: {
                //   show: true,
                //   formatter: function(params) {
                //     return ((params.value / maxData) * 100).toFixed(1) + " %";
                //   },
                //   position: "right",
                //   offset: [10, 0],
                //   color: "#F35C30",
                //   fontSize: 18
                // },
                animationDuration: 0,
                symbolRepeat: "fixed",
                symbolMargin: "20%",
                symbol: "rect",
                itemStyle: {
                  color: "#333",
                },
                symbolSize: [6, 10],
                symbolBoundingData: maxData,
                data: [item.visitorPercent],
                z: 5,
              },
            ],
          };
        });
      });

      // this.statisticsOption =
    },
    carinfos() {
      pastDaysRegionCarNumTrend({ county: this.county }).then((res) => {
        let xAxisData = [],
          series1 = [],
          series2 = [],
          series3 = [];
        res.data.map((item) => {
          xAxisData.push(item.inoutDateStr);
          series1.push(item.fixedCarNum);
          series2.push(item.visitorCarNum);
          series3.push(item.tempCarNum);
        });
        this.carinfosOption = {
          title: {
            text: "单位 / 辆",

            textStyle: {
              fontSize: 14,
              color: "rgba(255, 255, 255, 0.80)",
            },
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["固定车", "访客车", "临时车"],
            right: 0,
            itemHeight: 6,
            itemWidth: 6,
            icon: "circle",
            textStyle: {
              color: "rgba(255, 255, 255, 0.80)",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
            backgroundColor: "#04D2FF29",
          },

          xAxis: {
            type: "category",
            data: xAxisData,
            axisLine: {
              lineStyle: {
                color: "#04D2FF29",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#04D2FF29",
                type: "solid",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
          series: [
            {
              name: "固定车",
              type: "line",
              data: series1,
            },
            {
              name: "访客车",
              type: "line",
              data: series2,
            },
            {
              name: "临时车",
              type: "line",
              data: series3,
            },
          ],
        };
      });
    },
    getProesscql(teacher) {
      //教师出勤 今日出勤
      var xdata = Number(teacher.attendTeacherNum); //接收x数据
      var ydata = 100; //接收y数据
      var datas_outer = []; //存放外层颜色小块
      var num = 50; //定义小块个数
      var rate = xdata / ydata; //完成率
      for (var i = num; i >= 0; i--) {
        if (i <= rate * 50) {
          datas_outer.push({
            value: 1,
            name: "已完成",
            itemStyle: {
              color: "#41EAB1", //浅绿色
              borderWidth: 3,
              borderColor: "#00133B", //背景色 融入
            },
          });
        } else {
          datas_outer.push({
            value: 1, //占位用
            name: "未完成",
            itemStyle: {
              color: "#3D4C6C",
              borderWidth: 3,
              borderColor: "#00133B",
            },
          });
        }
      }
      this.proesscqlOption = {
        absenceTeacherNum: teacher.absenceTeacherNum,
        attendTeacherNum: teacher.attendTeacherNum,
        attendTeacherPercent: teacher.attendTeacherPercent,
        requireAttendTeacherNum: teacher.requireAttendTeacherNum,
        w: "142px",
        h: "142px",
        tooltip: {
          show: false,
        },
        series: [
          {
            name: "完成情况外层",
            type: "pie",
            radius: ["86%", "100%"],
            center: ["50%", "50%"],
            clockwise: false,
            data: datas_outer,
            startAngle: 0,
            hoverAnimation: false,
            legendHoverLink: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
          },
        ],
      };
    },
  },
};
</script>
<style lang="less" scoped>
.flowDatawrap {
  display: flex;
  padding: 0 40px;
  padding-top: 115px;
  box-sizing: border-box;
  .left {
    width: 380px;
    box-sizing: border-box;
    .titlebox {
      width: 100%;
      margin-bottom: 24px;
      height: 40px;
      background: url("@/assets/images/campusData/bjgk.png") no-repeat;
      background-size: 470px 40px;
      padding-left: 22px;
      box-sizing: border-box;
      color: #fff;
      text-shadow: 2px 2px 6px #1559be;
      font-family: "Alimama FangYuanTi VF";
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1.8px;
      line-height: 40px;
    }
    .jrcq {
      color: rgba(255, 255, 255, 0.88);
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2.56px;
      padding-left: 8px;
      position: relative;
      margin-bottom: 24px;
      &::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 14px;
        left: 0;
        top: 50%;
        transform: translateY(-7px);
        background: linear-gradient(
          to bottom,
          #0499ff 0%,
          #04d2ff 50%,
          #0499ff 100%
        );
      }
    }
    .echarts {
      display: flex;
      justify-content: center;
    }
    .rline {
      display: block;
      width: 100%;
      object-fit: cover;
      margin: 32px 0;
    }
    .progressBar {
      display: flex;
      align-items: center;
      margin-top: 24px;
      box-sizing: border-box;
      .t {
        color: #26e6ff;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 2.24px;
        margin-right: 8px;
      }
      .progress {
        width: 290px;
        height: 10px;
        background: rgba(255, 255, 255, 0.24);
        margin-right: 8px;
        position: relative;
        .progressAc {
          width: 10px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background: linear-gradient(
            270deg,
            #04fff0 0%,
            rgba(4, 210, 255, 0.8) 100%
          );
          &::after {
            position: absolute;
            right: -2px;
            top: 50%;
            content: "";
            width: 4px;
            height: 12px;
            transform: translateY(-6px);
            background: #fff;
          }
        }
      }
      .n {
        color: #fff;
        font-family: Akrobat;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.64px;
      }
    }
    .timeSection {
      display: flex;
      justify-content: space-around;
      .itemT {
        text-align: center;
        img {
          display: block;
          width: 56px;
          height: 56px;
          margin-bottom: 4px;
          margin: 0 auto;
        }
        .time {
          color: #fff;
          font-family: Akrobat;
          font-size: 20px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          letter-spacing: 0.8px;
          margin-bottom: 4px;
          height: 27px;
        }
        .name {
          color: #26e6ff;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 2.24px;
        }
        .name.c {
          color: #41eab1;
        }
        .name.g {
          color: #fcca3c;
        }
      }
      .progressItem {
        width: 110px;
        text-align: center;
        .percentage-value {
          color: #fff;
          font-family: Akrobat;
          font-size: 20px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          letter-spacing: 0.8px;
        }
        .label {
          color: #fff;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 2.24px;
          margin-top: 5px;
        }
      }
    }
  }
  .content {
    flex: 1;
    margin: 0 40px;
    padding-top: 90px;
    box-sizing: border-box;
    .xsqj {
      background: url("@/assets/images/campusData/xskqIcon.png") no-repeat;
      background-size: 1000px 40px;
      color: #fff;
      text-shadow: 2px 2px 6px #1559be;
      font-family: "Alimama FangYuanTi VF";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2.16px;
      padding-left: 47px;
      margin-bottom: 24px;
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
    }
    .sxSection {
      padding: 24px;
      padding-top: 0;
      border: 1px solid #04d2ff52;
      border-top: none;
      margin-bottom: 24px;
      box-sizing: border-box;
      .labele {
        color: rgba(255, 255, 255, 0.88);
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.56px;
        padding-left: 8px;
        position: relative;
        margin-bottom: 24px;
        box-sizing: border-box;
        &::after {
          content: "";
          position: absolute;
          width: 3px;
          height: 14px;
          left: 0;
          top: 50%;
          transform: translateY(-7px);
          background: linear-gradient(
            to bottom,
            #0499ff 0%,
            #04d2ff 50%,
            #0499ff 100%
          );
        }
      }
      .leaveStatistics {
        display: flex;
        justify-content: space-around;
        margin-bottom: 24px;
        .item {
          flex: 1;
          display: flex;
          img {
            display: block;
            width: 83px;
            height: 64px;
            margin-right: 24px;
          }
          .info {
            .qjamouts {
              color: rgba(255, 255, 255, 0.8);
              font-family: "PingFang SC";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 2.24px;
              margin-bottom: 4px;
            }
            .nums {
              color: #fff;
              font-family: Akrobat;
              font-size: 24px;
              font-style: normal;
              font-weight: 900;
              line-height: normal;
              letter-spacing: 0.96px;
              display: flex;
              span {
                color: rgba(255, 255, 255, 0.8);
                font-family: "PingFang SC";
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1.12px;
                display: flex;
                align-items: center;
                margin-left: 4px;
                img {
                  width: 24px;
                  height: auto;
                  object-fit: contain;
                  margin-right: 2px;
                }
              }
            }
          }
        }
      }
    }
    .teacherSection {
      border-bottom: none;
      margin-bottom: 0;
      display: flex;
      .l {
        width: 370px;
        margin-right: 40px;
        .row {
          display: flex;
          .proesscql {
            width: 142px;
            height: 142px;
            border-radius: 50%;
            margin-right: 40px;
            box-sizing: border-box;
            margin-top: 10px;
            position: relative;
            .cqlbox {
              width: 110px;
              height: 110px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
              padding-top: 23px;
              box-sizing: border-box;
              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: url("@/assets/images/campusData/cqlboxIcon.png")
                  no-repeat;
                background-size: 110px 110px;
                animation: rotate-ani 2s linear infinite;
              }
              @keyframes rotate-ani {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(360deg);
                }
              }
              .toptext {
                color: #fff;
                font-family: Akrobat;
                font-size: 32px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                letter-spacing: 2.56px;
                margin-bottom: 4px;
              }
              .bottext {
                color: rgba(255, 255, 255, 0.64);
                font-family: "PingFang SC";
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.48px;
              }
            }
          }
          .cqrsbox {
            .cqitem {
              display: flex;
              align-items: center;
              min-width: 196px;
              height: 40px;
              background: url("@/assets/images/campusData/ycqrs.png");
              background-size: 196px 40px;
              color: #fff;
              font-family: "PingFang SC";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 2.24px;
              padding-left: 16px;
              box-sizing: border-box;
              margin-bottom: 16px;
              span {
                color: #fff;
                font-family: Akrobat;
                font-size: 24px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                letter-spacing: 1.92px;
              }
            }
            .cqrs {
              background: url("@/assets/images/campusData/cqrs.png");
              background-size: 196px 40px;
            }
            .qqrs {
              background: url("@/assets/images/campusData/qqrs.png");
              background-size: 196px 40px;
            }
          }
        }
      }
      .r {
        flex: 1;
        .attendance {
          width: 100%;
          height: 180px;
        }
      }
    }
  }
  .right {
    width: 380px;
    .visitorSection {
      .vistoptitle {
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        font-family: "PingFang SC";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.88px;
        margin-bottom: 8px;
      }
      .visitorNums {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
        .i {
          width: 28px;
          height: 51px;
          background: url("@/assets/images/campusData/visitorCounts.png")
            no-repeat;
          background-size: 28px 51px;
          color: #fff;
          text-align: center;
          font-family: Akrobat;
          font-size: 28px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          letter-spacing: 1.12px;
          text-align: center;
          line-height: 51px;
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
        .s {
          font-size: 28px;
          font-style: normal;
          font-weight: 900;
          color: #fff;
          margin-right: 8px;
        }
      }
      .row {
        display: flex;
        .td {
          flex: 1;
        }
      }
    }
    .statisticsBox {
      .item {
        margin-bottom: 13px;
        .resText {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          .resTextLabel {
            color: rgba(255, 255, 255, 0.8);
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 2.24px;
          }
          .resTextNum {
            color: #fff;
            text-align: right;
            font-family: Akrobat;
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 0.64px;
          }
        }
        .schedule {
          width: 100%;
          margin-bottom: 13px;
        }
      }
    }
  }
}
</style>
