const nodeENV = process.env.NODE_ENV;
const VUE_APP_ENV = process.env.VUE_APP_ENV;

console.log(`nodeENV:${nodeENV}; VUE_APP_ENV:${VUE_APP_ENV}`);
let config = {};
config = require(`./${VUE_APP_ENV}`);

module.exports = {
  ...config,
};
