<template>
  <div id="chinaMap">
    <div id="threejs"></div>
    <div id="marker"></div>
    <!-- 弹框元素 -->
    <div style="display: none">
      <div class="popup_line"></div>
      <div class="popup_Main">
        <div class="popupMain_top"></div>
        <div class="popup_content">
          <div class="popup_head">
            <div class="popup_title">
              <div class="title_icon"></div>
              <div id="popup_Name">湖北省</div>
            </div>
            <div class="close" @click="popupClose"></div>
          </div>
          <div class="popup_item">
            <div>用电量：</div>
            <div class="item_value">2688万人次</div>
          </div>
          <div class="popup_item">
            <div>用水量</div>
            <div class="item_value">88.7%</div>
          </div>
        </div>
        <div class="popupMain_footer"></div>
      </div>
    </div>
    <div id="popup" style="display: none">
      <div class="popup_line"></div>
      <div class="viewWrap">
        <div id="orgName" class="name">瑞安中学</div>
        <div class="schoolSection">
          <div class="scItem">
            <div class="scTitle">班级数量</div>
            <div id="classNum" class="scnums">482</div>
          </div>
          <div class="scItem">
            <div class="scTitle">教师人数</div>
            <div id="teacherNum" class="scnums">482</div>
          </div>
          <div class="scItem">
            <div class="scTitle">在校学生数</div>
            <div id="studentNum" class="scnums">482</div>
          </div>
          <div class="scItem">
            <div class="scTitle">男生人数</div>
            <div id="studentMaleNum" class="scnums">482</div>
          </div>
          <div class="scItem">
            <div class="scTitle">女生人数</div>
            <div id="studentFemaleNum" class="scnums">482</div>
          </div>
          <div class="scItem">
            <div class="scTitle">毕业人数</div>
            <div id="graduateNum" class="scnums">482</div>
          </div>
        </div>
        <div class="footerSection">
          <div class="fItem">
            <div class="fLabel">老师人数</div>
            <div id="teacherNum1" class="nums">2897</div>
          </div>
          <div class="fItemBox">
            <div id="teacherStudentRatio" class="proportion">16:9</div>
            <div class="desc">师生对比</div>
          </div>
          <div class="fItem">
            <div class="fLabel">学生人数</div>
            <div id="studentNum1" class="nums">2897</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, toRaw, ref, watch, defineExpose } from "vue";
import * as THREE from "three";

//接口
import { schoolOverview } from "@/api/campusDataScreen";
// 引入TWEENJS
import TWEEN from "@tweenjs/tween.js";
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js";
// threejs基础配置，场景相机渲染器等
import {
  scene,
  camera,
  controls,
  renderer,
  css3DRenderer,
  css2DRenderer,
} from "./baseConfig/index.js";
// 加载地图
import {
  loadChinaMapData,
  mapModel,
  mapUf,
  projection,
} from "./initChinaMap/index.js";
// 地图圆圈背景
import { outerCircle, innerCircle } from "./backgroundCircle/index.js";
const props = defineProps(["schoolList"]);
// 时钟对象，用于获取两帧渲染之间的时间值
const clock = new THREE.Clock();
// 弹框元素
let divTag = null;
// css2D弹框对象
let css2Dpopup = null;
let orgName = null;
let classNum = null;
let teacherNum = null;
let studentNum = null;
let studentMaleNum = null;
let studentFemaleNum = null;
let graduateNum = null;
let teacherStudentRatio = null;
let studentNum1 = null;
let teacherNum1 = null;

let markerModel = ref([]);
let schoolListViewList = ref([]);
let schoolListNoViewList = ref([]);
// 根据模型大小计算一个缩放值
const scale = 70;
onMounted(async () => {
  document.getElementById("threejs").appendChild(renderer.domElement);
  document.getElementById("threejs").appendChild(css3DRenderer.domElement);
  document.getElementById("threejs").appendChild(css2DRenderer.domElement);

  orgName = document.getElementById("orgName");
  classNum = document.getElementById("classNum");
  teacherNum = document.getElementById("teacherNum");
  studentNum = document.getElementById("studentNum");
  studentMaleNum = document.getElementById("studentMaleNum");
  studentFemaleNum = document.getElementById("studentFemaleNum");
  graduateNum = document.getElementById("graduateNum");
  teacherStudentRatio = document.getElementById("teacherStudentRatio");
  studentNum1 = document.getElementById("studentNum1");
  teacherNum1 = document.getElementById("teacherNum1");

  // 加载中国地图
  await loadChinaMapData();
  // 应用缩放值到省份模型上去
  mapModel.scale.set(scale, scale, 1);
  // 初始化地图点击事件
  initMapClick();
  scene.add(mapModel, outerCircle, innerCircle);
  // createMarker();
  // 开始循环渲染
  render();
  // 首次进入动画
  eventAnimation();
});

// 循环渲染
function render() {
  requestAnimationFrame(render);
  camera.updateProjectionMatrix();
  controls.update();
  // 两帧渲染间隔
  let deltaTime = clock.getDelta();
  // 地图模型侧边渐变效果
  mapUf.uTime.value += deltaTime;
  if (mapUf.uTime.value >= 5) {
    mapUf.uTime.value = 0.0;
  }
  // 背景外圈内圈旋转
  outerCircle.rotation.z -= 0.0015;
  innerCircle.rotation.z += 0.0015;

  // TWEEN更新
  TWEEN.update();
  renderer.render(scene, camera);
  css3DRenderer.render(scene, camera);
  css2DRenderer.render(scene, camera);
}
let timeInterval = null;
// 初始化地图点击事件
function initMapClick() {
  divTag = document.getElementById("popup");
  const widthScale = window.innerWidth / 1920;
  const heightScale = window.innerHeight / 1080;
  divTag.style.top += (45 * heightScale).toFixed(2) + "px";
  divTag.style.left += (230 * widthScale).toFixed(2) + "px";
  // 转换为CSS2D对象
  css2Dpopup = new CSS2DObject(divTag);
  // 设置一个较高的渲染顺序，防止弹框被标牌等物体遮挡住
  css2Dpopup.renderOrder = 99;
  // 鼠标点击事件
  addEventListener("click", (e) => {
    console.log(timeInterval);
    if (timeInterval != null) {
      clearInterval(timeInterval);
    }
    const px = e.offsetX;
    const py = e.offsetY;
    // 屏幕坐标转为标准设备坐标
    const x = (px / window.innerWidth) * 2 - 1;
    const y = -(py / window.innerHeight) * 2 + 1;
    // 创建射线
    const raycaster = new THREE.Raycaster();
    // 设置射线参数
    raycaster.setFromCamera(new THREE.Vector2(x, y), camera);
    // 射线交叉计算拾取模型
    let intersects = raycaster.intersectObjects(markerModel.value);

    // 点击选中模型时
    if (intersects.length > 0) {
      const obj = intersects[0].object;
      // 设置弹框位置，弹框名称等

      schoolOverview({ orgId: obj.parent.userData.orgId }).then((res) => {
        css2Dpopup.position.copy(obj.parent.position);
        mapModel.add(css2Dpopup);
        orgName.innerHTML = obj.parent.userData.orgName;
        classNum.innerHTML = res.data.classNum;
        teacherNum.innerHTML = res.data.teacherNum;
        studentNum.innerHTML = res.data.studentNum;
        studentMaleNum.innerHTML = res.data.studentMaleNum;
        studentFemaleNum.innerHTML = res.data.studentFemaleNum;
        graduateNum.innerHTML = res.data.graduateNum;
        teacherStudentRatio.innerHTML = res.data.teacherStudentRatio;
        studentNum1.innerHTML = res.data.studentNum;
        teacherNum1.innerHTML = res.data.teacherNum;
        // 给弹框加载创建渐变动画
        new TWEEN.Tween({ opacity: 0 })
          .to({ opacity: 1.0 }, 500)
          .onUpdate(function (obj) {
            //动态更新div元素透明度
            divTag.style.opacity = obj.opacity;
          })
          .start();
      });
    }
    // 未选中模型
    else {
      // 给弹框清除创建渐变动画
      new TWEEN.Tween({ opacity: 0 })
        .to({ opacity: 0 }, 500)
        .onUpdate(function (obj) {
          //动态更新div元素透明度
          divTag.style.opacity = obj.opacity;
        })
        .start();
    }

    timeInterval = setInterval(() => {
      loopPlayback(schoolListNoViewList.value);
    }, 20000);
  });
}

//循环播放弹窗
function loopPlayback(coordinateArr) {
  if (timeInterval != null) {
    clearInterval(timeInterval);
  }
  //弹窗domID
  // const orgName = document.getElementById("orgName");
  // const classNum = document.getElementById("classNum");
  // const teacherNum = document.getElementById("teacherNum");
  // const studentNum = document.getElementById("studentNum");
  // const studentMaleNum = document.getElementById("studentMaleNum");
  // const studentFemaleNum = document.getElementById("studentFemaleNum");
  // const graduateNum = document.getElementById("graduateNum");
  // const teacherStudentRatio = document.getElementById("teacherStudentRatio");
  // const studentNum1 = document.getElementById("studentNum1");
  // const teacherNum1 = document.getElementById("teacherNum1");

  let index = 0;
  schoolListViewList.value = [];
  timeInterval = setInterval(function () {
    if (index == coordinateArr.length) {
      index = 0;
      clearInterval(timeInterval);
      showSchoolOverivew(schoolListViewList);
      return;
    }
    schoolOverview({ orgId: coordinateArr[index].orgId }).then((res) => {
      res.data.orgName = coordinateArr[index].orgName;
      schoolListViewList.value.push(
        Object.assign(coordinateArr[index], res.data)
      );
      const center = projection(coordinateArr[index].coordinate);
      const markerDom = document.getElementById("marker").cloneNode();
      const css2dObject = new CSS2DObject(markerDom);
      css2dObject.position.set(center[0], -center[1], 0);
      console.log(css2dObject.position);
      css2Dpopup.position.copy(css2dObject.position);
      mapModel.add(css2Dpopup);
      orgName.innerHTML = coordinateArr[index].orgName;
      classNum.innerHTML = res.data.classNum;
      teacherNum.innerHTML = res.data.teacherNum;
      studentNum.innerHTML = res.data.studentNum;
      studentMaleNum.innerHTML = res.data.studentMaleNum;
      studentFemaleNum.innerHTML = res.data.studentFemaleNum;
      graduateNum.innerHTML = res.data.graduateNum;
      teacherStudentRatio.innerHTML = res.data.teacherStudentRatio;
      studentNum1.innerHTML = res.data.studentNum;
      teacherNum1.innerHTML = res.data.teacherNum;
      index++;
      // 给弹框加载创建渐变动画
      new TWEEN.Tween({ opacity: 0 })
        .to({ opacity: 1.0 }, 500)
        .onUpdate(function (obj) {
          //动态更新div元素透明度
          divTag.style.opacity = obj.opacity;
        })
        .start();
    });
  }, 10000);
}
function showSchoolOverivew() {
  //多所学校详情存下来后调用
  let index = 0;
  let arr = toRaw(schoolListViewList.value);
  console.log(arr);
  timeInterval = setInterval(function () {
    if (index == arr.length) {
      index = 0;
    }
    const center = projection(arr[index].coordinate);
    const markerDom = document.getElementById("marker").cloneNode();
    const css2dObject = new CSS2DObject(markerDom);
    css2dObject.position.set(center[0], -center[1], 0);
    console.log(css2dObject.position);
    css2Dpopup.position.copy(css2dObject.position);
    mapModel.add(css2Dpopup);
    orgName.innerHTML = arr[index].orgName;
    classNum.innerHTML = arr[index].classNum;
    teacherNum.innerHTML = arr[index].teacherNum;
    studentNum.innerHTML = arr[index].studentNum;
    studentMaleNum.innerHTML = arr[index].studentMaleNum;
    studentFemaleNum.innerHTML = arr[index].studentFemaleNum;
    graduateNum.innerHTML = arr[index].graduateNum;
    teacherStudentRatio.innerHTML = arr[index].teacherStudentRatio;
    studentNum1.innerHTML = arr[index].studentNum;
    teacherNum1.innerHTML = arr[index].teacherNum;
    index++;
    // 给弹框加载创建渐变动画
    new TWEEN.Tween({ opacity: 0 })
      .to({ opacity: 1.0 }, 500)
      .onUpdate(function (obj) {
        //动态更新div元素透明度
        divTag.style.opacity = obj.opacity;
      })
      .start();
  }, 10000);
}
// 首次进入动画
function eventAnimation() {
  new TWEEN.Tween(camera.clone().position)
    .to(new THREE.Vector3(-5, 250, 300), 1500)
    .easing(TWEEN.Easing.Sinusoidal.InOut)
    .onUpdate((e) => {
      camera.position.copy(e);
      controls.target.set(-5, 0, -10);
      controls.update();
    })
    .start();
}
defineExpose({ createMarker });
function createMarker(schoolList) {
  //创建点位
  const coordinateArr = toRaw(schoolList);
  schoolListNoViewList.value = coordinateArr;
  loopPlayback(coordinateArr);
  coordinateArr.map((obj) => {
    console.log("坐标==", obj);
    const center = projection(obj.coordinate);
    const markerDom = document.getElementById("marker").cloneNode();
    const css2dObject = new CSS2DObject(markerDom);
    css2dObject.position.set(center[0], -center[1], 0);
    css2dObject.userData.orgName = obj.orgName;
    css2dObject.userData.countyName = obj.countyName;
    css2dObject.userData.orgId = obj.orgId;
    mapModel.add(css2dObject);
    // 创建一个不可见的3D对象
    const geometry = new THREE.BoxGeometry(0.12, 0.12, 3);
    const material = new THREE.MeshBasicMaterial({
      color: 0x0000ff,
      transparent: true,
      visible: false,
    });
    const invisibleMesh = new THREE.Mesh(geometry, material);
    invisibleMesh.position.z += 0;
    invisibleMesh.position.y -= 0.05;
    css2dObject.add(invisibleMesh);
    markerModel.value.push(invisibleMesh);
  });
}
</script>
<style lang="less">
#chinaMap {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}
#threejs {
  width: 100%;
  height: 100%;
}

#marker {
  position: absolute;
  background: url("@/assets/images/campusData/dropIcon.png") no-repeat;
  background-size: 100% 100%;
  width: 40px;
  height: 56px;
  cursor: pointer;
}
#popup {
  z-index: 999;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  width: 24vw;
  height: 22vh;
  transform: scale(0.5);
}
.popup_line {
  /*

  margin-top: 6%;
    width: 7%;
    height: 29%;*/
  margin-top: 6%;
  width: 7%;
  height: 29%;
  /*background: url("@/assets/images/campusData/popup_line.png") no-repeat;*/
  background: url("@/assets/images/campusData/ydtIcon.png") no-repeat;
  background-size: 100% 100%;
  transform: rotate(30deg);
}
.viewWrap {
  width: 379px;
  height: 414px;
  background: url("@/assets/images/campusData/schoolViewAlert.png") no-repeat;
  background-size: 100% 100%;
  padding: 46px 40px;
  box-sizing: border-box;

  .name {
    color: #fff;
    text-shadow: 2px 2px 6px #1559be;
    font-family: "Alimama FangYuanTi VF";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.16px;
    margin-bottom: 35px;
    padding-left: 4px;
  }
  .schoolSection {
    display: flex;
    flex-wrap: wrap;
    .scItem {
      margin-right: 38px;
      margin-bottom: 29px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      .scTitle {
        color: #1a95d9;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 10px;
        position: relative;
        padding-left: 13px;
        box-sizing: border-box;
        &::after {
          content: "";
          width: 8px;
          height: 14px;

          background: linear-gradient(
            270deg,
            rgba(14, 79, 115, 0) 0%,
            #1a95d9 100%
          );

          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-7px);
        }
      }
      .scnums {
        color: #ffc000;
        font-family: Akrobat;
        font-size: 22px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.44px;
        text-align: center;
      }
    }
  }
  .footerSection {
    display: flex;
    justify-content: center;
    .fItem {
      flex: 1;
      text-align: center;
      position: relative;
      box-sizing: border-box;
      padding-top: 28px;
      &::after {
        content: "";
        background: url("@/assets/images/campusData/toplineIcon.png") no-repeat;
        background-size: 40px 4px;
        position: absolute;
        left: 0;
        top: 0;
      }

      .fLabel {
        color: #1a95d9;
        font-family: "PingFang SC";
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 6px;
      }
      .nums {
        color: #188df3;
        font-family: Akrobat;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.4px;
      }
    }
    .fItemBox {
      width: 85px;
      height: 85px;
      text-align: center;
      background: url("@/assets/images/campusData/qbiliIcon.png") center
        no-repeat;
      background-size: 85px 85px;
      padding-top: 28px;
      box-sizing: border-box;
      .proportion {
        color: #ffc000;
        text-align: center;
        font-family: Akrobat;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.32px;
      }
      .desc {
        color: #1a95d9;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 7px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
</style>
