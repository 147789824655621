import axios from "axios";
import { ElMessage } from "element-plus";
// import Vue from 'vue'
// Vue.use(Toast);
// console.log(process.env.API_URL);

const request = axios.create({
  baseURL: process.env.API_URL, // api 的 base_url
  timeout: 15000, // request timeout
});

//响应返回时拦截
request.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (Number(res.code) === 2) {
      //登录代码=

      ElMessage({
        message: "需要重新登录",
        type: "error",
      });
      setTimeout("location.href='/login'", 100);
      return res;
    }

    if (Number(res.code) != 200) {
      ElMessage({
        message: res.describe,
        type: "error",
      });
      return res;
    }
    return res;
  },
  (error) => {
    // console.log(JSON.stringify(error));
    if (error.message == "Network Error") {
      error["message"] = "网络异常" + error.message;
    } else if (error.message.indexOf("timeout") != -1) {
      error["message"] = "返回超时";
    }
    ElMessage.error(error["message"]);
    return Promise.reject(error);
  }
);

export default request;
